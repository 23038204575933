const { Deserializer } = require('./deserializer');
const { Serializer } = require('./serializer');

const SERIALIZER = new Serializer();
const DESERIALIZER = new Deserializer();

export function serialize(object) {
    SERIALIZER.reset();

    return SERIALIZER.serialize(object);
}

export function deserialize(buffer) {
    DESERIALIZER.reset();

    return DESERIALIZER.deserialize(buffer);
}