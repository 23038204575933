import { clamp } from '../../utils/math';

export function editText(widget, evt) {
    const c = evt.key;
    const ctrl = evt.ctrlKey;
    let { text, cursorIndex } = widget.attributes;
    let valid = true;

    cursorIndex = clamp(cursorIndex, 0, text.length);

    if (c === 'Backspace') {
        if (ctrl) {
            text = text.substring(cursorIndex);
            cursorIndex = 0;
        } else {
            text = text.substring(0, text.length - 1);
            cursorIndex -= 1;
        }
    } else if (c === 'Home') {
        cursorIndex = 0;
    } else if (c === 'End') {
        cursorIndex = text.length;
    } else if (c === 'ArrowLeft') {
        if (ctrl) {
            cursorIndex = 0;
        } else {
            cursorIndex -= 1;
        }
    } else if (c === 'ArrowRight') {
        if (ctrl) {
            cursorIndex = text.length;
        } else {
            cursorIndex += 1;
        }
    } else if (isValidCharacter(c) && !ctrl) {
        text = text.substring(0, cursorIndex) + c + text.substring(cursorIndex, text.length);
        cursorIndex += 1;
    } else {
        valid = false;
    }

    if (valid) {
        widget.setAttributes({ text, cursorIndex });
    }
}

function isValidCharacter(c) {
    return /^[0-9a-zA-Z_ ~!@#$%&*()+-=,<.>/?|]$/.test(c);
}