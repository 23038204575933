export const Point = {
    add(p1, p2) {
        return {
            x: x(p1) + x(p2),
            y: y(p1) + y(p2)
        };
    },

    substract(p1, p2) {
        return {
            x: x(p1) - x(p2),
            y: y(p1) - y(p2)
        };
    },

    equal(p1, p2) {
        return p1 === p2 || (p1 && p2 && x(p1) === x(p2) && x(p1) === x(p2));
    },

    direction(p1, p2) {
        return {
            x: Math.sign(x(p2) - x(p1)),
            y: Math.sign(x(p2) - y(p1))
        }
    }
};

function x(obj) {
    return obj.x;
    if (Array.isArray(obj)) {
        return obj[0];
    } else {
        return obj.x;
    }
}

function y(obj) {
    return obj.y;
    if (Array.isArray(obj)) {
        return obj[1];
    } else {
        return obj.y;
    }
}