import { EntityClassList } from '../entity-class-list';
import { Headquarters } from './headquarters';
import { Plain } from './plain';
import { Shrine } from './shrine';
import { Mountain } from './mountain';
import { Water } from './water';
import { Cursed } from './cursed';
import { Factory } from './factory';
import { Laboratory } from './laboratory';

export const TERRAINS = new EntityClassList([
    Plain, Water, Mountain, Cursed, Factory, Laboratory, Shrine, Headquarters
]);