import { getArrayItem, sum } from '../../../engine/utils/array';
import { CONSTANTS } from '../constants';
import { Unit } from '../unit';
import { getAdjacentCells, getAdjacentCellsStartingFromDirection, getAlliedUnitCells, getCellsWithinMovementRange, getCellsWithinVisionRange, getDirection, getDistance, isAlliedUnitLocation, isOpponentUnitCell, isValidMoveDestination, spread } from '../utils';
import { Character } from './character';

Object.assign(CONSTANTS, {
    ISEDRIX_SUN_RANGE: 2,
    ISEDRIX_SUN_POWER: 2,
    ISEDRIX_WIND_UNIT_COUNT: 6,
    ISEDRIX_WIND_UNIT_STRENGTH: 1,
    ISEDRIX_WIND_RANGE: 2,
    ISEDRIX_RAIN_RANGE: 1,
    ISEDRIX_RAIN_MAX_MOVEMENT_RANGE: 4,
});

export class Isedrix extends Character {
    constructor(options) {
        super(options);
    }

    static name = 'Isedrix'
    static title = 'Isedrix'
    static abilities = [
        {
            description: `Move an allied unit by 1-$ISEDRIX_SUN_RANGE cells, then remove $ISEDRIX_SUN_POWER strength to all chains of opponent units adjacent to it.`,
            target1({ source }) {
                return getAlliedUnitCells(source);
            },
            target2({ target1 }, C) {
                return getCellsWithinMovementRange(target1, C.ISEDRIX_SUN_RANGE);
            },
            target2Highlight({ source, target2 }) {
                return spread({
                    start: target2,
                    spread: cell => isOpponentUnitCell(cell, source)
                });
            },
            trigger({ game, source, target1, target2 }, C) {
                game.moveUnit({ source, unit: target1.entity, location: target2 });

                for (const location of this.target2Highlight(...arguments)) {
                    game.setUnitStrength({ source, unit: location.entity, modifier: -C.ISEDRIX_SUN_POWER });
                }
            }
        }, {
            description: `Spawn 7 1-strength units in a semi-circle at exactly 2 range of an allied unit. They die at the end of your second next turn.`,
            target1({ source }) {
                return getAlliedUnitCells(source);
            },
            target2({ target1 }, C) {
                return getCellsWithinVisionRange(target1, C.ISEDRIX_WIND_RANGE).filter(cell => getDistance(cell, target1) === C.ISEDRIX_WIND_RANGE);
            },
            target2Highlight({ target1, target2 }) {
                const adjacent = getAdjacentCells(target1);
                const circle = [];

                for (const location of adjacent) {
                    const direction = getDirection(target1, location);
                    const cells = getAdjacentCellsStartingFromDirection(location, direction, [0, 1]);

                    circle.push(...cells);
                }

                const indexes = [-3, -2, -1, 0, 1, 2, 3];
                const i = circle.indexOf(target2);

                return indexes.map(index => getArrayItem(circle, i + index)).filter(location => isValidMoveDestination(location));
            },
            target2Hover() {
                return null;
            },
            trigger({ game, source }, C) {
                for (const location of this.target2Highlight(...arguments)) {
                    game.spawnUnit({ source, location, unit: new Unit({ strength: C.ISEDRIX_WIND_UNIT_STRENGTH, remainingTurnCount: 5 }), owner: source.owner });
                }
            }
        }, {
            description: `Merge all allied units within $ISEDRIX_RAIN_RANGE range of the selected allied unit, then move the new unit by X cells (where X is the strength of the new unit, max $ISEDRIX_RAIN_MAX_MOVEMENT_RANGE).`,
            target1({ source }) {
                return getAlliedUnitCells(source);
            },
            target1Hover({ source, target1 }, C) {
                return [target1, ...getCellsWithinMovementRange(target1, C.ISEDRIX_RAIN_RANGE).filter(location => isAlliedUnitLocation(location, source))];
            },
            target2({ target1 }, C) {
                const range = Math.min(C.ISEDRIX_RAIN_MAX_MOVEMENT_RANGE, sum(this.target1Hover(...arguments).map(cell => cell.entity.strength)));

                return getCellsWithinMovementRange(target1, range);
            },
            trigger({ game, source, target1, target2 }) {
                const units = this.target1Hover(...arguments).map(location => location.entity).slice(1);

                for (const unit of units) {
                    game.moveUnit({ source, unit, location: target1 });
                }

                game.moveUnit({ source, unit: target1.entity, location: target2 });
            }
        }
    ]
}
globalThis.ALL_FUNCTIONS.push(Isedrix);