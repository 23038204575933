import { Box } from '../../utils/box';
import { HEXAGON_HEIGHT_TO_WIDTH_RATIO, isInvalidGridCell } from '../../utils/geometry';
import { round } from '../../utils/math';
import { Widget } from './widget';
import { WidgetGeneric } from './widget-generic';

const CELL_SHAPE_TO_WIDTH_MULTIPLIER = { 'horizontal-hexagon': HEXAGON_HEIGHT_TO_WIDTH_RATIO };
const CELL_SHAPE_TO_HEIGHT_MULTIPLIER = { 'vertical-hexagon': HEXAGON_HEIGHT_TO_WIDTH_RATIO };
const CELL_SHAPE_TO_NESTED_WIDTH_RATIO = { 'horizontal-hexagon' : 0.25 };
const CELL_SHAPE_TO_NESTED_HEIGHT_RATIO = { 'vertical-hexagon' : 0.25 };
const CELL_SHAPE_TO_ADDITIONAL_WIDTH = { 'vertical-hexagon': 0.5 };
const CELL_SHAPE_TO_ADDITIONAL_HEIGHT = { 'horizontal-hexagon': 0.5 };

export class WidgetGrid extends Widget {
    constructor(parameters) {
        super(parameters);

        if (!this.children.length) {
            const childCount = this.attributes.width * this.attributes.height;

            // TODO: make so the number of children always matches the grid size
            for (let i = 0; i < childCount; ++i) {
                this.addChild(new WidgetGeneric());
            }
        }
    }

    static DEFAULT_ATTRIBUTES = {
        width: 1,
        height: 1,
        gridColor: null,
        gridLineThickness: 0,
        cellAspectRatio: 1,
        cellShape: 'square',
        spaceBetweenCells: 0,
        mirror: false
    }

    setAttributesCallback() {
        const requiredChildCount = this.attributes.width * this.attributes.height;

        if (this.children.length !== requiredChildCount) {
            const childCountToAdd = Math.max(0, requiredChildCount - this.children.length);
            const childCountToRemove = Math.max(0, this.children.length - requiredChildCount);

            for (let i = 0; i < childCountToAdd; ++i) {
                this.addChild(new WidgetGeneric());
            }

            this.children.splice(requiredChildCount, childCountToRemove);
        }
    }

    _render({ canvas, box, cursor }) {
        const childBoxes = [];
        let { width, height, cellAspectRatio, gridLineThickness, spaceBetweenCells, gridColor, mirror, cellShape } = this.attributes;

        gridLineThickness = box.getAdaptativeSize(gridLineThickness);
        spaceBetweenCells = box.getAdaptativeSize(spaceBetweenCells);

        const nestedWidthRatio = CELL_SHAPE_TO_NESTED_WIDTH_RATIO[cellShape] || 0;
        const nestedHeightRatio = CELL_SHAPE_TO_NESTED_HEIGHT_RATIO[cellShape] || 0;
        const shapeWidthMultiplier = CELL_SHAPE_TO_WIDTH_MULTIPLIER[cellShape] || 1;
        const shapeHeightMultiplier = CELL_SHAPE_TO_HEIGHT_MULTIPLIER[cellShape] || 1;
        const additionalFractionalWidth = CELL_SHAPE_TO_ADDITIONAL_WIDTH[cellShape] || 0;
        const additionalFractionalHeight = CELL_SHAPE_TO_ADDITIONAL_HEIGHT[cellShape] || 0;
        const trueAspectRatio = cellAspectRatio;
        const effectiveRemovedWidth =
            gridLineThickness * (width + 1) +
            spaceBetweenCells * (width - 1) +
            (spaceBetweenCells > 0 ? gridLineThickness * (width - 1) : 0);
        const effectiveRemovedHeight =
            gridLineThickness * (height + 1) +
            spaceBetweenCells * (height - 1) + 
            (spaceBetweenCells > 0 ? gridLineThickness * (height - 1) : 0);
        const maxCellWidth = (box.w - effectiveRemovedWidth) / (width);
        const maxCellHeight = (box.h - effectiveRemovedHeight) / (height);
        let cellWidth, cellHeight;

        if (maxCellWidth < maxCellHeight * trueAspectRatio) {
            cellWidth = maxCellWidth;
            cellHeight = maxCellWidth / trueAspectRatio;
        } else {
            cellWidth = maxCellHeight * trueAspectRatio;
            cellHeight = maxCellHeight;
        }

        cellWidth = round(cellWidth);
        cellHeight = round(cellHeight);
        const boardWidth = cellWidth * width + effectiveRemovedWidth;
        const boardHeight = cellHeight * height + effectiveRemovedHeight;
        const offsetX = Math.floor((box.width - boardWidth) / 2);
        const offsetY = Math.floor((box.height - boardHeight) / 2);
        const effectiveCellWidth = round(cellWidth * (1 - nestedWidthRatio) * shapeWidthMultiplier) + gridLineThickness + spaceBetweenCells + (spaceBetweenCells ? gridLineThickness : 0);
        const effectiveCellHeight = round(cellHeight * (1 - nestedHeightRatio) * shapeHeightMultiplier) + gridLineThickness + spaceBetweenCells + (spaceBetweenCells ? gridLineThickness : 0);
        const offsetXOnEvenRow = round(additionalFractionalWidth * effectiveCellWidth) || 0;
        const offsetYOnEvenColumn = round(additionalFractionalHeight * effectiveCellHeight) || 0;
        const startX = box.x + offsetX + round(cellWidth * (shapeWidthMultiplier - 1) * width / 2);
        const startY = box.y + offsetY + round(cellHeight * (shapeHeightMultiplier - 1) * height / 2);

        for (let y = 0; y < height; ++y) {
            for (let x = 0; x < width; ++x) {
                const isInvalidCell = isInvalidGridCell(cellShape, x, y, width, height);

                if (isInvalidCell) {
                    childBoxes.push(null);
                    continue;
                }

                const isEvenColumn = x % 2 === 0;
                const isEvenRow = y % 2 === 0;
                const mx = mirror ? width - x - 1 : x;
                const my = mirror ? height - y - 1 : y;
                const additionalWidth = (shapeWidthMultiplier - 1) * cellWidth;
                const additionalHeight = (shapeHeightMultiplier - 1) * cellHeight;
                const boxX = startX + mx * effectiveCellWidth - additionalWidth / 2 + (isEvenRow ? offsetXOnEvenRow : 0) * (mirror ? -1 : 1);
                const boxY = startY + my * effectiveCellHeight - additionalHeight / 2 + (isEvenColumn ? offsetYOnEvenColumn : 0) * (mirror ? -1 : 1);
                const boxW = cellWidth + additionalWidth;
                const boxH = cellHeight + additionalHeight;
                const childBox = new Box(boxX, boxY, boxW, boxH);

                childBoxes.push(childBox);
            }
        }

        for (const box of childBoxes.filter(box => box)) {
            const borderBox = box.pad(gridLineThickness * 2, gridLineThickness * 2);

            canvas.draw({ type: cellShape, ...borderBox, borderWidth: gridLineThickness, borderColor: gridColor });
        }

        const length = Math.min(this.children.length, childBoxes.length);

        for (let i = 0; i < length; ++i) {
            const childBox = childBoxes[i];
            const child = this.children[i];

            if (child) {
                child.render({ canvas, box: childBox, cursor });
            }
        }
    }
}

globalThis.ALL_FUNCTIONS.push(WidgetGrid);