import EnhancedCanvas from 'enhanced-canvas';
import { FrameManager } from './frame-manager';
import { Box } from '../utils/box';
import { isColor } from '../utils/colors';
import { Widget } from './widgets/widget';
import { EventTarget } from '../utils/event-target';
import { isVanillaObject, mergeObjects } from '../utils/objects';
import { G } from '../utils/group';
import { capitalize } from '../utils/string';
import { getArrayItem, makeArray } from '../utils/array';
import { WidgetGeneric } from './widgets/widget-generic';
import { WidgetGrid } from './widgets/widget-grid';
import { WidgetColumn, WidgetLine } from './widgets/widget-line';
import { WindowWrapper } from './window-wrapper';

const DEFAULT_CURSOR_STYLE = {
    skin: 'default',
    image: null,
    imageWidth: 0,
    imageHeight: 0,
    tooltip: null,
    tooltipLocation: null
};

const DEFAULT_CURSOR = {
    x: 0,
    y: 0,
    left: false,
    middle: false,
    right: false,
    hovered: null,
    dragged: null,
    draggedRight: null,
    focused: null,
    shift: false,
    ctrl: false,
    alt: false,
    style: DEFAULT_CURSOR_STYLE
};

export class UserInterface extends EventTarget {
    constructor(options = {}) {
        const {
            window = new WindowWrapper(),
            aspectRatio = 16 / 9,
            rootWidget = new Widget(),
            backgroundColor = 'black'
        } = options;

        super();
        this.registerEvents(['keyDown', 'keyUp', 'mouseMove', 'mouseUp', 'mouseDown', 'click', 'dragStart', 'dragEnd', 'rightClick', 'hover']);

        this._window = window;
        this._aspectRatio = aspectRatio;
        this._backgroundColor = backgroundColor;
        this._canvas = new EnhancedCanvas({ canvas: this._window.makeIntoCanvas(), globalImageLoader: true });
        this._frameManager = new FrameManager(() => this._render());
        this._layers = [];
        this._widgetsByLayer = [];
        this._widgetsById = {};
        this._focusChain = [];
        this._cursor = {};
        this._eventsToTrigger = null;
        this._renderEnabled = true;
        this._box = null;

        this._resetCursor();
        this._initWindowListeners();
        this.setRootWidget(rootWidget);
    }

    _initWindowListeners() {
        this._window.on({
            resize() {
                this.refresh();
            },
            mouseInput(evt) {
                // TODO: handle this in WindowWrapper
                const { top, left } = this._canvas.getDomElement().getBoundingClientRect();
                const x = evt.x - left;
                const y = evt.y - top;
                this._onMouseInput({ ...evt, x, y });
            },
            keyDown(evt) {
                this._onKeyDown(evt);
            },
            keyUp(evt) {
                this._onKeyUp(evt);
            }
        }, this);
    }

    _removeWindowListeners() {
        this._window.removeEventCallbacks(this);
    }

    setRenderEnabled(value) {
        if (this._renderEnabled === value) {
            return;
        }

        this._renderEnabled = value;

        if (value) {
            this._initWindowListeners();
        } else {
            this._removeWindowListeners();
        }
    }

    emulateMouseMove() {
        this._triggerWidgetEventOrFallback('unhover', false);
        this._cursor.hovered = null;
        this._onMouseInput({ x: this._cursor.x, y: this._cursor.y, action: 'move', button: null });
    }

    emulateClick(widget) {
        widget.onClick(this._cursor);
    }

    _collectAllWidgets() {
        this._widgetsByLayer = [];
        this._widgetsById = {};

        for (const rootWidget of this._layers) {
            if (rootWidget) {
                this._widgetsByLayer.push(rootWidget.collect());
            }
        }

        for (const widget of this._widgetsByLayer.flat()) {
            if (widget.id) {
                this._widgetsById[widget.id] = widget;
            }
        }
    }

    _onMouseInput(evt) {
        const { x, y, action, button } = evt;

        this._cursor.x = x;
        this._cursor.y = y;

        const activeLayerWidgets = getArrayItem(this._widgetsByLayer, -1) || [];
        const hoveredWidgetList = activeLayerWidgets.filter(widget => isHovered(widget, this._cursor));
        const hoveredWidget = getArrayItem(hoveredWidgetList, -1);

        this._eventsToTrigger = [];

        if (action === 'down') {
            this._cursor[button] = true;
            if (button === 'left') {
                this._focusWidget(this._cursor.hovered);
            }

            this._triggerWidgetEventOrFallback('mouseDown');
        } else if (action === 'up') {
            this._cursor[button] = false;
            if (button === 'left') {
                if (!this._cursor.dragged || (this._cursor.dragged === this._cursor.hovered && this._cursor.dragged.attributes.clickOnSelfDrag !== false)) {
                    this._triggerWidgetEventOrFallback('click');
                }

                if (this._cursor.dragged) {
                    this._triggerWidgetEventOrFallback('dragEnd');
                    this._cursor.dragged = null;
                } 

                if (this._cursor.focused !== this._cursor.hovered) {
                    this._focusWidget(null);
                }

                this._triggerWidgetEventOrFallback('mouseUp');
            } else if (button === 'right') {
                if (!this._cursor.draggedRight || this._cursor.draggedRight === this._cursor.hovered) {
                    this._triggerWidgetEventOrFallback('rightClick');
                }

                if (this._cursor.draggedRight) {
                    this._cursor.draggedRight = null;
                }
            }
        } else if (action === 'move') {
            if (this._cursor.left && !this._cursor.dragged) {
                this._cursor.dragged = this._cursor.hovered;
                this._triggerWidgetEventOrFallback('dragStart');
            }

            if (this._cursor.right && !this._cursor.draggedRight) {
                this._cursor.draggedRight = this._cursor.hovered;
            }

            if (hoveredWidget !== this._cursor.hovered) {
                this._triggerWidgetEventOrFallback('unhover', false);
                this._hoverWidget(hoveredWidget);
                this._triggerWidgetEventOrFallback('hover', false);

                if (this._cursor.hovered) {
                    this.triggerEvent('hover', this._cursor);
                }
            }

            this.triggerEvent('mouseMove', this._cursor);
        }

        if (this._eventsToTrigger) {
            for (const [eventName, payload] of this._eventsToTrigger) {
                this.triggerEvent(eventName, payload);
            }
        }

        this.refresh();
    }

    isKeyPressed(code) {
        return this._window.isKeyPressed(code);
    }

    getCursor() {
        return this._cursor;
    }

    _triggerWidgetEventOrFallback(eventName, fallback = true) {
        const widgetFunctionName = `on${capitalize(eventName)}`;

        if (!isDisabled(this._cursor.hovered)) {
            const intercepted = this._cursor.hovered[widgetFunctionName](this._cursor)
                || (eventName === 'click' && this._cursor.hovered.attributes.interceptClick);
            
            if (intercepted) {
                this._eventsToTrigger = null;
            }
        }
        
        if (fallback && this._eventsToTrigger) {
            this._eventsToTrigger.push([eventName, { ...this._cursor }])
        }
    }

    _updateModifierKeys(evt) {
        this._cursor.shift = evt.shiftKey;
        this._cursor.ctrl = evt.ctrlKey;
        this._cursor.alt = evt.altKey;
    }

    _onKeyDown(evt) {
        this._updateModifierKeys(evt);
        
        if (!isDisabled(this._cursor.focused)) {
            this._cursor.focused.onKeyDown(evt);

            if (evt.key === 'Enter' && !evt.ctrlKey) {
                this._cursor.focused.onEnter(evt);
            }
        }

        this.triggerEvent('keyDown', evt);
        this.refresh();
    }
    
    _onKeyUp(evt) {
        this._updateModifierKeys(evt);
        this.triggerEvent('keyUp', evt);
        this.refresh();
    }

    _hoverWidget(widget) {
        if (this._cursor.hovered) {
            this._cursor.hovered.setAttributes({ hovered: false });
        }

        if (widget) {
            widget.setAttributes({ hovered: true });
        }

        this._cursor.hovered = widget;
    }

    _focusWidget(widget) {
        if (this._cursor.focused) {
            this._cursor.focused.setAttributes({ focused: false });
        }

        if (widget) {
            widget.setAttributes({ focused: true });
        }

        this._cursor.focused = widget;
        this.refresh();
    }

    _resetCursor() {
        mergeObjects(this._cursor, DEFAULT_CURSOR);
    }

    _resetCursorStyle() {
        mergeObjects(this._cursor.style, DEFAULT_CURSOR_STYLE);
    }

    setRootWidget(item, { transformId = null, layer = 0, attributes = null } = {}) {
        const widget = valueToWidget(item);

        if (!this._layers[layer] && !widget) {
            return this;
        }

        // TODO: somewhat hacky, maybe there is a better way to do that
        if (transformId && widget) {
            for (const w of widget.collect()) {
                w._id = transformId(w._id);
            }
        }

        if (this._layers[layer]) {
            this._layers[layer].destroy();
        }

        this._layers[layer] = widget;
        this._focusChain = [];
        this._resetCursor();
        this._collectAllWidgets();
        this.refresh();

        if (attributes) {
            this.setWidgetAttributes(attributes);
        }

        return this;
    }

    getWidget(id) {
        if (id instanceof Widget) {
            return id;
        } else {
            return this._widgetsById[id] || null;
        }
    }

    getWidgetList(ids) {
        return ids.map(id => this.getWidget(id));
    }

    getWidgetGroup(ids) {
        return G(...ids.map(id => this.getWidget(id)));
    }

    getAllWidgets() {
        return this._widgetsByLayer.flat();
    }

    setWidgetAttributes(valuesOrSelector, thisArgOrAttributes) {
        if (isVanillaObject(valuesOrSelector)) {
            const values = valuesOrSelector;
            const thisArg = thisArgOrAttributes;

            for (const [key, attributes] of Object.entries(values)) {
                const ids = key.replace(/[A-Z0-9]/g, str => `-${str.toLowerCase()}`).split(' ').filter(str => str);

                for (const id of ids) {
                    const widget = this.getWidget(id);

                    if (widget) {
                        widget.setAttributes(attributes);

                        if (thisArg) {
                            widget.setThisArg(thisArg);
                        }
                    }
                }
            }
        } else if (valuesOrSelector) {
            let values = valuesOrSelector;
            let attributes = thisArgOrAttributes;

            if (typeof values === 'function') {
                values = this.getAllWidgets().filter(values);
            }

            const widgets = values.map(widgetOrId => this.getWidget(widgetOrId)).filter(x => x);

            if (typeof attributes === 'function') {
                attributes = widgets.map(widget => attributes(widget));
            } else if (!Array.isArray(attributes)) {
                attributes = makeArray(widgets.length, attributes);
            }

            for (let i = 0; i < widgets.length; ++i) {
                const widget = widgets[i];
                const att = attributes[i];

                widget.setAttributes(att);
            }
        }

        this._collectAllWidgets();
        this.refresh();

        return this;
    }

    setFocusChain(chain) {
        this._focusChain = chain.map(id => this.getWidget(id));

        return this;
    }

    _focusFromChain(direction) {
        const focusChain = this._focusChain.filter(widget => widget && !widget.attributes.disabled);

        if (!focusChain.length) {
            this._focusWidget(null);
            return;
        }

        const index = focusChain.indexOf(this._cursor.focused);
        let indexToFocus = 0;

        if (index === -1 && direction === -1) {
            indexToFocus = focusChain.length - 1;
        } else {
            indexToFocus = index + + direction;
        }

        indexToFocus = (indexToFocus + focusChain.length) % focusChain.length
        this._focusWidget(focusChain[indexToFocus]);

        return this;
    }

    focusNext() {
        return this._focusFromChain(1);
    }

    focusPrevious() {
        return this._focusFromChain(-1);
    }

    focus(id) {
        this._focusWidget(this.getWidget(id));
    }

    refresh() {
        this._frameManager.refresh();
    }

    _render() {
        if (!this._renderEnabled) {
            return;
        }

        for (const widget of this.getAllWidgets()) {
            widget.resetBox();
        }

        this._box = new Box(0, 0, this._canvas.width, this._canvas.height);

        if (this._aspectRatio) {
            this._box = this._box.stripToMatchAspectRatio(this._aspectRatio);
        }

        const box = this._box;
        const canvas = this._canvas;
        const cursor = this._cursor;

        this._resetCursorStyle();
        this._canvas.clear(this._backgroundColor);

        for (const rootWidget of this._layers) {
            if (rootWidget) {
                rootWidget.render({ canvas, cursor, box });
            }
        }
        this._renderCursor();

        if (this._canvas.isLoadingImage()) {
            this.refresh();
        }
    }

    _renderCursor() {
        let { x, y, style: { skin, tooltip, tooltipWidth, tooltipHeight, image, imageWidth, imageHeight } } = this._cursor;

        if (image) {
            const width = imageWidth || image.width;
            const height = imageHeight || image.height;

            this._canvas.image({
                x, y,
                image,
                width,
                height,
                verticalAlign: 'middle',
                horizontalAlign: 'center'
            });

            skin = 'grabbing';
        }

        if (tooltip) {
            const backgroundMargin = 5;
            const box = new Box(x, y,
                Math.round(tooltipWidth * this._box.w) + backgroundMargin * 2,
                Math.round(tooltipHeight * this._box.h) + backgroundMargin * 2
            );
            const baseTextSize = box.getAdaptativeSize('5.5%');

            this._canvas.drawFormattedText({
                x, y,
                text: tooltip,
                fixedWidth: box.w,
                // fixedHeight: box.h,
                textSize: baseTextSize,
                backgroundColor: 'white',
                backgroundMargin,
                backgroundBorderColor: 'black',
                mirrorHorizontally: box.x + box.w > this._box.w,
                // mirrorVertically: box.y + box.h > this._box.h
            });
        }

        this._canvas.setDomStyle({ cursor: skin });
    }
}

const STR_TO_WIDGET_CLASS = {
    generic: WidgetGeneric,
    label: WidgetGeneric,
    grid: WidgetGrid,
    line: WidgetLine,
    column: WidgetColumn
};

function valueToWidget(value) {
    if (value instanceof Widget) {
        return value;
    } else if (typeof value === 'string') {
        return stringToWidget(value);
    } else if (Array.isArray(value)) {
        return valueListToWidgets(value)[0];
    } else {
        return null;
    }
}

function valueListToWidgets(list) {
    const result = [];
    let lastWidget = null;

    for (const value of list) {
        if (typeof value === 'number') {
            lastWidget = numberToWidget(value);
            result.push(lastWidget);
        } else if (typeof value === 'string') {
            lastWidget = stringToWidget(value);
            result.push(lastWidget);
        } else if (Array.isArray(value)) {
            const children = valueListToWidgets(value);

            for (const child of children) {
                lastWidget.addChild(child);
            }
        } else if (value instanceof Widget) {
            lastWidget = value;
            result.push(lastWidget);
        }
    }

    return result;
}

function numberToWidget(force) {
    return new Widget({ attributes: { force } });
}

function isCellShape(str) {
    return ['square', 'horizontal-hexagon', 'vertical-hexagon'].includes(str);
}

function stringToWidget(string) {
    let currentStringParts = null;
    let WidgetClass = WidgetGeneric;
    const parameters = { attributes: {} };
    const fragments = string.split(' ').filter(x => x);

    for (let str of fragments) {
        const c = str[0];
        const sub = str.substring(1);

        if (c === '"') {
            currentStringParts = [];
            str = sub;
        }

        if (currentStringParts) {
            currentStringParts.push(str);

            if (str[str.length - 1] === '"') {
                const text = currentStringParts.join(' ');
                parameters.attributes.text = text.substring(0, text.length - 1);
                currentStringParts = null;
            }
        } else if (isCellShape(str)) {
            parameters.attributes.cellShape = str;
        } else if (/^\d+x\d+$/.test(str)) {
            const [width, height] = str.split('x').map(n => parseInt(n));
            parameters.attributes.width = width;
            parameters.attributes.height = height;
        } else if (c === '#') {
            parameters.id = sub;
        } else if (c === '%') {
            parameters.aspectRatio = parseFloat(sub);
        } else if (c === '*') {
            parameters.attributes.force = parseFloat(sub);
        } else if (c === ']') {
            parameters.margin = parseFloat(sub) / 100;
        } else if (isWidgetClassName(str)) {
            WidgetClass = STR_TO_WIDGET_CLASS[str];
        } else if (isColor(str)) {
            parameters.backgroundColor = str;
        }
    }

    return new WidgetClass(parameters);
}

function isWidgetClassName(str) {
    return str in STR_TO_WIDGET_CLASS;
}

function isDisabled(widget) {
    if (!widget) {
        return true;
    }

    return !!widget.attributes.disabled;
}

function isHovered(widget, cursor) {
    const hoverMargin = widget.attributes.hoverMargin || 0;

    if (widget.attributes.disabled) {
        return false;
    }

    if (!widget.box?.containsPoint(cursor, hoverMargin)) {
        return false;
    }

    if (widget.attributes.hoverable === false) {
        return false;
    }

    return true;
}
globalThis.ALL_FUNCTIONS.push(UserInterface);