export class EventTarget {
    constructor({ strict = true } = {}) {
        this._strict = strict;
        this._eventCallbacks = {};
    }

    registerEvents(eventNames) {
        for (const eventName of eventNames) {
            if (this._eventCallbacks[eventName]) {
                throw new Error(`event "${eventName}" is already registered`);
            }

            this._eventCallbacks[eventName] = [];
        }
    }

    triggerEvent(eventName, payload) {
        if (!this._eventCallbacks[eventName]) {
            if (this._strict) {
                throw new Error(`event "${eventName}" does not exist`);
            } else {
                return [];
            }
        }

        const listeners = this._eventCallbacks[eventName];
        const result = [];

        for (const { callback, thisArg } of listeners) {
            const value = callback.call(thisArg, payload, eventName);
            result.push(value);
        }

        return result;
    }

    resetEvents() {
        this._eventCallbacks = {};
    }

    on(eventName, callback, thisArg) {
        if (typeof eventName === 'object') {
            thisArg = callback;
            for (const [name, callback] of Object.entries(eventName)) {
                this.on(name, callback, thisArg);
            }
        } else {
            const names = eventName.split(' ').filter(x => x);

            for (const name of names) {
                if (!this._eventCallbacks[name]) {
                    if (this._strict) {
                        throw new Error(`event "${name}" does not exist`);
                    } else {
                        this._eventCallbacks[name] = [];
                    }
                }

                this._eventCallbacks[name].push({ callback, thisArg });
            }
        }
    }

    removeEventCallbacks(thisArg) {
        for (const [key, value] of Object.entries(this._eventCallbacks)) {
            this._eventCallbacks[key] = value.filter(v => v.thisArg !== thisArg);
        }
    }
}
globalThis.ALL_FUNCTIONS.push(EventTarget);