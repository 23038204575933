import { getArrayItem, toArray } from '../../../engine/utils/array';
import { Location } from '../../../engine/utils/location';
import { cloneObject, visitObject } from '../../../engine/utils/objects';
import { isInstanceOf, swapPropertyValues } from '../../../engine/utils/objects';
import { Deserializer } from '../../../engine/utils/serialization/deserializer';
import { capitalize } from '../../../engine/utils/string';
import { formatDuration } from '../../../engine/utils/time';
import { Timer } from '../../../engine/utils/timer';
import { AbilityPayload } from '../../data/ability-payload';
import { Character } from '../../data/characters/character';
import { Terrain } from '../../data/terrains/terrain';
import { FUNCTIONS } from '../../data/functions';
import { Item } from '../../data/items/item';
import { getImageForCharacter, getImageForTerrain, getImageForUnit } from '../graphics';
import { TooltipMaker } from '../tooltip';
import { Screen } from './screen';

const DISABLED_COLOR = '#787878';

export class GameScreen extends Screen {
    constructor(client, { mirror = false } = {}) {
        super(client);
        this._gameState = null;
        this._gameStateVersion = 0;
        this._deserializer = new Deserializer(FUNCTIONS);
        this._timer = new Timer();
        this._interaction = null;
        this._states = [];
        this._mirror = mirror;
        this._graphics = cloneObject(this._client.graphics);
        this._tooltipMaker = new TooltipMaker({ graphics: this._graphics, pointOfViewId: this._state.id });

        if (this._mirror) {
            visitObject(this._graphics, (value, key) => {
                if (key !== 'SEASONS' && Array.isArray(value) && value.length > 1) {
                    swapPropertyValues(value, 0, 1);
                }
            });
        }

        this._init();
    }

    _init() {
        this._server.on({
            notifyGameStateChange(data) {
                this._onGameState(data);
            }
        }, this);

        this._timer.on({
            timePass({ elapsed }) {
                const { MAX_TURN_DURATION } = this._gameState.constants;
                const timeStr = formatDuration(Math.max(1, MAX_TURN_DURATION - elapsed));
                const timerWidget = this._ui.getWidget('timer');

                if (timerWidget.attributes.text !== timeStr) {
                    timerWidget.setAttributes({ text: timeStr });
                    this._ui.refresh();
                }
            }
        }, this);
    }

    _onGameState({ gameState, version }) {
        this.log('NEW GAME STATE' + (version === this._gameStateVersion ? ' (ignored because same as current)' : ` (${gameState.byteLength} bytes)`));
        if (version !== this._gameStateVersion) {
            const currentActivePlayerId = this._gameState && this._gameState.activePlayer.id;
            const newGameState = this._deserializer.deserialize(gameState);
            const newTurn = newGameState.activePlayer.id !== this._gameState?.activePlayer.id;
            const gameFinished = newGameState.winner && !this._gameState?.winner;

            this._cancelAbility();
            this._gameState = newGameState;
            this._gameStateVersion = version;
            this._tooltipMaker.constants = this._gameState.constants;
            this._updateUi();
            this._ui.emulateMouseMove();

            if (this._gameState.activePlayer.id !== currentActivePlayerId) {
                this._timer.reset(this._gameState.constants.MAX_TURN_DURATION);
            }

            if (newTurn && this._gameState.constants.SHOW_START_TURN_POPUP) {
                const selfTurn = this._getSelfPlayer() === this._gameState.activePlayer;
                const message = selfTurn ? 'Your turn' : `Opponent's turn`;
                const color = this._graphics.PLAYER_COLORS[selfTurn ? 0 : 1] ;

                if (selfTurn || this._gameState.turnNumber === 1) {
                    this._popup(message, color, 1500);
                }
            }

            if (gameFinished) {
                const victory = this._getSelfPlayer() === this._gameState.winner;
                const message = victory ? 'Victory!' : `Defeat.`;
                const color = this._graphics.PLAYER_COLORS[victory ? 0 : 1] ;

                this._popup(message, color);
            }
        }
    }

    _popup(message, color, timeout) {
        this._ui.setRootWidget('#popup ]44 %7', {
            layer: 1,
            attributes: {
                popup: {
                    text: message,
                    textColor: color,
                    textSize: '85%',
                    borderWidth: '4%',
                    borderColor: 'black',
                    borderRadius: '10%',
                    backgroundColor: 'white',
                    showPointer: true
                }
            }
        });

        if (timeout) {
            setTimeout(() => this._removePopup(), timeout);
        }
    }

    _removePopup() {
        this._ui.setRootWidget(null, { layer: 1 });

        if (this._gameState.winner && this._gameState.constants.EXIT_ON_GAME_END) {
            this._exit();
        }
    }

    _getSelfPlayer() {
        return this._gameState.players.find(player => player.id === this._state.id);
    }

    _getOpponentPlayer() {
        return this._gameState.players.find(player => player.id !== this._state.id);
    }

    _canLocationUseAbility(location) {
        const entity = location.entity;
        const isCharacter = isInstanceOf(entity, Character);
        const isItem = isInstanceOf(entity, Item);

        if ((!isCharacter && !isItem) || entity.owner !== this._gameState.activePlayer || this._gameState.winner) {
            return false;
        }

        const ability = entity.getAbility(this._gameState.currentSeasonIndex);
        const actionCountOk = isItem || (entity.extraActionCount > 0 || (entity.remainingActionCount > 0 && this._gameState.remainingActionCount > 0));
        const abilityConditionOk = !ability.condition || ability.condition({ game: this._gameState, source: entity }, this._gameState.constants);

        return actionCountOk && abilityConditionOk;
    }

    _updateUi() {
        const { SEASONS } = this._graphics;
        const game = this._gameState;
        const selfPlayer = this._getSelfPlayer();
        const opponentPlayer = this._getOpponentPlayer();  

        const [ currentSeasonName, currentSeasonColor ] = SEASONS[game.currentSeasonIndex];

        this._cancelAbility();

        this._ui.setWidgetAttributes({
            battlefield: this._getBattlefieldGridAttributes(),
            selfCharacters: this._getCharacterGridAttributes(selfPlayer),
            opponentCharacters: this._getCharacterGridAttributes(opponentPlayer),
            selfItems: this._getItemGridAttributes(selfPlayer),
            opponentItems: this._getItemGridAttributes(opponentPlayer),
            selfName: this._getPlayerNameAttributes(selfPlayer),
            opponentName: this._getPlayerNameAttributes(opponentPlayer),
            tooltip: {
                borderColor: 'black',
                backgroundColor: 'white',
                borderWidth: 1
            },
            seasonName: {
                text: capitalize(currentSeasonName),
                textColor: currentSeasonColor
            },
            seasonStep: {
                text: game.seasonChangeCount === 0 ? '(start)' : '(end)',
                textSize: '60%'
            },
            turnNumber: {
                text: `T${game.turnNumber}`,
                horizontalAlign: 'left',
                textSize: '60%'
            },
            timer: {
                disabled: game.activePlayer !== selfPlayer,
                disabled_textColor: DISABLED_COLOR
            },
            endTurn: {
                text: 'Opponent turn',
                backgroundColor: 'white',
                borderColor: 'black',
                borderRadius: 5,
                borderWidth: 2,
                textColor: 'black',
                textSize: '70%',
                active: game.activePlayer === selfPlayer,
                active_text: 'End turn',
                active_onClick() {
                    this._endTurn()
                },
                finished: !!game.winner,
                finished_text: 'Exit',
                finished_onClick() {
                    this._exit();
                },
                disabled: !game.winner && game.activePlayer !== selfPlayer,
                disabled_textColor: DISABLED_COLOR,
                disabled_borderColor: DISABLED_COLOR,
                hovered_overlayColor: 'white',
                hovered_overlayAlpha: 0.3
            },
            infoPanel: {
                borderColor: 'black',
                borderWidth: '1%',
                borderRadius: '10%'
            },
            history: {
                formattedText: this._tooltipMaker.history(this._gameState),
                borderColor: 'black',
                backgroundColor: 'white',
                borderWidth: 1
            }
        }, this);

        if (game.winner) {
            this._timer.destroy();
        }
    }

    _exit() {
        this._client.showLobbyScreen();
    }

    _endTurn() {
        this._client.sendGameAction('end-turn', null);
    }

    _surrender() {
        this._client.sendGameAction('surrender', null);
    }

    _useAbility() {
        const { source, targets } = this._interaction;

        this._client.sendGameAction('ability', {
            source: source.location.toInfo(),
            targets: targets.map(location => location.toInfo())
        });

        this._cancelAbility();
    }

    _updateAbilityInteraction() {
        const { ability, source, targets } = this._interaction;
        const constants = this._gameState.constants;
        const nextTargetIndex = this._interaction.nextTargetIndex;
        const targetMethodName = `target${nextTargetIndex}`;
        const prevHoverMethodName = `target${nextTargetIndex - 1}Hover`;
        const hoverMethodName = `target${nextTargetIndex}Hover`;
        const nextTargetMethodName = `target${nextTargetIndex + 1}`;
        const highlightMethodName = `target${nextTargetIndex}Highlight`;
        const affected = [source.location];

        if (!ability[targetMethodName]) {
            this._useAbility();
            return;
        }

        if (ability[prevHoverMethodName]) {
            affected.push(...toArray(ability[prevHoverMethodName](this._interaction, constants)));
        }
        
        if (affected.length === 1 && targets.length) {
            affected.push(getArrayItem(targets, -1));
        }

        const validTargetLocations = ability[targetMethodName](this._interaction, constants);

        this._setLocationWidgetAttributes('all', { source: false, dimmed: true, selected: false, highlighted: false, validTarget: false });
        this._setLocationWidgetAttributes(validTargetLocations, { dimmed: false, validTarget: true });
        this._setLocationWidgetAttributes(affected, { source: true, dimmed: false });

        if (this._interaction.hovered) {
            const payload = this._interaction.cloneAndIncludeHovered();
            const locationsToHover = toArray(ability[hoverMethodName] ? ability[hoverMethodName](payload, constants) : this._interaction.hovered);
            const locationsToHighlight = [];

            if (ability[highlightMethodName] || ability[nextTargetMethodName]) {
                const key = ability[highlightMethodName] ? highlightMethodName : nextTargetMethodName;

                locationsToHighlight.push(...toArray(ability[key](payload, constants)));
            }

            this._setLocationWidgetAttributes(locationsToHover, { selected: true });
            this._setLocationWidgetAttributes(locationsToHighlight, { dimmed: false, highlighted: true });
        }
    }

    _clickAbilityTarget(widget) {
        this._interaction.targets.push(widget.data);
        this._interaction.hovered = null;
        this._updateAbilityInteraction();
    }

    _hoverAbilityTarget(widget) {
        this._interaction.hovered = widget.data;
        this._updateAbilityInteraction();
    }

    _unhoverAbilityTarget() {
        this._interaction.hovered = null;
        this._updateAbilityInteraction();
    }

    _clickAbility(widget) {
        this._cancelAbility();

        const source = widget.data.entity;
        const ability = source.getAbility(this._gameState.currentSeasonIndex);

        this._interaction = new AbilityPayload(this._gameState, ability, source);

        this._updateAbilityInteraction();
        this._ui.emulateMouseMove();
    }

    _cancelAbility() {
        if (!this._interaction) {
            return;
        }

        this._interaction = null;
        this._resetHighlights();
        this._ui.emulateMouseMove();
    }

    _resetHighlights() {
        this._setLocationWidgetAttributes('all', {
            source: false,
            dimmed: false,
            selected: false,
            highlighted: false,
            validTarget: false
        });
    }

    _setLocationWidgetAttributes(locations, attributes) {
        const widgets = this._getWidgetsFromLocations(locations);

        this._ui.setWidgetAttributes(widgets, attributes);
    }

    _getWidgetsFromLocations(locations) {
        if (locations === 'all') {
            return this._ui.getAllWidgets().filter(widget => isInstanceOf(widget.data, Location));
        } if (typeof locations === 'function') {
            return this._ui.getAllWidgets().filter(widget => isInstanceOf(widget.data, Location) && locations(widget.data));
        } else if (Array.isArray(locations)) {
            return locations.map(location => this._ui.getWidget(location.toId()));
        } else if (isInstanceOf(locations, Location)) {
            return [this._ui.getWidget(locations.toId())];
        } else {
            return [];
        }
    }

    _getPov(entity) {
        if (!entity) {
            return 0;
        }

        return entity.owner === this._getSelfPlayer() ? 1 : 2;
    }

    _getWidgetLocationListeners() {
        return {
            source_interceptClick: true,
            source_onClick: null,
            validTarget: false,
            validTarget_onClick(widget) {
                this._clickAbilityTarget(widget);
            },
            validTarget_onHover(widget) {
                this._hoverAbilityTarget(widget);
            },
            validTarget_onUnhover(widget) {
                this._unhoverAbilityTarget(widget);
            },
            validTarget_onDragStart(widget) {
                this._clickAbilityTarget(widget);
            },
            validTarget_onDragEnd(widget) {
                this._clickAbilityTarget(widget);
            }
        };
    }

    _getBattlefieldGridAttributes() {
        const { MAP } = this._gameState.constants;

        return {
            data: this._gameState.battlefield.cells,
            width: MAP.width,
            height: MAP.height,
            mirror: this._getSelfPlayer().isFirst === this._mirror,
            cellAspectRatio: 1,
            cellShape: MAP.shape,
            gridColor: 'black',
            gridLineThickness: 1,
            children: widget => ({
                background: getImageForTerrain(widget.data.terrain, this._getPov(widget.data.terrain), this._graphics),
                image: getImageForUnit(widget.data.entity, this._getPov(widget.data.entity), this._graphics),
                topLeftBadge: this._getUnitTopLeftBadge(widget.data.entity),
                hoverMargin: 1,
                shape: MAP.shape,
                textSize: '50%',
                highlighted_backgroundOverlayColor: 'orange',
                highlighted_backgroundOverlayAlpha: 0.5,
                dimmed_overlayColor: 'black',
                dimmed_overlayAlpha: 0.35,
                selected_borderColor: 'black',
                selected_borderWidth: 2,
                // selected_overlayColor: 'white',
                // selected_overlayAlpha: 0.35,
                source_borderColor: 'black',
                source_borderWidth: 2,
                ...this._getWidgetLocationListeners()
            })
        };
    }

    _getCharacterGridAttributes(player) {
        const { CHARACTER_ASPECT_RATIO } = this._graphics;

        return {
            cellAspectRatio: CHARACTER_ASPECT_RATIO,
            spaceBetweenCells: '30%',
            data: this._gameState.spatialIndex.getGrid(player.getCharacterGridName()).cells,
            children: widget => ({
                image: getImageForCharacter(widget.data.entity, this._getPov(widget.data.entity), this._graphics),
                backgroundColor: 'white',
                borderRadius: '10%',
                borderWidth: '2%',
                topLeftTextSize: '25%',
                highlighted_backgroundOverlayColor: 'orange',
                highlighted_backgroundOverlayAlpha: 0.5,
                active: this._canLocationUseAbility(widget.data),
                active_onClick(widget) {
                    this._clickAbility(widget)
                },
                dimmed_overlayColor: 'black',
                dimmed_overlayAlpha: 0.3,
                dimmed_onClick: null,
                ...this._getWidgetLocationListeners()
            })
        };
    }

    _getItemGridAttributes(player) {
        const { ITEM_IMAGES } = this._graphics;

        return {
            data: this._gameState.spatialIndex.getGrid(player.getItemGridName()).cells,
            spaceBetweenCells: 5,
            children: widget => ({
                image: ITEM_IMAGES[widget.data.entity?.name],
                hoverMargin: 5,
                backgroundColor: 'white',
                borderRadius: '10%',
                borderWidth: '2%',
                imageScale: 0.75,
                highlighted: false,
                highlighted_overlayColor: 'orange',
                highlighted_overlayAlpha: 0.5,
                active: this._canLocationUseAbility(widget.data),
                active_onClick(widget) {
                    this._clickAbility(widget)
                },
                dimmed: false,
                dimmed_overlayColor: 'black',
                dimmed_overlayAlpha: 0.3,
                dimmed_onClick: null,
                ...this._getWidgetLocationListeners()
            })
        }
    }

    _getPlayerNameAttributes(player) {
        const { PLAYER_COLORS } = this._graphics;
        const i = player === this._getSelfPlayer() ? 0 : 1;

        return {
            text: `${player.username}`,
            textColor: PLAYER_COLORS[i],
            active: this._gameState.activePlayer === player,
            active_borderRadius: '20%',
            active_backgroundColor: 'white'
        };
    }

    _getInfoPanelText(cursor) {
        const location = cursor?.hovered?.data;
        const entity = location?.entity;

        if (isInstanceOf(entity, Character)) {
            return this._tooltipMaker.character(entity, this._gameState.currentSeasonIndex);
        } else if (isInstanceOf(entity, Item)) {
            return this._tooltipMaker.item(entity);
        } else if (isInstanceOf(location, Location) && isInstanceOf(location.terrain, Terrain)) {
            return this._tooltipMaker.cell(location, this._getSelfPlayer());
        } else {
            return this._tooltipMaker.rules();
        }
    }

    _setTooltip() {
        this._ui.setWidgetAttributes({
            tooltip: {
                formattedText: this._getInfoPanelText(this._ui.getCursor())
            }
        });
    }

    show() {
        this._setUi();

        this._ui.on({
            mouseUp() {
                this._cancelAbility();
            },
            mouseDown() {
                this._removePopup();
            },
            mouseMove() {
                this._setTooltip();
            },
            keyDown(evt) {
                const noModifier = !evt.ctrlKey && !evt.altKey && !evt.shiftKey;

                if (evt.code.startsWith('Digit') && !evt.altKey && !evt.ctrlKey) {
                    const index = parseInt(evt.code.substring('Digit'.length)) - 1;
                    const gridWidgetId = evt.shiftKey ? 'self-items' : 'self-characters';
                    const widget = this._ui.getWidget(gridWidgetId).children[index];

                    if (widget) {
                        this._cancelAbility();
                        widget.onClick();
                    }
                } else if (noModifier && evt.code === 'Space') {
                    this._endTurn();
                } else if (noModifier && evt.code === 'Escape') {
                    this._cancelAbility();
                } else if (evt.ctrlKey && evt.shiftKey && !evt.altKey && evt.code === 'Space') {
                    this._surrender();
                }
            }
        }, this);

        this._client.getGameState(this._state.gameId).then(data => this._onGameState(data));
    }

    _setUi() {
        const { BACKGROUND_COLOR } = this._graphics;
        const { MAX_ITEM_COUNT_PER_PLAYER, CHARACTER_COUNT_PER_PLAYER } = this._client.constants;

        const options = {};

        if (this._mirror) {
            options.transformId = (id) => {
                if (id.startsWith('self-')) {
                    return id.replace('self-', 'opponent-');
                } else if (id.startsWith('opponent-')) {
                    return id.replace('opponent-', 'self-');
                } else {
                    return id;
                }
            }
        }

        this._ui.setRootWidget([
            `line ${BACKGROUND_COLOR}`, [
                'column', [
                    'column #info-panel ]5 *2', [
                        'line *2 %5', [
                            0.8,
                            'column *1', [
                                1,
                                '#turn-number *1',
                                0.2
                            ],
                            0.2,
                            '#season-name *3',
                            0.2,
                            'column *1', [
                                1,
                                '#season-step *1',
                                0.2,
                            ],
                            0.8
                        ],
                        '#end-turn %5 *1',
                        '#timer %5 *1',
                    ],
                    '#history ]5 *3',
                    `line ]10 *1`, [
                        // `#self-items-label %3 *1`,
                        `grid ${MAX_ITEM_COUNT_PER_PLAYER}x1 #self-items *3`,
                    ],
                    `#self-name %5 ]10 *1`
                ],
                'column *2', [
                    `grid ${CHARACTER_COUNT_PER_PLAYER}x1 #opponent-characters ]7`,
                    `grid #battlefield *5`,
                    `grid ${CHARACTER_COUNT_PER_PLAYER}x1 #self-characters ]7`,
                ],
                'column', [
                    '#opponent-name %5 ]10 *1',
                    `line ]10 *1`, [
                        // `#opponent-items-label %3 *1`,
                        `grid ${MAX_ITEM_COUNT_PER_PLAYER}x1 #opponent-items *3`,
                    ],
                    0.5,
                    '#tooltip ]5 *4.5'
                ]
            ]
        ], options);
    }

    _getUnitTopLeftBadge(unit) {
        if (!unit || unit.remainingTurnCount === Infinity) {
            return null;
        }

        return `circle 65% white "${unit.remainingTurnCount.toString()}"`;
    }

    destroy() {
        this._timer.destroy();
        this._server.removeEventCallbacks(this);
    }
}
globalThis.ALL_FUNCTIONS.push(GameScreen);