export class AbilityPayload {
    constructor(game, ability, source, targets = []) {
        this.game = game;
        this.ability = ability;
        this.source = source;
        this.targets = targets;
        this.hovered = null;
    }

    get target1() {
        return this.targets[0];
    }

    get target2() {
        return this.targets[1];
    }

    get nextTargetIndex() {
        return this.targets.length + 1;
    }

    cloneAndIncludeHovered() {
        const clone = new AbilityPayload(this.game, this.ability, this.source);

        clone.targets = this.targets.slice();
        clone.targets.push(this.hovered);

        return clone;
    }
}
globalThis.ALL_FUNCTIONS.push(AbilityPayload);