import { Entity } from '../entity';

export class Character extends Entity {
    constructor({ owner, location, tags } = {}) {
        super({ owner, location, tags });

        this.remainingActionCount = 0;
        this.extraActionCount = 0;
    }

    makeMessageFromAbilityUse(game, ability, target1, target2) {
        let str = `[T${game.turnNumber}] ${this.owner.username} used *${this.title}*'s $SEASON_${game.currentSeasonIndex} ability`;

        if (ability.target1) {
            if (!ability.target2) {
                str += ` on (${target1.x},${target1.y})`;
            } else {
                str += ` from (${target1.x},${target1.y}) to (${target2.x},${target2.y})`;
            }
        }

        str += '.';

        return str;
    }
}
globalThis.ALL_FUNCTIONS.push(Character);