import { Entity } from '../entity';

export class Terrain extends Entity {
    constructor({ owner, location, tags } = {}) {
        super({ owner, location, tags });
    }

    isObjective() {
        return false;
    }

    allowsLineOfSight() {
        return true;
    }

    allowsUnitMovement() {
        return true;
    }
}
globalThis.ALL_FUNCTIONS.push(Terrain);