import { getWebSocketServerUrl } from 'outpost';
import { Client } from './game/client/client';

async function main() {
    const webSocketServerUrl = getWebSocketServerUrl();
    const useLocalStorage = true;
    const client = new Client({ webSocketServerUrl, useLocalStorage });

    await client.start();
}

main();