import { isInstanceOf } from '../../../engine/utils/objects';
import { CONSTANTS } from '../constants';
import { Factory } from '../terrains/factory';
import { Headquarters } from '../terrains/headquarters';
import { Unit } from '../unit';
import { getAllCells, getAlliedUnitCells, getCellsWithinMovementRange, getPath } from '../utils';
import { Character } from './character';

Object.assign(CONSTANTS, {
    DROWS_SUN_POWER: 5,
    DROWS_RAIN_MAX_RANGE: 6
});

export class Drows extends Character {
    constructor(options) {
        super(options);
    }

    static name = 'Drows'
    static title = 'Drows'
    static abilities = [
        {
            description: `Spawn a $DROWS_SUN_POWER-strength unit on an allied headquarters or factory.`,
            target1({ source }) {
                return getAllCells(source).filter(cell => cell.terrain.owner === source.owner && (isInstanceOf(cell.terrain, Headquarters) || isInstanceOf(cell.terrain, Factory)));
            },
            trigger({ game, source, target1 }, C) {
                game.spawnUnit({ source, location: target1, unit: new Unit(C.DROWS_SUN_POWER), owner: source.owner });
            }
        }, {
            description: `Double an allied unit's strength.`,
            target1({ source }) {
                return getAlliedUnitCells(source);
            },
            trigger({ game, source, target1 }) {
                game.setUnitStrength({ source, unit: target1.entity, modifier: target1.entity.strength });
            }
        }, {
            description: `Move an allied unit by 1-6 cells. It loses 1 strength per distance travelled.`,
            target1({ source }) {
                return getAlliedUnitCells(source).filter(location => location.entity.strength > 1);
            },
            target2({ target1 }, C) {
                const maxRange = Math.min(target1.entity.strength - 1, C.DROWS_RAIN_MAX_RANGE);

                return getCellsWithinMovementRange(target1, maxRange);
            },
            trigger({ game, source, target1, target2 }) {
                game.setUnitStrength({ source, unit: target1.entity, modifier: -(getPath(target1, target2).length - 1) });
                game.moveUnit({ source, unit: target1.entity, location: target2 });
            }
        }
    ]
}
globalThis.ALL_FUNCTIONS.push(Drows);