import { Screen } from './screen';
import { Character } from '../../data/characters/character';
import { BUTTON_STYLE, SMALL_BUTTON_STYLE } from '../styles';
import { CHARACTERS } from '../../data/characters';
import { makeArray } from '../../../engine/utils/array';
import { getEntityId } from '../../data/entity-class-list';
import { TooltipMaker } from '../tooltip';
import { CONSTANTS } from '../../data/constants';
import { isInstanceOf } from '../../../engine/utils/objects';

const PREBUILT_SET_1 = ['Ulrich', 'Ewys', 'Blobby', 'Tazad'];
const PREBUILT_SET_2 = ['Zafiel', 'Isedrix', 'Odrec', 'Drows'];

const CHARACTER_COUNT_PER_PLAYER = CONSTANTS.CHARACTER_COUNT_PER_PLAYER;

const TOOLTIP_WIDTH = 287 / 1279; // Temporary
const TOOLTIP_HEIGHT = 430 / 720; // Temporary

export class LobbyScreen extends Screen {
    constructor(client) {
        super(client);

        this._tooltipMaker = new TooltipMaker(this._client.graphics);
        this._server.on({
            notifyStateChange() {
                this._updatePlayButtonState();
            }
        }, this);
    }

    _toggleMatchmaking() {
        const selectedCharacters = this._getSelectedCharacters();
        const stateOk = (this._state.activity === 'idle' && selectedCharacters.every(char => !!char)) || this._state.activity === 'matchmaking';

        if (!stateOk) {
            return;
        }

        if (this._state.activity === 'idle') {
            const tools = {
                characterIds: selectedCharacters.map(char => getEntityId(char))
            };

            this._server.query('startMatchmaking', { tools });
        } else if (this._state.activity === 'matchmaking') {
            this._server.query('stopMatchmaking', null);
        }
    }

    _getSelectedCharacters() {
        return this._ui.getWidget('selected-characters').children.map(widget => widget.data);
    }

    _onSelectedCharactersChange() {
        this._updateGrids();
        this._updatePlayButtonState();
        this._saveSetToLocalStorage();
    }

    _loadSetFromLocalStorage() {
        const characters = new Array(CHARACTER_COUNT_PER_PLAYER);
        const ids = this._client.loadFromLocalStorage('selected-set').split(' ').filter(x => x);

        for (let i = 0; i < characters.length; ++i) {
            characters[i] = ids[i];
        }

        this._loadSet(characters);
    }

    _saveSetToLocalStorage() {
        const characterIds = this._getSelectedCharacters().map(char => getEntityId(char) || 'null').join(' ');

        this._client.saveToLocalStorage('selected-set', characterIds);
    }

    _updateGrids() {
        const { CHARACTER_ASPECT_RATIO, CHARACTER_IMAGES } = this._client.graphics;

        this._ui.setWidgetAttributes(['available-characters', 'selected-characters'], {
            gridColor: 'black',
            cellAspectRatio: CHARACTER_ASPECT_RATIO,
            gridLineThickness: 2,
            children: widget => {
                const characterImage = CHARACTER_IMAGES[widget.data?.name];

                return {
                    image: characterImage,
                    draggedImage: characterImage,
                    draggedImageScale: 0.5,
                    showPointer: !!characterImage,
                    backgroundColor: 'white',
                    tooltip: this._tooltipMaker.character(widget.data, -1, false),
                    tooltipWidth: TOOLTIP_WIDTH,
                    tooltipHeight: TOOLTIP_HEIGHT
                }
            }
        });
    }

    _updatePlayButtonState() {
        if (!this._state.id) {
            return;
        }

        let disabled = true;
        let text = 'Find match';

        if (this._state.activity === 'idle') {
            disabled = this._getSelectedCharacters().some(char => !char);
        } else if (this._state.activity === 'matchmaking') {
            disabled = false;
            text = 'Cancel';
        }

        this._ui.setWidgetAttributes({
            play: { disabled, text }
        });

        this._ui.emulateMouseMove();
    }

    _isIdle() {
        return this._state.activity === 'idle';
    }

    _loadSet(characters) {
        if (!characters) {
            characters = makeArray(CHARACTER_COUNT_PER_PLAYER, null);
        }

        this._ui.setWidgetAttributes({
            selectedCharacters: {
                data: characters.map(characterId => CHARACTERS.instanciate(characterId))
            }
        });

        this._onSelectedCharactersChange();
    }

    destroy() {
        this._server.removeEventCallbacks(this);
    }

    _setUi() {
        const { BACKGROUND_COLOR } = this._client.graphics;
        const CHARACTER_COUNT = CHARACTERS.size();

        this._ui.setRootWidget([
            `column ${BACKGROUND_COLOR}`, [
                0.5,
                'line *4', [
                    2,
                    '#game-name ]5 *2',
                    1,
                    'column ]10', [
                        0.4,
                        '#username *1.2',
                        0.4
                    ]
                ],
                'line *16', [
                    'column ]10', [
                        'label "Available characters:" *1',
                        0.3,
                        `grid #available-characters *5 ]2 ${CHARACTER_COUNT}x1`,
                        1.2,
                        'label "Selected characters:" *1',
                        0.3,
                        `grid #selected-characters *5 ${CHARACTER_COUNT_PER_PLAYER}x1`,
                        0.4,
                        'line *1', [
                            3,
                            '#reset %4 *1',
                            0.5,
                            '#load-set-1 %4 *1',
                            0.5,
                            '#load-set-2 %4 *1',
                            3
                        ]
                    ]
                ],
                'line *3', [
                    3,
                    '#play %4 ]10 *4',
                    2,
                    'column ]10 *1', [
                        1,
                        '#logout %4 *1'
                    ]
                ]
            ]
        ]);
    }

    show() {
        const { GAME_NAME, GAME_NAME_COLOR, CHARACTER_ASPECT_RATIO } = this._client.graphics;

        this._setUi();
        this._ui.setWidgetAttributes({
            play: {
                text: 'Find match',
                ...BUTTON_STYLE,
                onClick() {
                    this._toggleMatchmaking();
                }
            },
            reset: {
                text: 'Reset',
                ...SMALL_BUTTON_STYLE,
                onClick() {
                    this._loadSet(null); 
                }
            },
            loadSet1: {
                text: 'Load set 1', 
                ...SMALL_BUTTON_STYLE,
                onClick() {
                    this._loadSet(PREBUILT_SET_1);
                }
            },
            loadSet2: {
                text: 'Load set 2',
                ...SMALL_BUTTON_STYLE,
                onClick() {
                    this._loadSet(PREBUILT_SET_2);
                }
            },
            logout: {
                text: 'Log out',
                ...SMALL_BUTTON_STYLE,
                onClick() {
                    this._client.logout();
                }
            },
            username: {
                text: this._client.state.username,
                textSize: '80%',
                backgroundColor: 'white',
                borderRadius: '15%'
            },
            gameName: {
                text: GAME_NAME,
                textColor: GAME_NAME_COLOR
            },
            selectedCharacters: {
                children: {
                    onDragEnd(widget, { dragged }) {
                        if (dragged.data && isInstanceOf(dragged.data, Character)) {
                            if (dragged.parent.id === 'selected-characters') {
                                const tmp = widget.data;
                                widget.data = dragged.data;
                                dragged.data = tmp;
                            } else if (!widget.parent.children.some(w => w.data?.name === dragged.data?.name)) {
                                widget.data = dragged.data;
                            }

                            this._onSelectedCharactersChange();
                        }
                    },
                    onRightClick(widget) {
                        widget.data = null;
                        this._onSelectedCharactersChange();
                    }
                }
            },
            availableCharacters: {
                data: CHARACTERS.list().map(CharacterClass => new CharacterClass()),
                children: {
                    clickOnSelfDrag: false,
                    onClick(widget) {
                        const selectedCharactersWidget = this._ui.getWidget('selected-characters');
                        const characterCell = selectedCharactersWidget.children.find(w => w.data === widget.data);
                        const availableCell = selectedCharactersWidget.children.find(w => !w.data);

                        if (!characterCell && availableCell) {
                            availableCell.data = widget.data;
                            this._onSelectedCharactersChange();
                        }
                    },
                    onDragEnd(widget, { dragged }) {
                        if (dragged.parent.id === 'selected-characters') {
                            dragged.data = null;
                            this._onSelectedCharactersChange();
                        }
                    },
                    onRightClick(widget) {
                        const selectedCharactersWidget = this._ui.getWidget('selected-characters');
                        const characterCell = selectedCharactersWidget.children.find(w => w.data === widget.data);

                        if (characterCell) {
                            characterCell.data = null;
                            this._onSelectedCharactersChange();
                        }
                    }
                }
            }
        }, this);

        this._loadSetFromLocalStorage();

        if (CONSTANTS.AUTO_MATCHMAKING) {
            this._toggleMatchmaking();
        }
    }
}
globalThis.ALL_FUNCTIONS.push(LobbyScreen);