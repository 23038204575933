import { makeArray } from '../../utils/array';
import { Location } from '../../utils/location';
import { isInstanceOf, isVanillaObject, mergeObjects } from '../../utils/objects';

export class Widget {
    constructor(parameters = {}) {
        const {
            id = '',
            attributes = {},
            children = [],
            margin = 0,
            aspectRatio = null,
            backgroundColor = null,
            data = undefined
        } = parameters;

        this._id = id;
        this._attributes = {};
        this._defaultAttributes = this.constructor.DEFAULT_ATTRIBUTES;
        this._attributesByTag = { base: {} };
        this._children = children;
        this._parent = null;
        this._aspectRatio = aspectRatio;
        this._margin = margin;
        this._backgroundColor = backgroundColor;
        this._data = data;
        this._box = null;
        this._thisArg = null;

        this.setAttributes(attributes);
    }

    static DEFAULT_ATTRIBUTES = {}

    get id() {
        return this._id;
    }

    get children() {
        return this._children;
    }

    get parent() {
        return this._parent;
    }

    get attributes() {
        return this._attributes;
    }

    get box() {
        return this._box;
    }

    get data() {
        return this._data;
    }

    set data(value) {
        this._data = value;
    }

    setThisArg(thisArg) {
        this._thisArg = thisArg;

        for (const child of this._children) {
            child.setThisArg(thisArg);
        }
    }

    resetBox() {
        this._box = null;
    }

    addChild(child) {
        child._parent = this;
        this._children.push(child);
    }

    collect(predicate) {
        const result = [];

        if (!predicate || predicate(this)) {
            result.push(this);
        }

        for (const child of this._children) {
            result.push(...child.collect(predicate))
        }

        return result;
    }

    get(attributeName) {
        const value = this._attributes[attributeName];

        if (typeof value === 'function') {
            return value(this);
        } else {
            return value;
        }
    }

    setAttributes(attributes) {
        if (!attributes) {
            return;
        }

        if (typeof attributes === 'function') {
            attributes = attributes(this, this.parent?.children.indexOf(this));
        }

        for (const [key, value] of Object.entries(attributes)) {
            if (key === 'data' || key === 'children') {
                continue;
            } else {
                const underscoreIndex = key.indexOf('_');
                const tag = underscoreIndex === -1 ? 'base' : key.substring(0, underscoreIndex);
                const name = underscoreIndex === -1 ? key : key.substring(underscoreIndex + 1);

                if (!this._attributesByTag[tag]) {
                    this._attributesByTag[tag] = {};
                }

                this._attributesByTag[tag][name] = value;
            }
        }

        this._computeAttributes();

        if (attributes.data) {
            this._setData(attributes.data);
        }

        if (attributes.children) {
            this._setChildrenAttributes(attributes.children);
        }

        // TODO: remove this specific case but keep the same idea
        if (typeof attributes.disabled === 'boolean') {
            this._setChildrenAttributes({ disabled: attributes.disabled });
        }
    }

    setAttributesCallback() {

    }

    _computeAttributes() {
        this._attributes = Object.assign({}, this._defaultAttributes);

        for (const [tag, value] of Object.entries(this._attributesByTag)) {
            if (this._attributes[tag] || tag === 'base') {
                Object.assign(this._attributes, value);
            }
        }

        this.setAttributesCallback();
    }

    _setChildrenAttributes(attributes) {
        if (!attributes) {
            return;
        }

        const childrenAttributes = Array.isArray(attributes)
            ? attributes
            : makeArray(this._children.length, attributes);

        for (let i = 0; i < this._children.length; ++i) {
            const child = this._children[i];
            const childAttributes = childrenAttributes[i];

            child.setAttributes(childAttributes);
        }
    }

    _setData(data) {
        if (Array.isArray(data)) {
            for (let i = 0; i < this._children.length; ++i) {
                const child = this._children[i];
                const childData = data[i];

                child._setData(childData);
            }
        } else {
            this.data = data;

            if (!this._id && isInstanceOf(this.data, Location)) {
                this._id = this.data.toId();
            }
        }
    }

    render({ canvas, box, cursor }) {
        if (this._backgroundColor) {
            canvas.rectangle({ ...box, fillColor: this._backgroundColor });
        }

        if (this._margin) {
            box = box.stripRatio(this._margin);
        }

        if (this._aspectRatio) {
            box = box.stripToMatchAspectRatio(this._aspectRatio);
        }

        this._box = box;

        if (box) {
            this._render({ canvas, box, cursor });
        }
    }

    _render() {

    }

    onHover(cursor){
        return this._callListener('onHover', cursor);
    }

    onUnhover(cursor){
        return this._callListener('onUnhover', cursor);
    }

    onClick(cursor) {
        return this._callListener('onClick', cursor);
    }

    onMouseUp(cursor) {
        return this._callListener('onMouseUp', cursor);
    }

    onMouseDown(cursor) {
        return this._callListener('onMouseDown', cursor);
    }

    onRightClick(cursor) {
        return this._callListener('onRightClick', cursor);
    }

    onDragStart(cursor) {
        return this._callListener('onDragStart', cursor);
    }

    onDragEnd(cursor) {
        return this._callListener('onDragEnd', cursor);
    }

    onKeyDown(evt) {
        return this._callListener('onKeyDown', evt);
    }

    onEnter(evt) {
        return this._callListener('onEnter', evt);
    }

    destroy() {

    }

    _callListener(key, arg) {
        const func = this._attributes[key];
        const thisArg = this._thisArg;

        if (typeof func === 'function') {
            const intercepted = func.call(thisArg, this, arg) !== false;

            return intercepted;
        } else {
            return false;
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Widget);