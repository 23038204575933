import { Character } from './characters/character';
import { Entity } from './entity';

export class Unit extends Entity {
    constructor(parameters = {}) {
        if (typeof parameters === 'number') {
            parameters = { strength: parameters };
        }

        const { owner, location, strength = 0, remainingTurnCount = Infinity } = parameters;

        super({ owner, location });

        this.strength = strength;
        this.remainingTurnCount = remainingTurnCount;
    }

    static name = 'Unit'

    static postEventCallbacks = {
        endTurn({ self, game }) {
            // TODO: this should probably be directly handled by the character that causes this
            self.remainingTurnCount -= 1;

            if (self.remainingTurnCount === 0) {
                game.killUnit({ source: self, unit: self });
            }
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Unit);