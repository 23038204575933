import { makeObjectFromArray } from '../../engine/utils/objects';

export class EntityClassList {
    constructor(list = []) {
        this._classList = list;
        this._nameToClass = makeObjectFromArray(list, f => f.name);
    }

    names() {
        return this._classList.map(f => f.name);
    }

    instanciate(name, params) {
        if (!name) {
            return null;
        }

        const ClassObject = this._nameToClass[name];

        if (ClassObject) {
            return new ClassObject(params);
        } else {
            return null;
        }
    }

    list() {
        return this._classList;
    }

    size() {
        return this._classList.length;
    }

    filter(SuperClass) {
        return this._classList.filter(classObj => classObj.prototype instanceof SuperClass);
    }

    nameToClassMapping() {
        return this._nameToClass;
    }
}

export function getEntityId(entityClass) {
    if (!entityClass) {
        return null;
    }

    return entityClass.name;
}
globalThis.ALL_FUNCTIONS.push(EntityClassList);