export class Box {
    constructor(x, y, w, h) {
        this.x = x;
        this.y = y;
        this.width = w;
        this.height = h;
    }

    get w() {
        return this.width;
    }

    set w(value) {
        this.width = value;
    }

    get h() {
        return this.height;
    }

    set h(value) {
        this.height = value;
    }

    cx() {
        return this.x + this.width / 2;
    }

    cy() {
        return this.y + this.height / 2;
    }

    round() {
        // const newX = Math.round(this.x);
        // const newY = Math.round(this.y);
        // const dx = this.x - newX;
        // const dy = this.y - newY;

        // return new Box(
        //     newX,
        //     newY,
        //     Math.round(this.w - dx),
        //     Math.round(this.h - dy)
        // );

        return new Box(
            Math.floor(this.x),
            Math.floor(this.y),
            Math.ceil(this.w),
            Math.ceil(this.h),
        );
    }

    equals(box) {
        return box
            && this.x === box.x
            && this.y === box.y
            && this.w === box.w
            && this.h === box.h;
    }

    clone() {
        return new Box(this.x, this.y, this.w, this.h);
    }

    containsPoint(pos, margin = 0) {
        return pos.x >= this.x - margin
            && pos.x < this.x + this.w + margin
            && pos.y >= this.y - margin
            && pos.y < this.y + this.h + margin;
    }

    getLocalPos(pos) {
        return {
            x: pos.x - this.x,
            y: pos.y - this.y
        };
    }

    isNull() {
        return this.w === 0 && this.h === 0;
    }
    
    add(box) {
        return {
            x: this.x + (box.x || 0),
            y: this.y + (box.y || 0),
            w: this.w + (box.w || 0),
            h: this.h + (box.h || 0)
        };
    }

    padToMatchAspectRatio(aspectRatio) {
        const widthFromHeight = Math.round(this.h * aspectRatio);
        const heightFromWidth = Math.round(this.w / aspectRatio);
        let widthToPad = 0;
        let heightToPad = 0;

        if (this.w < widthFromHeight) {
            widthToPad = widthFromHeight - this.w;
        } else {
            heightToPad = heightFromWidth - this.h;
        }

        return this.pad(widthToPad, heightToPad);
    }

    stripToMatchAspectRatio(aspectRatio) {
        const widthFromHeight = Math.ceil(this.h * aspectRatio);
        const heightFromWidth = Math.ceil(this.w / aspectRatio);
        let widthToStrip = 0;
        let heightToStrip = 0;

        if (this.w > widthFromHeight) {
            widthToStrip = this.w - widthFromHeight;
        } else {
            heightToStrip = this.h - heightFromWidth;
        }

        return this.strip(widthToStrip, heightToStrip);
    }

    isTooHorizontalForAspectRatio(aspectRatio) {
        const widthFromHeight = Math.round(this.h * aspectRatio);

        return this.w > widthFromHeight;
    }

    isTooVerticalForAspectRatio(aspectRatio) {
        return !this.isTooHorizontalForAspectRatio(aspectRatio);
    }

    strip(width, height) {
        return this.pad(-width, -height);
    }

    toSize(width, height) {
        return new Box(
            this.x - Math.round((width - this.w) / 2),
            this.y - Math.round((height - this.h) / 2),
            width,
            height
        );
    }

    pad(width, height) {
        return new Box(
            this.x - Math.floor(width / 2),
            this.y - Math.floor(height / 2),
            this.w + width,
            this.h + height
        );
    }

    stripRatio(percent) {
        const marginToStrip = Math.round(Math.min(this.w, this.h) * percent) * 2;

        return this.strip(marginToStrip, marginToStrip);
    }

    getAdaptativeSize(value) {
        if (typeof value === 'string' && value.endsWith('%')) {
            return Math.round(Math.min(this.w, this.h) * parseFloat(value) / 100);
        } else {
            return +value;
        }
    }

    scale(ratio) {
        const widthToStrip = Math.round(this.w * (1 - ratio));
        const heightToStrip = Math.round(this.h * (1 - ratio));

        return this.strip(widthToStrip, heightToStrip);
    }

    scaleAndMatchAspectRatio(scale, aspectRatio = 1) {
        const shouldDecreaseWidth = this.isTooHorizontalForAspectRatio(aspectRatio);
        let w = this.w * scale;
        let h = this.h * scale;

        if (shouldDecreaseWidth) {
            w = h * aspectRatio;
        } else {
            h = w / aspectRatio;
        }

        return this.toSize(w, h);
    }
}
globalThis.ALL_FUNCTIONS.push(Box);