import { Terrain } from './terrain';

export class Mountain extends Terrain {
    constructor(parameters) {
        super(parameters);
    }

    static name = 'Mountain'
    static description = 'Blocks unit movement and line of sight.';

    allowsLineOfSight() {
        return false;
    }

    allowsUnitMovement() {
        return false;
    }
}
globalThis.ALL_FUNCTIONS.push(Mountain);