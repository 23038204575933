import { sum } from '../../utils/array';
import { Box } from '../../utils/box';
import { Widget } from './widget';

class WidgetLineOrColumn extends Widget {
    constructor(isHorizontal, parameters) {
        super(parameters);
        this._isHorizontal = isHorizontal;
    }

    static DEFAULT_ATTRIBUTES = {
        backgroundColor: null,
        borderColor: null,
        borderRadius: 0,
        borderWidth: 1
    }

    _render({ canvas, box, cursor }) {
        const isHorizontal = this._isHorizontal;
        let { backgroundColor, borderColor, borderRadius, borderWidth } = this.attributes;

        if (backgroundColor || borderColor) {
            borderRadius = box.getAdaptativeSize(borderRadius);
            borderWidth = box.getAdaptativeSize(borderWidth);

            canvas.rectangle({
                ...box,
                fillColor: backgroundColor,
                borderRadius,
                borderWidth,
                borderColor
            });
        }

        const totalForce = sum(this.children.map(w => w.attributes.force || 1));
        const flexSpace = Math.round((isHorizontal ? box.w : box.h));
        let remainingSpace = isHorizontal ? box.w : box.h;
        let borrowedFromNext = 0;

        let x = box.x;
        let y = box.y;

        for (let i = 0; i < this.children.length; ++i) {
            const child = this.children[i];
            const force = child.attributes.force || 1;
            const itemBox = new Box(x, y, box.w, box.h);
            let variableDimension = force / totalForce * flexSpace;

            variableDimension -= borrowedFromNext;
            const roundedValue = Math.ceil(variableDimension); 
            borrowedFromNext = roundedValue - variableDimension;
            variableDimension = roundedValue;
            variableDimension = Math.min(variableDimension, remainingSpace);
            remainingSpace -= variableDimension;

            if (isHorizontal) {
                itemBox.w = variableDimension;
                x += variableDimension;
            } else {
                itemBox.h = variableDimension;
                y += variableDimension;
            }

            child.render({ canvas, box: itemBox, cursor })
        }
    }
}

export class WidgetLine extends WidgetLineOrColumn {
    constructor(parameters) {
        super(true, parameters);
    }
}

export class WidgetColumn extends WidgetLineOrColumn {
    constructor(parameters) {
        super(false, parameters);
    }
}
globalThis.ALL_FUNCTIONS.push(WidgetLineOrColumn);
globalThis.ALL_FUNCTIONS.push(WidgetLine);
globalThis.ALL_FUNCTIONS.push(WidgetColumn);