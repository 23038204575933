export default {
    "width": 19,
    "height": 15,
    "shape": "vertical-hexagon",
    "terrains": [
        [1, 1, "Shrine", 0],
        [4, 1, "Water", 0],
        [17, 1, "Headquarters", 2],
        [4, 2, "Water", 0],
        [5, 2, "Water", 0],
        [0, 5, "Water", 0],
        [1, 5, "Water", 0],
        [8, 5, "Water", 0],
        [9, 5, "Water", 0],
        [0, 6, "Water", 0],
        [1, 6, "Water", 0],
        [7, 6, "Water", 0],
        [9, 7, "Laboratory", 0],
        [10, 8, "Water", 0],
        [16, 8, "Water", 0],
        [17, 8, "Water", 0],
        [9, 9, "Water", 0],
        [10, 9, "Water", 0],
        [17, 9, "Water", 0],
        [18, 9, "Water", 0],
        [12, 12, "Water", 0],
        [13, 12, "Water", 0],
        [1, 13, "Headquarters", 1],
        [14, 13, "Water", 0],
        [17, 13, "Shrine", 0]
    ],
    "units": [
        [11, 1, 1, 2],
        [17, 1, 3, 2],
        [15, 6, 1, 2],
        [2, 8, 1, 1],
        [1, 13, 3, 1],
        [7, 13, 1, 1]
    ]
};