import { CONSTANTS } from '../constants';
import { Unit } from '../unit';
import { Plain } from './plain';
import { Terrain } from './terrain';

Object.assign(CONSTANTS, {
    FACTORY_UNIT_STRENGTH: 1
});

export class Factory extends Terrain {
    constructor(parameters) {
        super(parameters);
    }

    static name = 'Factory'
    static description = `Produces a $FACTORY_UNIT_STRENGTH-strength unit at the beginning of the owner's turn. Destroyed when captured by the opponent.`;

    static postEventCallbacks = {
        endTurn({ self, game }) {
            if (game.activePlayer === self.owner) {
                game.spawnUnit({
                    source: self,
                    unit: new Unit({ strength: game.constants.FACTORY_UNIT_STRENGTH }),
                    location: self.location,
                    owner: self.owner
                });
            }
        },
        moveUnit({ self, game }, { unit, location }) {
            if (location.terrain === self && unit.strength > 0 && unit.owner !== self.owner) {
                game.setTerrain({
                    source: self,
                    location,
                    terrain: new Plain()
                });
            }
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Factory);