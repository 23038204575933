const DEFAULT_BORDER_WIDTH = '4%';
const DEFAULT_BORDER_RADIUS = '15%';
const DISABLED_STYLE = {
    disabled_overlayColor: 'black',
    disabled_overlayAlpha: 0.2
};
const HOVERED_STYLE = {
    hovered_overlayColor: 'white',
    hovered_overlayAlpha: 0.3
};

export const BUTTON_STYLE = {
    backgroundColor: 'orange',
    borderColor: 'black',
    borderRadius: DEFAULT_BORDER_RADIUS,
    borderWidth: DEFAULT_BORDER_WIDTH,
    textHorizontalAlign: 'center',
    textSize: '65%',
    ...HOVERED_STYLE,
    ...DISABLED_STYLE
};

export const SMALL_BUTTON_STYLE = {
    backgroundColor: 'black',
    borderRadius: DEFAULT_BORDER_RADIUS,
    borderWidth: DEFAULT_BORDER_WIDTH,
    textHorizontalAlign: 'center',
    textColor: 'white',
    textSize: '70%',
    ...HOVERED_STYLE,
    ...DISABLED_STYLE
};

export const TEXTBOX_STYLE = {
    base_backgroundColor: 'white',
    base_borderColor: 'black',
    base_borderRadius: DEFAULT_BORDER_RADIUS,
    base_borderWidth: DEFAULT_BORDER_WIDTH,
    base_textHorizontalAlign: 'left',
    base_textSize: '50%',
    base_textMargin: 10,
    
    focused_borderColor: 'blue',

    disabled_overlayColor: 'black',
    disabled_overlayAlpha: 0.35
};

export const LABEL_STYLE = {
    textSize: '95%'
};