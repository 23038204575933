import { CONSTANTS } from '../constants';
import { getCellsWithAlliedUnits, isAlliedUnitLocation, getAllCells, getAllCellsInRadius, getDistance, isPlainLocation, getAdjacentCells, getDirection, getAdjacentCellsStartingFromDirection, isUnitCell, getAlliedUnitCells } from '../utils';
import { Character } from './character';

Object.assign(CONSTANTS, {
    EWYS_SUN_RANGE: 5,
    EWYS_RAIN_RADIUS: 1
});

export class Ewys extends Character {
    constructor(options) {
        super(options);
    }

    static name = 'Ewys'
    static title = 'Ewys'
    static abilities = [
        {
            description: `Teleport an allied unit on a plain at exactly $EWYS_SUN_RANGE range.`,
            target1({ source }) {
                return getCellsWithAlliedUnits(source);
            },
            target2({ target1 }, C) {
                return target1.grid.spread({
                    start: target1,
                    spread: () => true,
                    add: cell => isPlainLocation(cell) && getDistance(target1, cell) === C.EWYS_SUN_RANGE,
                    distance: C.EWYS_SUN_RANGE
                });
            },
            trigger({ game, source, target1, target2 }) {
                game.moveUnit({ source, unit: target1.entity, location: target2 });
            }
        }, {
            description: `Sacrifice an allied unit to kill all units in a 5-cells cone in front of it.`,
            target1({ source }) {
                return getCellsWithAlliedUnits(source);
            },
            target2({ target1 }) {
                return getAdjacentCells(target1);
            },
            target2Highlight({ target1, target2 }) {
                const direction = getDirection(target1, target2);
                const cells1 = getAdjacentCellsStartingFromDirection(target1, direction, [0, 1]);
                const cells2 = getAdjacentCellsStartingFromDirection(cells1[0], direction, [0]);
                const cells3 = getAdjacentCellsStartingFromDirection(cells1[1], direction, [0, 1]);

                return [...cells1, ...cells2, ...cells3];
            },
            target2Hover({ source }) {
                return this.target2Highlight(...arguments).filter(cell => isUnitCell(cell, source));
            },
            trigger({ game, target1, source }) {
                const units = this.target2Hover(...arguments).map(cell => cell.entity);

                for (const unit of units) {
                    game.killUnit({ source, unit });
                }

                game.killUnit({ source, unit: target1.entity });
            }
        }, {
            description: `Move all allied units within a $EWYS_RAIN_RADIUS-cell radius to another allied unit's location.`,
            target1({ source }) {
                return getAllCells(source);
            },
            target1Highlight({ target1 }, C) {
                return getAllCellsInRadius(target1, C.EWYS_RAIN_RADIUS);
            },
            target1Hover({ source }) {
                return this.target1Highlight(...arguments).filter(cell => isAlliedUnitLocation(cell, source));
            },
            target2({ source }) {
                return getAlliedUnitCells(source);
            },
            trigger({ game, source, target2 }) {
                for (const location of this.target1Hover(...arguments)) {
                    if (location !== target2) {
                        game.moveUnit({ source, unit: location.entity, location: target2 });
                    }
                }
            }
        }
    ]
}
globalThis.ALL_FUNCTIONS.push(Ewys);