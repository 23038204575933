import { Connection } from '../common/connection';

export class ConnectionToServer extends Connection {
    constructor({ webSocketServerUrl, onConnect, onDisconnect }) {
        super({
            webSocket: new WebSocket(webSocketServerUrl),
            expectAnswer: true,
            onConnect,
            onDisconnect
        });
    }
}
globalThis.ALL_FUNCTIONS.push(ConnectionToServer);