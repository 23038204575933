export class Scheduler {
    constructor() {
        this._tasks = new Set();
    }

    schedule(parameters) {
        if (!typeof parameters === 'function') {
            parameters = { callback: parameters };
        }

        for (const task of this._tasks) {
            if (task.isFinished()) {
                this._tasks.delete(task);
            }
        }

        const task = new Task(parameters);
        task.fire();
        this._tasks.add(task);
    }

    reset() {
        for (const task of this._tasks) {
            task.destroy();
        }

        this._tasks.clear();
    }

    destroy() {
        this.reset();
    }
}

class Task {
    constructor({ callback, delay = 0, repeat = false }) {
        this.callback = callback;
        this.delay = delay;
        this.repeat = repeat;
        this.timeoutId = 0;
        this.finished = false;
    }

    _trigger() {
        if (!this.repeat) {
            this.callback();
            this.finished = true;
        } else {
            const now = Date.now();
            this.callback();
            const elapsed = Date.now() - now;
            const remaining = Math.max(0, this.delay - elapsed);

            this.timeoutId = setTimeout(() => this._trigger(), remaining);
        }
    }

    isFinished() {
        return this.finished;
    }

    destroy() {
        clearTimeout(this.timeoutId);
    }

    fire() {
        this.timeoutId = setTimeout(() => this._trigger(), this.delay);
    }
}
globalThis.ALL_FUNCTIONS.push(Scheduler);
globalThis.ALL_FUNCTIONS.push(Task);