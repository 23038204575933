export function G() {
    const items = [];

    for (const arg of arguments) {
        items.push(arg);
    }

    return new Proxy({}, {
        get(target, property) {
            return function() {
                for (const item of items) {
                    item[property].apply(item, arguments);
                }
            };
        }
    })
}