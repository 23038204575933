export class Screen {
    constructor(client) {
        this._client = client;
    }

    get _ui() {
        return this._client.ui;
    }

    get _server() {
        return this._client.server;
    }

    get _state() {
        return this._client.state;
    }

    log(message) {
        this._client.log(message);
    }

    destroy() {
        
    }
}
globalThis.ALL_FUNCTIONS.push(Screen);