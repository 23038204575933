import { Blobby } from './blobby';
import { Odrec } from './odrec';
import { Drows } from './drows';
import { Isedrix } from './isedrix';
import { Tazad } from './tazad';
import { Ulrich } from './ulrich';
import { Zafiel } from './zafiel';
import { Ewys } from './ewys';
import { EntityClassList } from '../entity-class-list';

export const CHARACTERS = new EntityClassList([
    Ulrich, Ewys, Blobby, Tazad, Zafiel, Isedrix, Odrec, Drows
]);