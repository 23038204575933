import { CONSTANTS } from '../constants';
import { Terrain } from './terrain';

Object.assign(CONSTANTS, {
    CURSED_TERRAIN_STRENGTH: 2
});

export class Cursed extends Terrain {
    constructor(parameters) {
        super(parameters);
    }

    static name = 'Cursed'
    static description = 'Units on a cursed terrain have their strength set to $CURSED_TERRAIN_STRENGTH.';

    static preEventCallbacks = {
        moveUnit({ self, game }, { unit, location }) {
            if (location.terrain === self) {
                game.setUnitStrength({ source: self, unit, value: game.constants.CURSED_TERRAIN_STRENGTH });
            }
        }
    }

    static postEventCallbacks = {
        moveUnit({ self, game }, { location }) {
            if (location.terrain === self && location.entity) {
                game.setUnitStrength({ source: self, unit: location.entity, value: game.constants.CURSED_TERRAIN_STRENGTH });
            }
        },
        setTerrain({ self, game }, { location, terrain }) {
            if (terrain === self && location.entity) {
                game.setUnitStrength({ source: self, unit: location.entity, value: game.constants.CURSED_TERRAIN_STRENGTH });
            }
        },
    }
}
globalThis.ALL_FUNCTIONS.push(Cursed);