import { editText } from '../../../engine/client/widgets/utils';
import { USERNAME_REGEXP } from '../../../engine/common/api-checks';
import { BUTTON_STYLE, TEXTBOX_STYLE } from '../styles';
import { Screen } from './screen';

export class WelcomeScreen extends Screen {
    constructor(client) {
        super(client);
    }

    _authenticate() {
        const username = this._getUsernameFromUi();

        if (username.length > 0) {
            this._client.authenticate({ username });
        }
    }

    _getUsernameFromUi() {
        return this._ui.getWidget('username').attributes.text || '';
    }

    _updateAuthenticateButtonState() {
        const username = this._getUsernameFromUi();
        const disabled = !USERNAME_REGEXP.test(username);

        this._ui.setWidgetAttributes({
            authenticate: { disabled }
        });
    }

    _setUi() {
        const { BACKGROUND_COLOR } = this._client.graphics;

        this._ui.setRootWidget([
            `column ${BACKGROUND_COLOR}`, [
                0.5,
                'line *4', [
                    1,
                    '#game-name ]5 *1',
                    1
                ],
                'column *16', [
                    1,
                    'line *3', [
                        1,
                        'column', [
                            '#username *20 %4',
                            4,
                            '#authenticate *20 %4',
                        ],
                        1
                    ],
                    1
                ],
                3
            ]
        ]);
    }

    show() {
        const { GAME_NAME, GAME_NAME_COLOR } = this._client.graphics;
        const storedUsername = this._client.loadFromLocalStorage('username');

        this._setUi();

        this._ui.setWidgetAttributes({
            gameName: {
                text: GAME_NAME,
                textColor: GAME_NAME_COLOR
            },
            username: {
                text: storedUsername,
                cursorIndex: storedUsername.length,
                textPlaceholder: 'Username',
                allowTextEditing: true,
                ...TEXTBOX_STYLE,
                onKeyDown(widget, evt) {
                    editText(widget, evt);
                    this._updateAuthenticateButtonState();
                },
                onEnter() {
                    this._authenticate();
                }
            },
            password: {
                disabled: true,
                textPlaceholder: 'Password',
                allowTextEditing: true,
                isTextPassword: true,
                ...TEXTBOX_STYLE,
                onKeyDown(widget, evt) {
                    editText(widget, evt);
                    this._updateAuthenticateButtonState();
                },
                onEnter() {
                    this._authenticate();
                }
            },
            authenticate: {
                text: 'Log in',
                ...BUTTON_STYLE,
                onClick() {
                    this._authenticate();
                }
            }
        }, this);
        this._ui.setFocusChain(['username', 'password']);
        this._ui.focusNext();
        this._updateAuthenticateButtonState();
        this._authenticate();
    }
}
globalThis.ALL_FUNCTIONS.push(WelcomeScreen);