function defaultCreateCanvas(width, height) {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    return canvas;
}

function defaultLoadImage(url) {
    return new Promise(resolve => {
        const image = new Image();
        image.src = url;
        image.onload = () => {
            resolve(image);
        }
    })
}

const CONFIG = {
    createCanvas: defaultCreateCanvas,
    loadImage: defaultLoadImage
};

export class DOM {
    static configure({ createCanvas, loadImage }) {
        if (createCanvas) {
            CONFIG.createCanvas = createCanvas;
        }

        if (loadImage) {
            CONFIG.loadImage = loadImage;
        }
    }

    static createCanvas(width, height) {
        return CONFIG.createCanvas(width, height);
    }

    static loadImage(url) {
        return CONFIG.loadImage(url);
    }
}
globalThis.ALL_FUNCTIONS.push(DOM);