import { EventTarget } from '../utils/event-target';

const BUTTON_TO_STRING = ['left', 'middle', 'right'];

export class WindowWrapper extends EventTarget {
    constructor() {
        super();

        this.registerEvents(['resize', 'keyDown', 'keyUp', 'mouseInput']);

        this._initialized = false;
        this._width = window.innerWidth;
        this._height = window.innerHeight;
        this._keys = {};
        this._x = 0;
        this._y = 0;
        this._canvas = null;

        this._ensureInit();
    }

    get width() {
        return this._width;
    }

    get height() {
        return this._height;
    }

    _ensureInit() {
        if (!this._initialized) {
            window.addEventListener('resize', () => this._onResize());
            document.addEventListener('keydown', evt => this._onKeyDown(evt));
            document.addEventListener('keyup', evt => this._onKeyUp(evt));
            document.addEventListener('visibilitychange', () => this._resetKeys());
            document.addEventListener('mousemove', evt => this._onMouseMove(evt));
            document.addEventListener('mouseleave', evt => this._onMouseMove(evt));
            document.addEventListener('mousedown', evt => this._onMouseDown(evt));
            document.addEventListener('mouseup', evt => this._onMouseUp(evt));
            document.addEventListener('contextmenu', evt => evt.preventDefault());

            this._initialized = true;
        }
    }

    _emit(eventName, payload) {
        this.triggerEvent(eventName, payload);
    }

    _triggerMouseEvent(payload) {
        this._x = payload.x;
        this._y = payload.y;

        this._emit('mouseInput', payload);
    }

    _onMouseMove(evt) {
        this._triggerMouseEvent({
            action: 'move',
            x: evt.clientX,
            y: evt.clientY,
            button: null,
            domEvent: evt
        });
    }

    _onMouseDown(evt) {
        this._triggerMouseEvent({
            action: 'down',
            x: evt.clientX,
            y: evt.clientY,
            button: BUTTON_TO_STRING[evt.button],
            domEvent: evt
        });
    }

    _onMouseUp(evt) {
        this._triggerMouseEvent({
            action: 'up',
            x: evt.clientX,
            y: evt.clientY,
            button: BUTTON_TO_STRING[evt.button],
            domEvent: evt
        });
    }

    _onKeyDown(evt) {
        this._keys[evt.code] = true;

        this.triggerEvent('keyDown', evt);
    }

    _onKeyUp(evt) {
        this._keys[evt.code] = false;

        this.triggerEvent('keyUp', evt);
    }

    _resetKeys() {
        for (const [code, pressed] of Object.entries(this._keys)) {
            if (pressed) {
                const evt = {
                    code,
                    key: '',
                    repeat: false,
                    altKey: false,
                    ctrlKey: false,
                    metaKey: false,
                    shiftKey: false,
                    domEvent: null
                };

                this._keys[code] = false;

                this.triggerEvent('keyUp', evt);
            }
        }
    }

    _onResize() {
        this._width = window.innerWidth;
        this._height = window.innerHeight;

        this._resizeCanvas();

        this.triggerEvent('resize', { width: this._width, height: this._height });
    }

    _resizeCanvas() {
        if (this._canvas) {
            this._canvas.width = this._width;
            this._canvas.height = this._height;
        }
    }

    makeIntoCanvas() {
        if (!this._canvas) {
            this._canvas = document.createElement('canvas');

            setTimeout(() => {
                document.body.style.margin = 0;
                document.body.appendChild(this._canvas);
                this._onResize();
            });
        }

        return this._canvas;
    }

    isKeyPressed(code) {
        return this._keys[code];
    }
}
globalThis.ALL_FUNCTIONS.push(WindowWrapper);