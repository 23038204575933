import { CONSTANTS } from '../constants';
import { getAllCells, getAlliedUnitCells, getCellsWithinMovementRange, getCellsWithinStraightMovementRange, getDirection, isValidMoveDestination, getAllCellsInRadius, isAlliedUnitLocation, isPlainLocation, getCellInDirection, getPath } from '../utils';
import { Character } from './character';

Object.assign(CONSTANTS, {
    ULRICH_SUN_RANGE: 3,
    ULRICH_WIND_RANGE: 5,
    ULRICH_RAIN_RANGE: 2
});

export class Ulrich extends Character {
    constructor(options) {
        super(options);
    }

    static name = 'Ulrich'
    static title = 'Ulrich'
    static abilities = [
        {
            description: `Move an allied unit by 1-$ULRICH_SUN_RANGE cells.`,
            target1({ source }) {
                return getAlliedUnitCells(source);
            },
            target2({ target1 }, { ULRICH_SUN_RANGE }) {
                return getCellsWithinMovementRange(target1, ULRICH_SUN_RANGE);
            },
            trigger({ game, source, target1, target2 }) {
                game.moveUnit({ source, unit: target1.entity, location: target2 });
            }
        }, {
            description: `An allied unit rushes over $ULRICH_WIND_RANGE cells (move in a straight line, merge with allied units and fight opponent units on the way).`,
            target1({ source }) {
                return getAlliedUnitCells(source);
            },
            target2({ target1 }, { ULRICH_WIND_RANGE }) {
                return getCellsWithinStraightMovementRange(target1, ULRICH_WIND_RANGE);
            },
            target2Highlight({ target1, target2 }, C) {
                const direction = getDirection(target1, target2);

                return target1.grid.spread({
                    start: target1,
                    spread: cell => getDirection(target1, cell) === direction && isValidMoveDestination(cell),
                    distance: C.ULRICH_WIND_RANGE
                });
            },
            target2Hover() {
                return null;
            },
            trigger({ game, source, target1, target2 }, C) {
                const direction = getDirection(target1, target2);
                const owner = target1.entity.owner;
                let start = target1;
            
                for (let i = 0; i < C.ULRICH_WIND_RANGE; ++i) {
                    const location = getCellInDirection(start, direction);
                    
                    if (!start.entity || start.entity.strength <= 0 || start.entity.owner !== owner || !isValidMoveDestination(location)) {
                        break;
                    }

                    game.moveUnit({ source, unit: start.entity, location });
                    start = location;
                }
            }
        }, {
            description: `Move all allied units within $ULRICH_RAIN_RANGE cells of the selected cell on the selected cell.`,
            target1({ source }) {
                return getAllCells(source).filter(location => isValidMoveDestination(location));
            },
            target1Highlight({ source, target1 }, C) {
                return getAllCellsInRadius(target1, C.ULRICH_RAIN_RANGE).filter(location => isPlainLocation(location) || isAlliedUnitLocation(location, source));
            },
            target1Hover({ target1, source }, C) {
                return this.target1Highlight(...arguments)
                    .filter(location => {
                        const allied = isAlliedUnitLocation(location, source);
                        const path = getPath(location, target1, source);

                        return allied && path && path.length - 1 <= C.ULRICH_RAIN_RANGE;
                    })
                    .concat([target1]);
            },
            trigger({ game, source, target1 }) {
                const locations = this.target1Hover(...arguments);

                for (const location of locations) {
                    if (location !== target1) {
                        game.moveUnit({ source, location: target1, unit: location.entity });
                    }
                }
            }
        }
    ]
}
globalThis.ALL_FUNCTIONS.push(Ulrich);