export function capitalize(string) {
    if (!string) {
        return string;
    }

    return string[0].toUpperCase() + string.substring(1);
}

export function uncapitalize(string) {
    if (!string) {
        return string;
    }

    return string[0].toLowerCase() + string.substring(1);
}