import { Grid } from '../../engine/utils/grid';
import { Location } from '../../engine/utils/location';
import { CHARACTERS } from './characters';
import { TERRAINS } from './terrains';
import { Game } from './game';
import { ITEMS } from './items';
import { Player } from './player';
import { SpatialIndex } from './spatial-index';
import { Unit } from './unit';

export const FUNCTIONS = {
    Game,
    SpatialIndex,
    Grid,
    Location,
    Player,
    Unit,
    ...CHARACTERS.nameToClassMapping(),
    ...ITEMS.nameToClassMapping(),
    ...TERRAINS.nameToClassMapping()
};