export function getCurrentTime() {
    return Date.now() / 1000;
}

export function formatDuration(totalSeconds) {
    if (!isFinite(totalSeconds)) {
        return '∞';
    }

    totalSeconds = Math.ceil(totalSeconds);

    const seconds = totalSeconds % 60;
    const minutes = Math.floor(totalSeconds / 60);

    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
}