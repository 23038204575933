export function checkValueAgainstSchema(value, schema) {
    const result = valueMatchesSchema(value, schema);

    return { error: result };
}

function valueMatchesSchema(value, schema) {
    let error = null;

    if (schema === undefined) {
        error = null;
    } else if (schema === null) {
        if (value !== null) {
            error = `value is not null`;
        }
    } else if (schema instanceof RegExp) {
        if (!schema.test(value)) {
            error = `value does not match regular expression ${schema.toString()}`;
        }
    } else if (Array.isArray(schema)) {
        const type = schema[0];

        if (type === 'or') {
            let errors = [];

            for (let i = 1; i < schema.length; ++i) {
                const itemError = valueMatchesSchema(value, schema[i]);

                if (itemError) {
                    errors.push(itemError);
                } else {
                    errors = null;
                    break;
                }
            }

            if (errors) {
                error = errors.join(' AND ');
            }
        } else {
            const [_, itemSchema, length] = schema;

            if (!Array.isArray(value)) {
                error = `value is not an array`;
            } else if (length && value.length !== length) {
                error = `array length is ${value.length}, expected ${length}`;
            }

            for (let i = 0; !error && i < length; ++i) {
                const item = value[i];
                const itemError = valueMatchesSchema(item, itemSchema);

                if (itemError) {
                    error = `index ${i}: ${itemError}`;
                }
            }

            if (!error && type === 'set' && new Set(value).size !== length) {
                error = `array contains duplicates`;
            }
        }
    } else if (schema === 'object') {
        if (!value || value.constructor !== Object) {
            error = `value ${value} is not an object`;
        }
    } else if (schema === 'boolean') {
        if (typeof value !== 'boolean') {
            error = `value ${value} is not a boolean`;
        }
    } else if (schema === 'string') {
        if (typeof value !== 'string') {
            error = `value ${value} is not a string`;
        }
    } else if (schema && schema.constructor === Object) {
        if (!value || value.constructor !== Object) {
            error = `value ${value} is not an object`;
        } else {
            const schemaEntries = Object.entries(schema);
            const valueKeys = Object.keys(value);

            for (let i = 0; !error && i < schemaEntries.length; ++i) {
                const [key, subValue] = schemaEntries[i];
                const itemError = valueMatchesSchema(value[key], subValue);

                if (itemError) {
                    error = `property "${key}": ${itemError}`;
                }
            }

            for (let i = 0; !error && i < valueKeys.length; ++i) {
                const key = valueKeys[i];

                if (!(key in schema)) {
                    error = `property "${key}" does not belong to schema`;
                }
            }
        }
    } else if (schema === 'index') {
        if (!isIndex(value)) {
            error = `value ${value} is not a positive integer`;
        }
    } else if (schema === 'id') {
        if (!isId(value)) {
            error = `value ${value} is not a strictly positive integer`;
        }
    } else if (typeof schema === 'function') {
        error = schema(value);
    } else {
        throw new Error(`invalid schema ${schema.toString()}`);
    }

    return error;
}

function isId(value) {
    return typeof value === 'number' && value % 1 === 0 && value > 0;
}

function isIndex(value) {
    return typeof value === 'number' && value % 1 === 0 && value >= 0;
}

export function makeStringSchema(value) {
    return new Function('value', `return value === "${value}" ? null : "value is not '${value}'";`);
}

export function makeEnumSchema(values) {
    return new Function('value', `return (${values.map(value => `value === "${value}"`).join(' || ')}) ? null : 'value ' + value + ' is not one of ${values.map(str => `"${str}"`).join(', ')}';`);
}

export function makeBoundIntegerSchema(min, max) {
    return new Function('value', `return (typeof value === "number" && value % 1 === 0 && value >= ${min} && value <= ${max}) ? null : 'value is not an integer in [${min};${max}]';`);
}

export function makeIndexSchema(max) {
    return new Function('value', `return (typeof value === "number" && value % 1 === 0 && value < ${max}) ? null : 'value is not an index in [0;${max}[';`);
}