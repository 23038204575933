import { toArray } from './array';
import { MAX_UINT_32, MAX_INT_32 } from './constants';
import { isInvalidGridCell } from './geometry';
import { Location } from './location';
import { Point } from './point';

const DIRECTIONS = {
    'square': ['top', 'right', 'bottom', 'left'],
    'vertical-hexagon': ['top-right', 'right', 'bottom-right', 'bottom-left', 'left', 'top-left'],
    'horizontal-hexagon': ['top', 'top-right', 'bottom-right', 'bottom', 'bottom-left', 'top-left']
};

export class Grid {
    constructor({ parent = null, name = null, width = 1, height = 1, shape = 'square' }) {
        this.parent = parent;
        this.name = name;
        this.width = width;
        this.height = height;
        this.cells = new Array(width * height);
        this.shape = shape;

        for (let y = 0; y < height; ++y) {
            for (let x = 0; x < width; ++x) {
                const i = this._getIndex(x, y);
                
                this.cells[i] = new Location({ x, y, index: i, grid: this });
            }
        }
    }

    static name = 'Grid'

    _getIndex(x, y) {
        return y * this.width + x;
    }

    _getCoords(index) {
        return {
            x: index % this.width,
            y: Math.floor(index / this.width)
        };
    }

    _isOutOfBounds(coords) {
        return (coords.x < 0 || coords.x >= this.width || coords.y < 0 || coords.y >= this.height)
            || isInvalidGridCell(this.shape, coords.x, coords.y, this.width, this.height);
    }

    _isHorizontalHexagon() {
        return this.shape === 'horizontal-hexagon';
    }

    _isVerticalHexagon() {
        return this.shape === 'vertical-hexagon';
    }

    push(entity) {
        for (const cell of this.cells) {
            if (!cell.entity) {
                cell.entity = entity;
                return true;
            }
        }

        return false;
    }

    get(coords) {
        if (typeof coords === 'number') {
            return this.cells[coords] || null;
        }

        const isOutOfBounds = (coords.x < 0 || coords.x >= this.width || coords.y < 0 || coords.y >= this.height);
        const isInvalid = isInvalidGridCell(this.shape, coords.x, coords.y, this.width, this.height);

        if (isOutOfBounds || isInvalid) {
            return null;
        }

        const index = this._getIndex(coords.x, coords.y);

        return this.cells[index];
    }

    getEntity(coords) {
        return this.get(coords)?.entity;
    }

    entities() {
        return this.cells.map(cell => cell.entity).filter(entity => entity);
    }

    find(callback) {
        return this.cells.find(callback);
    }

    filter(callback) {
        return this.cells.filter(callback);
    }

    map(callback) {
        return this.cells.map(callback);
    }

    spread({ start, spread = () => true, add = null, distance = Infinity, includeStart = false, diagonals = false }) {
        if (!start) {
            throw new Error('"start" not specified');
        }

        if (typeof distance === 'object') {
            // TODO: have a proper min/max system
            const { min, max } = distance;
            distance = max;
            includeStart = min === 0;
        }

        const cells = new Uint32Array(this.cells.length);
        const accumulator = [];

        cells.fill(MAX_UINT_32);

        const startCells = typeof start === 'function'
            ? this.filter(start)
            : toArray(start);

        for (const cell of startCells) {
            this._spread(cell, spread, add, 0, distance, includeStart, diagonals, cells, accumulator, null, cell);
        }

        return Array.from(new Set(accumulator));
    }

    _spread(coords, spreadPredicate, addPredicate, distanceFromOrigin, maxDistance, includeStart, diagonals, cells, accumulator, from, start) {
        const cell = this.get(coords);

        if (!cell || cells[cell.index] < distanceFromOrigin) {
            return;
        }

        const shouldSpread = spreadPredicate(cell, { start, from, distanceFromOrigin });
        const shouldAdd = (from || includeStart) && (addPredicate ? addPredicate(cell, { start, from, distanceFromOrigin }) : shouldSpread);

        if (shouldAdd) {
            accumulator.push(cell);
        }

        cells[cell.index] = Math.min(MAX_UINT_32, distanceFromOrigin);

        if ((shouldSpread || !from) && distanceFromOrigin < maxDistance) {
            for (const pos of this._getAdjacentCellsCoords(coords, diagonals)) {
                this._spread(pos, spreadPredicate, addPredicate, distanceFromOrigin + 1, maxDistance, includeStart, diagonals, cells, accumulator, cell, start);
            }
        }
    }

    _getSquareAdjacentDirections() {
        return [ [0, -1], [1, 0], [0, 1], [-1, 0] ];
    }

    _getSquareAdjacentDirectionsWithDiagonals() {
        return [ [0, -1], [1, -1], [1, 0], [1, 1], [0, 1], [-1, 1], [-1, 0], [-1, -1] ];
    }

    _getHorizontalHexagonAdjacentDirections(pos) {
        if (pos.x % 2 === 0) {
            return [ [0, -1], [1, 0], [1, 1], [0, 1], [-1, 1], [-1, 0] ];
        } else {
            return [ [0, -1], [1, -1], [1, 0], [0, 1], [-1, 0], [-1, -1] ];
        }
    }

    _getVerticalHexagonAdjacentDirections(pos) {
        if (pos.y % 2 === 0) {
            return [ [1, -1], [1, 0], [1, 1], [0, 1], [-1, 0], [0, -1] ];
        } else {
            return [ [0, -1], [1, 0], [0, 1], [-1, 1], [-1, 0], [-1, -1] ];
        }
    }

    getAdjacentCells(cell) {
        return this._getAdjacentCellsCoords(cell).map(coords => this.get(coords)).filter(location => location);
    }

    _getAdjacentCellsCoords(coords, diagonals = false) {
        let directions = [];

        if (this._isHorizontalHexagon()) {
            directions = this._getHorizontalHexagonAdjacentDirections(coords);
        } else if (this._isVerticalHexagon()) {
            directions = this._getVerticalHexagonAdjacentDirections(coords);
        } else if (diagonals) {
            directions = this._getSquareAdjacentDirectionsWithDiagonals();
        } else {
            directions = this._getSquareAdjacentDirections();
        }

        return directions.map(([x, y]) => ({ x: coords.x + x, y: coords.y + y }));
    }

    areAligned(cell1, cell2) {
        return this.getDirection(cell1, cell2) !== null;
    }

    getAdjacentCellsStartingFromDirection(cell, direction, indexes) {
        const adjacentCoords = this._getAdjacentCellsCoords(cell);
        const directions = this.getDirections();
        const index = directions.indexOf(direction);
        const result = [];

        for (const i of indexes) {
            const directionIndex = (index + i + directions.length) % directions.length;
            result.push(adjacentCoords[directionIndex]);
        }

        return result.map(pos => this.get(pos)).filter(location => location);
    }

    getDirection(cell1, cell2) {
        const dx = cell2.x - cell1.x;
        const dy = cell2.y - cell1.y;

        if (dx === 0 && dy === 0) {
            return null;
            return 'self';
        }

        if (this._isHorizontalHexagon()) {
            const d = Math.abs(dx) - Math.abs(2 * dy);
            const odd = cell1.x % 2;
            const m = ((odd && dy < 0) || (!odd && dy > 0)) ? -1 : 1;
            const verticalAlign = dx === 0;
            const horizontalAlign = d === 0 || d === m;

            if (verticalAlign) {
                return dy > 0 ? 'bottom' : 'top';
            } else if (horizontalAlign) {
                return (odd ? dy >= 0 : dy > 0)
                    ? (dx > 0 ? 'bottom-right' : 'bottom-left')
                    : (dx > 0 ? 'top-right' : 'top-left')
            }
        } else if (this._isVerticalHexagon()) {
            const d = Math.abs(dy) - Math.abs(2 * dx);
            const odd = cell1.y % 2;
            const m = ((odd && dx < 0) || (!odd && dx > 0)) ? -1 : 1;
            const horizontalAlign = dy === 0;
            const verticalAlign = d === 0 || d === m;

            if (horizontalAlign) {
                return dx > 0 ? 'right' : 'left';
            } else if (verticalAlign) {
                return (odd ? dx >= 0 : dx > 0)
                    ? (dy > 0 ? 'bottom-right' : 'top-right')
                    : (dy > 0 ? 'bottom-left' : 'top-left')
            }
        } else {
            if (dx === 0) {
                return dy > 0 ? 'bottom' : 'top';
            } else if (dy === 0) {
                return dx > 0 ? 'right' : 'left';
            }
        }

        return null;
    }

    getDirections() {
        return DIRECTIONS[this.shape];
    }

    getCellInDirection(cell, direction) {
        const adjacentCoords = this._getAdjacentCellsCoords(cell);
        const directions = this.getDirections();
        const index = directions.indexOf(direction);
        
        return this.get(adjacentCoords[index]);
    }

    _getSymmetricalCoords(pos, center) {
        const d = Point.substract(center, pos);
        const s = Point.add(center, d);

        if (d.x === 0 && d.y === 0) {
            return s;
        }

        if (this._isHorizontalHexagon()) {
            const odd = pos.x % 2 === 1;
            const difOdd = Math.abs(d.x) % 2 === 1;

            if (difOdd) {
                s.y += odd ? 1 : -1;
            }
        } else if (this._isVerticalHexagon()) {
            const odd = pos.y % 2 === 1;
            const difOdd = Math.abs(d.y) % 2 === 1;

            if (difOdd) {
                s.x += odd ? 1 : -1;
            }
        }

        return s;
    }

    getNeighbors({ x, y }, diagonals) {
        return this._getAdjacentCellsCoords({ x, y }, diagonals).map(pos => this.get(pos)).filter(value => value);
    }

    getSymmetrical(pos, center) {
        return this.get(this._getSymmetricalCoords(pos, center));
    }

    getDistance(cell1, cell2) {
        const dx = cell2.x - cell1.x;
        const dy = cell2.y - cell1.y;

        if (this._isHorizontalHexagon()) {
            const odd = cell1.x % 2;
            const s = (Math.abs(dx) % 2 === 1 && ((odd && dy < 0) || !odd && dy > 0)) ? -1 : 0;
            const d = Math.abs(dx) + Math.max(0, s + Math.abs(dy) - Math.floor(Math.abs(dx / 2)));

            return d;
        } else if (this._isVerticalHexagon()) {
            const odd = cell1.y % 2;
            const s = (Math.abs(dy) % 2 === 1 && ((odd && dx < 0) || !odd && dx > 0)) ? -1 : 0;
            const d = Math.abs(dy) + Math.max(0, s + Math.abs(dx) - Math.floor(Math.abs(dy / 2)));

            return d;
        } else {
            return Math.abs(dx) + Math.abs(dy);
        }
    }

    _heuristic(cell1, cell2) {
        return this.getDistance(cell1, cell2);
    }

    getPath({ start, target, cost = () => 1 }) {
        const size = this.width * this.height;
        const openSet = [start];
        const cameFrom = new Int32Array(size);
        const gScore = new Float32Array(size);
        const fScore = new Float32Array(size);

        cameFrom.fill(-1);
        gScore.fill(Infinity);
        fScore.fill(Infinity);

        gScore[start.index] = 0;
        fScore[start.index] = this._heuristic(start, target);

        while (openSet.length > 0) {
            let current = openSet.reduce((prev, current) => fScore[current.index] < fScore[prev.index] ? current : prev);

            if (current === target) {
                const path = [current];

                while (cameFrom[current.index] !== -1) {
                    current = this.cells[cameFrom[current.index]];
                    path.push(current);
                }

                return path.reverse();
            }

            openSet.splice(openSet.indexOf(current), 1);

            for (const neighbor of this.getAdjacentCells(current)) {
                const tentativeGScore = gScore[current.index] + cost(neighbor, current);

                if (tentativeGScore < gScore[neighbor.index]) {
                    cameFrom[neighbor.index] = current.index;
                    gScore[neighbor.index] = tentativeGScore;
                    fScore[neighbor.index] = tentativeGScore + this._heuristic(neighbor, target);

                    if (!openSet.includes(neighbor)) {
                        openSet.push(neighbor);
                    }
                }
            }
        }

        return null;
    }

    sortByDirection(locations, direction) {
        // 1 - split locations 
    }
}
globalThis.ALL_FUNCTIONS.push(Grid);