import { isInstanceOf } from '../../engine/utils/objects';
import { Character } from '../data/characters/character';
import { Terrain } from '../data/terrains/terrain';
import { Unit } from '../data/unit';
import { getCharacterImageUrl, getItemImageUrl } from './resources';

export const GRAPHICS = {
    GAME_NAME: 'Sun, Wind & Rain',
    GAME_NAME_COLOR: 'mediumorchid',
    CHARACTER_ASPECT_RATIO: 1,
    BACKGROUND_COLOR: 'rgb(200,200,200)',
    PLAYER_COLORS: ['dodgerblue', 'tomato'],
    EXPERIENCE_COLOR: 'purple',
    SEASONS: [
        ['sun', 'coral'],
        ['wind', 'seagreen'],
        ['rain', 'steelblue'],
    ],
    TERRAIN_IMAGES: {
        Plain: 'lightgreen',
        Water: 'royalblue',
        Shrine: 'gold',
        Headquarters: ['lightblue', '#FFC09A'],
        Factory: ['lightblue', '#FFC09A'],
        Portal: 'greenyellow',
        Cursed: 'plum',
        Mountain: 'brown',
        Laboratory: '#EEEEEE'
    },
    CHARACTER_IMAGES: {
        Blobby: getCharacterImageUrl('blob'),
        Drows: getCharacterImageUrl('drows'),
        Ewys: getCharacterImageUrl('wizard'),
        Isedrix: getCharacterImageUrl('majestic'),
        Odrec: getCharacterImageUrl('deceitful'),
        Tazad: getCharacterImageUrl('skeleton'),
        Ulrich: getCharacterImageUrl('ulrich'),
        Zafiel: getCharacterImageUrl('wise')
    },
    ITEM_IMAGES: {
        Clock: getItemImageUrl('clock'),
        PotionOfStrength: getItemImageUrl('elixir-of-strength'),
        RallyingFlag: getItemImageUrl('rallying-flag')
    }
};

export function getImageForTerrain(terrain, playerNumber = 1, graphics = GRAPHICS) {
    if (!terrain) {
        return null;
    }

    const value = graphics.TERRAIN_IMAGES[terrain.name];

    if (Array.isArray(value)) {
        return value[playerNumber - 1] || value[0];
    } else {
        return value;
    }
}

export function getImageForCharacter(character, playerNumber = 1, graphics = GRAPHICS) {
    if (!character) {
        return null;
    }

    const value = graphics.CHARACTER_IMAGES[character.name];

    if (Array.isArray(value)) {
        return value[playerNumber - 1] || value[0];
    } else {
        return value;
    }
}

export function getImageForUnit(unit, playerNumber = 1, graphics = GRAPHICS) {
    if (!unit) {
        return null;
    }

    const color = graphics.PLAYER_COLORS[playerNumber - 1];

    let str = `circle ${color} 70%`;

    if (unit.strength) {
        str += ` "${unit.strength}"`;
    }

    return str;
}

export function getImageForEntity(entity, playerNumber = 1, graphics = GRAPHICS) {
    if (isInstanceOf(entity, Unit)) {
        return getImageForUnit(entity, playerNumber, graphics);
    } else if (isInstanceOf(entity, Terrain)) {
        return getImageForTerrain(entity, playerNumber, graphics);
    } else if (isInstanceOf(entity, Character)) {
        return getImageForCharacter(entity, playerNumber, graphics);
    }

    return null;
}

export function hasOwner(terrain, graphics = GRAPHICS) {
    return Array.isArray(graphics.TERRAIN_IMAGES[terrain.name]);
}