import { uncapitalize, capitalize } from '../../engine/utils/string';
import { CONSTANTS } from '../data/constants';
import { GRAPHICS } from './graphics';
import { createIcons } from './icons';

let ICONS = null;

function getIcons() {
    if (!ICONS) {
        ICONS = createIcons();
    }

    return ICONS;
}

export class TooltipMaker {
    constructor({ constants = CONSTANTS, graphics = GRAPHICS, pointOfViewId } = {}) {
        this.constants = constants;
        this.graphics = graphics;
        this.pointOfViewId = pointOfViewId;
    }

    rules() {
        const { SEASONS } = this.graphics;
        const OPPONENT_HQ_COLOR = this.graphics.TERRAIN_IMAGES.Headquarters[1];
        const SHRINE_COLOR = this.graphics.TERRAIN_IMAGES.Shrine;
        const seasonsStr = SEASONS.concat([SEASONS[0]]).map(([name, color]) => `@${color} ${name}@`).join(' ➡ ');
        const string = [
            `|#RULES|`, [
                `• Win conditions:`,
                `- place a unit on your opponent's @${OPPONENT_HQ_COLOR} *headquarters*@; _or_`,
                `- place a unit on both @${SHRINE_COLOR} *shrines*@; _or_`,
                `- kill all your opponent's units`,
            ].join('\n'),
            `• You can only use one character ability per turn.`,
            `• You can use any number of items per turn.`,
            `• A character always uses the ability that matches the current season.`,
            `• After both players have played a turn, the season changes (${seasonsStr}).`,
            `• A *chain* (e.g chain of water) is a set of cells that you can traverse without going through a cell of another type.`
        ].join('\n\n');

        return this._replaceConstants(string);
    }

    history(game) {
        const { SEASONS } = this.graphics;
        const itemsToDisplay = game.history.slice(-4).reverse();

        return '|#HISTORY|\n\n' + itemsToDisplay.map(({ message }) => {
            if (!message) {
                return null;
            }

            const selfPlayer = game.players.find(player => player.id === this.pointOfViewId);
            const opponentPlayer = game.players.find(player => player.id !== this.pointOfViewId);

            message = message.replace(/\$SEASON_(\d)/g, (str, index) => {
                const [name, color] = SEASONS[parseInt(index)];

                return `*@${color} ${name}@*`;
            });
            message = message.replace(selfPlayer.username, `*@${this.graphics.PLAYER_COLORS[0]} ${selfPlayer.username}@*`);
            message = message.replace(opponentPlayer.username, `*@${this.graphics.PLAYER_COLORS[1]} ${opponentPlayer.username}@*`);

            return '• ' + message;
        }).filter(str => str).join('\n\n');
    }

    item(item) {
        const string = [
            `|#${item.title}|`,
            `*Effect:* ${uncapitalize(item.description)}`,
            `|##_(Items are consumed after usage)|`
        ].join('\n\n\n');

        return this._replaceConstants(string);
    }

    character(character, currentSeasonIndex, fullDetails = true) {
        if (!character) {
            return null;
        }

        const lines = [
            this._characterNameToString(character)
        ];

        for (let i = 0; i < character.abilities.length; ++i) {
            lines.push(this._abilityToString(character, i, currentSeasonIndex))
        }

        if (fullDetails) {
            lines.push(
                `\n|##_(Using an ability ends your turn)_|`
            );
        }

        const string = lines.join('\n\n');

        return this._replaceConstants(string);
    }

    cell(location, selfPlayer) {
        let terrainStr = '';
        let unitStr = 'none';
        let infoStr = '';

        const unit = location.entity;
        const env = location.terrain;
    
        if (unit) {
            unitStr = `${unit.owner === selfPlayer ? 'allied' : 'opponent'}, strength ${unit.strength}`;
    
            if (unit.remainingTurnCount !== Infinity) {
                unitStr += ` (dies in ${unit.remainingTurnCount} turn${unit.remainingTurnCount > 1 ? 's' : ''})`;
            }
        }

        terrainStr = env.name.toLowerCase();
    
        if (env.owner) {
            terrainStr = `${env.owner === selfPlayer ? 'allied' : 'opponent'} ${terrainStr}`;
        }

        const type = env.name;

        if (type === 'Headquarters' && env.owner === selfPlayer) {
            infoStr = `You lose if your opponent places one of their units on this cell`;
        } else if (type === 'Headquarters' && env.owner !== selfPlayer) {
            infoStr = `You win if you place one of your units on this cell`;
        } else {
            infoStr = (env.description || '').replace(/\.$/, '');
        }
    
        if (infoStr) {
            infoStr = `|_##(${infoStr})##_`;
        }
    
        const str = [
            `|*Unit:* ${unitStr}|`,
            `|*Terrain:* ${terrainStr} (${location.x},${location.y})|`,
            infoStr
        ].join('\n\n');
    
        return this._replaceConstants(`${str}`);
    }

    _replaceConstants(str) {
        return str.replace(/\$[A-Z_]{2,}/g, str => this.constants[str.substring(1)] ?? str);
    }

    _characterNameToString(character) {
        return `|#${character.title}|`;

        let focusStr = '';

        if (character.focus !== undefined) {
            focusStr = ` (${character.focus} focus)`;
            focusStr = this._encloseInFocusColor(focusStr, character);
            focusStr = wrapBold(focusStr, character.enhanced);
        }

        return `|#${character.title}#${focusStr}`;
    }

    _encloseInFocusColor(string, character) {
        return wrapColor(string, this._constants.BASE_FOCUS_COLOR);
    }

    _replaceEnclosed(string, enclosingCharacters, callback) {
        const start = enclosingCharacters[0];
        const end = enclosingCharacters[1] || start;

        const startIndex = string.indexOf(start);
        const endIndex = string.indexOf(end, startIndex);

        if (startIndex === -1 || endIndex === -1) {
            return string;
        }

        const substring = string.substring(startIndex + 1, endIndex);
        const replacement = callback(substring);
        const result = string.substring(0, startIndex) + replacement + string.substring(endIndex + 1);

        return result;
    }

    _formatBoostEffect(string, character, completeDescription) {
        const slashIndex = string.indexOf('/');
        let unboostedEffect = slashIndex === -1 ? '' : string.substring(0, slashIndex);
        let boostedEffect = slashIndex === -1 ? string : string.substring(slashIndex + 1);

        if (completeDescription && !unboostedEffect) {
            boostedEffect = `[${boostedEffect}]`;
        }
        boostedEffect = this._encloseInFocusColor(boostedEffect, character);

        if (completeDescription) {
            if (unboostedEffect) {
                return `[${unboostedEffect}/${boostedEffect}]`;
            } else {
                return boostedEffect;
            }
        } else if (character.focus >= this._constants.ENHANCED_ABILITY_FOCUS_COST) {
            return boostedEffect;
        } else {
            return unboostedEffect;
        }
    }

    _abilityToString(character, abilityIndex, currentSeasonIndex) {
        const ability = character.getAbility(abilityIndex);
        const [seasonName, seasonColor] = this.graphics.SEASONS[abilityIndex];
        const highlight = abilityIndex === currentSeasonIndex;

        let content = ability.description || '';
        content = this._replaceEnclosed(content, '()', str => `_##(${str})##_`);
        // content = this._replaceEnclosed(content, '[]', string => this._formatBoostEffect(string, character, completeDescription));
        // content = this._replaceEnclosed(content, '[]', string => this._formatBoostEffect(string, character, completeDescription));
        content = uncapitalize(content);

        let prefix = `${capitalize(seasonName)}:`;
        prefix = wrapColor(prefix, seasonColor);

        if (highlight) {
            prefix = wrapBold(prefix);
            content = wrapBold(content);
            content = this._replaceEnclosed(content, '()', str => `*(${str})*`);
        }

        return `${prefix} ${content}`;
    }
}


function wrapColor(string, color) {
    return `@${color} ${string}@`;
}

function wrapBold(string, value = true) {
    if (value) {
        return `*${string}*`;
    } else {
        return string;
    }
}
globalThis.ALL_FUNCTIONS.push(TooltipMaker);