import { CONSTANTS } from '../constants';
import { getAlliedUnitCells } from '../utils';
import { Item } from './item';

Object.assign(CONSTANTS, {
    POTION_OF_STRENGTH_BONUS: 1
});

export class PotionOfStrength extends Item {
    constructor(parameters) {
        super(parameters);
    }

    static name = 'PotionOfStrength'
    static title = 'Potion of strength'
    static description = `Give an allied unit +$POTION_OF_STRENGTH_BONUS strength.`
    static ability = {
        target1({ source }) {
            return getAlliedUnitCells(source);
        },
        trigger({ game, source, target1 }, C) {
            game.setUnitStrength({ source, unit: target1.entity, modifier: C.POTION_OF_STRENGTH_BONUS });
        }
    }
}
globalThis.ALL_FUNCTIONS.push(PotionOfStrength);