export const HEXAGON_WIDTH_TO_HEIGHT_RATIO = 0.8660254037844386;
export const HEXAGON_HEIGHT_TO_WIDTH_RATIO = 1 / HEXAGON_WIDTH_TO_HEIGHT_RATIO;
export const HEXAGON_NESTED_WIDTH_RATIO = 0.25;

export function isInvalidGridCell(cellShape, x, y, width, height) {
    if (cellShape === 'vertical-hexagon') {
        return (y % 2 === 0 && x === width - 1) || (height % 2 === 0 && y === height - 1);
    } else if (cellShape === 'hexagon' || cellShape === 'horizontal-hexagon') {
        return (x % 2 === 0 && y === height - 1) || (width % 2 === 0 && x === width - 1);
    }

    return false;
}