import { DOM } from './dom';

const IMAGE_EXTENSIONS = ['.png', '.jpg', '.jpeg'];

function isImageUrl(url) {
    return IMAGE_EXTENSIONS.some(ext => url.endsWith(ext));
}

export class ImageLoader {
    constructor() {
        this._images = {};
        this._loadingCount = 0;
    }

    get(url) {
        let image = this._images[url];
        
        if (image === undefined) {
            image = this._load(url);
        }

        return image;
    }

    isLoading() {
        return this._loadingCount > 0;
    }

    _load(url) {
        let result = null;

        if (isImageUrl(url)) {
            this._loadingCount += 1;
            DOM.loadImage(url).then(image => {
                this._images[url] = image;
                this._loadingCount -= 1;
            });
        }

        this._images[url] = result;

        return result;
    }
}

export const GLOBAL_IMAGE_LOADER = new ImageLoader();
globalThis.ALL_FUNCTIONS.push(ImageLoader);