import { isUnitCell, isValidMoveDestination } from '../utils';
import { Item } from './item';

export class RallyingFlag extends Item {
    constructor(params) {
        super(params);
    }

    static name = 'RallyingFlag'
    static title = 'Rallying flag'
    static description = `Move any unit anywhere.`
    static ability = {
        target1({ game }) {
            return game.battlefield.filter(cell => isUnitCell(cell));
        },
        target1Highlight() {
            return null;
        },
        target2({ game, target1 }) {
            return game.battlefield.filter(location => location !== target1 && isValidMoveDestination(location));
        },
        trigger({ game, source, target1, target2 }) {
            game.moveUnit({ source, unit: target1.entity, location: target2 });
            game.addItem({ source, item: new RallyingFlag(), owner: source.owner });
        }
    }
}
globalThis.ALL_FUNCTIONS.push(RallyingFlag);