import { CONSTANTS } from '../constants';
import { Factory } from '../terrains/factory';
import { Plain } from '../terrains/plain';
import { isEmptyLocation, isPlainLocation, isOpponentUnitCell, getAllPlainsWithinAlliedRange, spread, getAlliedUnitCells, getCellsWithinVisionRange, getAdjacentCells, isValidMoveDestination } from '../utils';
import { Character } from './character';

Object.assign(CONSTANTS, {
    ZAFIEL_SUN_RANGE: 1,
    ZAFIEL_WIND_RANGE: 1,
    ZAFIEL_WIND_POWER: 2,
    ZAFIEL_RAIN_RANGE: 5
});

export class Zafiel extends Character {
    constructor(options) {
        super(options);
    }

    static name = 'Zafiel'
    static title = 'Zafiel'
    static abilities = [
        {
            description: `Create a factory on a plain within $ZAFIEL_SUN_RANGE range of an allied unit (produces a $FACTORY_UNIT_STRENGTH-strength unit at the beginning of your turn, destroyed when captured).`,
            target1({ source }, C) {
                return getAllPlainsWithinAlliedRange(source, { min: 0, max: C.ZAFIEL_SUN_RANGE }).filter(location => !isOpponentUnitCell(location, source));
            },
            trigger({ game, source, target1 }) {
                game.setTerrain({
                    source,
                    terrain: new Factory(),
                    owner: source.owner,
                    location: target1
                });
            }
        }, {
            description: `Move any non-plain terrain within $ZAFIEL_WIND_RANGE range of an allied unit by $ZAFIEL_WIND_POWER cells, on an empty plain.`,
            target1({ source }, C) {
                return getCellsWithinVisionRange(getAlliedUnitCells(source), { min: 0, max: C.ZAFIEL_WIND_RANGE }).filter(location => !isPlainLocation(location));
            },
            target2({ source, target1 }, C) {
                return getCellsWithinVisionRange(target1, C.ZAFIEL_WIND_POWER, source).filter(location => isEmptyLocation(location) && isPlainLocation(location));
            },
            trigger({ game, source, target1, target2 }) {
                const movedTerrain = target1.terrain;

                game.setTerrain({ source, location: target1, terrain: new Plain() });
                game.setTerrain({ source, location: target2, terrain: movedTerrain });
            }
        }, {
            description: `Move an allied unit by 1-$ZAFIEL_RAIN_RANGE cells alongside non-plain terrain.`,
            target1({ source }) {
                return getAlliedUnitCells(source);
            },
            target2({ source, target1 }, C) {
                return spread({
                    start: target1,
                    spread: cell => isOrHasAdjacentNonPlain(cell) && isValidMoveDestination(cell) && !isOpponentUnitCell(cell, source),
                    add: cell => isOrHasAdjacentNonPlain(cell) && isValidMoveDestination(cell),
                    distance: C.ZAFIEL_RAIN_RANGE
                })
            },
            trigger({ game, source, target1, target2 }) {
                game.moveUnit({ source, unit: target1.entity, location: target2 });
            }
        }
    ]
}

function isOrHasAdjacentNonPlain(location) {
    const adjacent = getAdjacentCells(location);

    // TODO: square cells have 4 adjacent cells max
    return adjacent.length < 6 || !isPlainLocation(location) || adjacent.some(loc => !isPlainLocation(loc));
}
globalThis.ALL_FUNCTIONS.push(Zafiel);