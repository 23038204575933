import { Box } from '../../utils/box';
import { addAlphaToColor } from '../../utils/colors';
import { Widget } from './widget';

export class WidgetGeneric extends Widget {
    constructor(parameters) {
        super(parameters);
    }

    static DEFAULT_ATTRIBUTES = {
        shape: 'rectangle',
        background: null,
        backgroundColor: null,
        backgroundOverlayColor: null,
        backgroundOverlayAlpha: 1,
        overlayColor: null,
        overlayAlpha: 1,
        borderRadius: 0,
        borderWidth: 1,
        borderColor: null,
        borderAlpha: 1,
        image: null,
        imageScale: 1,
        text: '',
        textColor: 'black',
        textFont: 'Arial',
        textMargin: 0,
        textSize: '100%',
        textPlaceholder: '',
        textPlaceholderColor: 'grey',
        textHorizontalAlign: 'center',
        textVerticalAlign: 'middle',
        textIsPassword: false,
        allowTextEditing: false,
        cursorIndex: 0,
        topLeftBadge: null,
        topLeftBadgeSize: '50%',
        tooltip: '',
        tooltipWidth: 0,
        tooltipHeight: 0,
        draggedImage: null,
        draggedImageScale: 1,
        formattedText: null
    }

    _render({ canvas, box, cursor }) {
        const attributes = this.attributes;

        const type = attributes.shape;
        const image = attributes.image;
        const imageScale = attributes.imageScale;
        const background = attributes.background;
        const backgroundColor = attributes.backgroundColor;
        const backgroundOverlayColor = addAlphaToColor(attributes.backgroundOverlayColor, attributes.backgroundOverlayAlpha);
        const overlayColor = addAlphaToColor(attributes.overlayColor, attributes.overlayAlpha);
        const borderWidth = box.getAdaptativeSize(attributes.borderWidth);
        const borderRadius = box.getAdaptativeSize(attributes.borderRadius);
        const borderColor = addAlphaToColor(attributes.borderColor, attributes.borderAlpha);

        if (backgroundColor) {
            canvas.draw({ ...box, type, borderRadius, fillColor: backgroundColor });
        }

        if (background) {
            this._renderImage(canvas, box, background, 1, borderRadius, type);
        }

        if (backgroundOverlayColor) {
            canvas.draw({ ...box, type, borderRadius, fillColor: backgroundOverlayColor });
        }

        if (image) {
            this._renderImage(canvas, box, image, imageScale, borderRadius, type);
        }

        this._renderText(canvas, box), borderRadius;

        if (borderColor && borderWidth) {
            canvas.draw({ ...box, type, borderColor, borderWidth, borderRadius });
        }

        if (attributes.topLeftBadge) {
            const badgeSize = this.box.getAdaptativeSize(attributes.topLeftBadgeSize);
            const badgeBox = new Box(box.x, box.y, badgeSize, badgeSize);

            this._renderImage(canvas, badgeBox, attributes.topLeftBadge, 1, 0, 'square');
        }

        if (attributes.formattedText) {
            const baseTextSize = box.getAdaptativeSize('5.5%');

            canvas.drawFormattedText({
                x: box.x,
                y: box.y,
                fixedWidth: box.w,
                fixedHeight: box.h,
                text: attributes.formattedText,
                textSize: baseTextSize,
                backgroundMargin: 5,
                verticalAlign: 'top'
            });
        }

        if (overlayColor) {
            canvas.draw({ ...box, type, fillColor: overlayColor, borderRadius, borderWidth });
        }

        if (this._shouldShowPointer(cursor)) {
            cursor.style.skin = this._getPointerToDisplay(cursor);
        }

        if (this._shouldShowTooltip(cursor)) {
            cursor.style.tooltip = attributes.tooltip;
            cursor.style.tooltipWidth = attributes.tooltipWidth;
            cursor.style.tooltipHeight = attributes.tooltipHeight;
        }

        if (this._shouldShowDraggedImage(cursor)) {
            cursor.style.image = attributes.draggedImage;
            cursor.style.imageWidth = Math.round(box.w * attributes.draggedImageScale);
            cursor.style.imageHeight = Math.round(box.h * attributes.draggedImageScale);
        }
    }

    _getPointerToDisplay() {
        if (this.attributes.allowTextEditing) {
            return 'text';
        } else {
            return 'pointer';
        }
    }

    _renderImage(canvas, box, image, scale, borderRadius, defaultShape) {
        let finalBox = box;
        if (!image) {
            return;
        }

        if (isImageUrl(image)) {
            finalBox = finalBox.scale(scale);
            canvas.image({ ...finalBox, image, borderRadius });        
        } else {
            let type = defaultShape;
            let fillColor = 'black';
            let otherScale = 1;
            let text = null;

            for (const str of image.split(' ').filter(str => str)) {
                if (str.endsWith('%')) {
                    otherScale = parseFloat(str) / 100;
                } else if (['circle', 'rectangle', 'square'].includes(str)) {
                    type = str;
                } else if (str[0] === '"' && str[str.length - 1] === '"') {
                    text = str.substring(1, str.length - 1);
                } else {
                    fillColor = str;
                }
            }

            const finalScale = scale * otherScale;

            if (type === 'circle') {
                finalBox = finalBox.scaleAndMatchAspectRatio(finalScale, 1);
            } else {
                finalBox = finalBox.scale(finalScale);
            }

            canvas.draw({ ...finalBox, type, fillColor, borderRadius });

            if (text) {
                canvas.text({
                    ...finalBox,
                    text,
                    horizontalAlign: 'center',
                    verticalAlign: 'middle',
                    font: this.attributes.textFont,
                    color: 'black',
                    size: Math.round(finalBox.h * 0.9)
                });
            }
        }
    }

    _renderText(canvas, box, borderRadius) {
        const attributes = this.attributes;

        if (attributes.text || attributes.textPlaceholder) {
            let text = attributes.textPlaceholder;

            if (attributes.text) {
                text = attributes.text;

                if (attributes.textIsPassword) {
                    text = ''.padStart(text.length, '\u2022');
                }
            }

            const params = {
                ...box,
                ...this._getTextParams(box),
                text,
                borderRadius
            };

            canvas.text(params);

            if (attributes.allowTextEditing && attributes.focused) {
                const barPosition = canvas.getTextCharacterPosition(params, attributes.cursorIndex);

                canvas.rectangle({
                    x: Math.round(barPosition.x),
                    y: Math.round(barPosition.y),
                    width: 1,
                    height: Math.round(params.size * 0.85),
                    fillColor: params.color
                });
            }
        }
    }

    _getTextParams(box) {
        const { textSize, textColor, textFont, textHorizontalAlign, textVerticalAlign, textPlaceholderColor, textMargin } = this.attributes;

        return {
            horizontalAlign: textHorizontalAlign,
            verticalAlign: textVerticalAlign,
            font: textFont,
            color: this.attributes.text ? textColor : textPlaceholderColor,
            size: box.getAdaptativeSize(textSize),
            margin: box.getAdaptativeSize(textMargin),
        };
    }

    _shouldShowTooltip(cursor) {
        if (this.attributes.disabled || cursor.hovered !== this || cursor.dragged) {
            return false;
        }

        return true;
    }

    _shouldShowDraggedImage(cursor) {
        return cursor.dragged === this;
    }

    _shouldShowPointer(cursor) {
        if (cursor.hovered !== this) {
            return false;
        }

        if (this.attributes.showPointer === true) {
            return true;
        }

        if (!cursor.dragged || cursor.dragged === this) {
            if (this.attributes.showPointer === false) {
                return false;
            } else {
                return this.attributes.allowTextEditing || !!this.attributes.onClick
            }
        } else {
            return !!this.attributes.onDragEnd;
        }
    }
}

function isImageUrl(url) {
    return ['.png', '.jpg', '.jpeg'].some(ext => url.endsWith(ext));
}
globalThis.ALL_FUNCTIONS.push(WidgetGeneric);