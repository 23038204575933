import { DOM } from './dom';
import { GLOBAL_IMAGE_LOADER, ImageLoader } from './image-loader';
import { formatText } from './text-formatter';

const HORIZONTAL_ALIGN_TO_OFFSET_X = { left: 0, center: 0.5, right: 1 };
const VERTICAL_ALIGN_TO_OFFSET_Y = { top: 0, middle: 0.5, bottom: 1 };
const VERTICAL_ALIGN_TO_TEXT_BASELINE = {
    top: 'top',
    middle: 'middle',
    bottom: 'alphabetic'
}

function isNativeDomCanvas(value) {
    return typeof HTMLCanvasElement !== 'undefined' && value instanceof HTMLCanvasElement;
}

export class EnhancedCanvas {
    constructor(parameters = {}) {
        if (isNativeDomCanvas(parameters)) {
            parameters = { canvas: parameters };
        }

        const {
            canvas = null,
            globalImageLoader = false,
            width, height, size,
            domStyle = null,
            appendToBody = false
        } = parameters;

        this._canvas = canvas || DOM.createCanvas(300, 150);
        this._ctx = this._canvas.getContext('2d');
        this._imageLoader = globalImageLoader ? GLOBAL_IMAGE_LOADER :  new ImageLoader();

        if (width && height) {
            this.resize(width, height);
        } else if (size) {
            const [width, height] = size.split('x').map(x => parseInt(x));
            this.resize(width, height);
        }

        if (domStyle) {
            this.setDomStyle(domStyle);
        }

        if (appendToBody) {
            this.appendToBody();
        }
    }

    get width() {
        return this._canvas.width;
    }

    get height() {
        return this._canvas.height;
    }

    static configure(options) {
        DOM.configure(options);
    }

    isLoadingImage() {
        return this._imageLoader.isLoading();
    }

    getContext() {
        return this._ctx;
    }

    resize(width, height) {
        width = Math.round(width);
        height = Math.round(height);

        if (width === this.width && height === this.height) {
            return;
        }

        this._canvas.width = width;
        this._canvas.height = height;

        return this;
    }

    setDomStyle(style) {
        Object.assign(this._canvas.style, style);

        return this;
    }

    getDomElement() {
        return this._canvas;
    }

    appendToBody() {
        setTimeout(() => document.body.appendChild(this.getDomElement()));

        return this;
    }

    clear(color) {
        this._ctx.clearRect(0, 0, this.width, this.height);

        if (color) {
            this._ctx.fillStyle = color;
            this._ctx.fillRect(0, 0, this.width, this.height);
        }

        return this;
    }

    image(params = {}) {
        let {
            image = null
        } = params;

        if (typeof image === 'string') {
            image = this._imageLoader.get(image);
        } else if (image instanceof EnhancedCanvas) {
            image = image.getDomElement();
        }

        if (!image) {
            return;
        }

        const {
            width = image.width,
            height = image.height,
            sx = 0,
            sy = 0,
            sw = image.width,
            sh = image.height,
            borderRadius = 0
        } = params;

        this._setTransformMatrix(params, width, height);

        if (borderRadius) {
            this._roundRectangle(width, height, borderRadius);
            this._ctx.save();
            this._ctx.clip();
            this._ctx.drawImage(image, sx, sy, sw, sh, -width / 2, -height / 2, width, height);
            this._ctx.restore();
        } else {
            this._ctx.drawImage(image, sx, sy, sw, sh, -width / 2, -height / 2, width, height);
        }

        this._resetTransformatinMatrix();

        return this;
    }

    _fillAndStroke(params, isLine) {
        const {
            fillColor = null,
            borderColor = null,
            borderWidth = 1
        } = params;

        if (fillColor) {
            this._ctx.fillStyle = fillColor;
            this._ctx.fill();
        }

        if (borderColor && borderWidth) {
            if (isLine) {
                this._ctx.strokeStyle = borderColor;
                this._ctx.lineWidth = borderWidth;
                this._ctx.stroke();
            } else {
                this._ctx.save();
                this._ctx.clip();
                this._ctx.strokeStyle = borderColor;
                this._ctx.lineWidth = borderWidth * 2;
                this._ctx.stroke();
                this._ctx.restore();
            }
        }

        this._resetTransformatinMatrix();
    }

    dot(x, y, color = 'black') {
        this._ctx.beginPath();
        this._ctx.arc(x, y, 2, 0, Math.PI * 2);
        this._ctx.fillStyle = color;
        this._ctx.fill();
    }

    _setTransformMatrix(params, defaultWidth = 0, defaultHeight = 0) {
        let {
            x = 0,
            y = 0,
            width = defaultWidth,
            height = defaultHeight,
            angle = 0,
            horizontalAlign = 'left',
            verticalAlign = 'top'
        } = params;

        if (horizontalAlign === 'left') {
            x += width / 2;
        } else if (horizontalAlign == 'right') {
            x -= width / 2;
        }

        if (verticalAlign === 'top') {
            y += height / 2;
        } else if (verticalAlign === 'bottom') {
            y -= height / 2;
        }

        this._ctx.resetTransform();
        this._ctx.translate(x, y);
        this._ctx.rotate(angle);
    }

    _resetTransformatinMatrix() {
        this._ctx.resetTransform();
    }

    rectangle(params = {}) {
        const {
            width = 0,
            height = 0,
            borderRadius = 0
        } = params;

        this._setTransformMatrix(params);

        if (!borderRadius) {
            this._ctx.beginPath();
            this._ctx.rect(-width / 2, -height / 2, width, height);
        } else {
            this._roundRectangle(width, height, borderRadius);
        }
        
        this._fillAndStroke(params);

        return this;
    }

    square(params) {
        // TODO: make so width and height are the same
        
        return this.rectangle(params);
    }

    _roundRectangle(w, h, r) {
        const x1 = -w / 2;
        const y1 = -h / 2;
        const x2 =  w / 2;
        const y2 =  h / 2;

        this._ctx.beginPath();
        this._ctx.moveTo(x1 + r, y1);
        this._ctx.lineTo(x2 - r, y1);
        this._ctx.quadraticCurveTo(x2, y1, x2, y1 + r);
        this._ctx.lineTo(x2, y2 - r);
        this._ctx.quadraticCurveTo(x2, y2, x2 - r, y2);
        this._ctx.lineTo(x1 + r, y2);
        this._ctx.quadraticCurveTo(x1, y2, x1, y2 - r);
        this._ctx.lineTo(x1, y1 + r);
        this._ctx.quadraticCurveTo(x1, y1, x1 + r, y1);
        this._ctx.closePath();
    }

    ellipse(params = {}) {
        const { width = 0, height = 0 } = params;

        this._setTransformMatrix(params);
        this._ctx.beginPath();
        this._ctx.ellipse(0, 0, width / 2, height / 2, 0, 0, Math.PI * 2);

        this._fillAndStroke(params);

        return this;
    }

    circle(params) {
        // TODO: make so width and height are the same
        return this.ellipse(params);
    }

    triangle(params = {}) {
        return this._polygon(TRIANGLE_POINTS, params);
    }

    hexagon(params = {}) {
        return this.horizontalHexagon(params);
    }

    horizontalHexagon(params = {}) {
        return this._polygon(HORIZONTAL_HEXAGON_POINTS, params);
    }

    verticalHexagon(params = {}) {
        return this._polygon(VERTICAL_HEXAGON_POINTS, params);
    }

    star(params = {}) {
        return this._polygon(STAR_POINTS, params);
    }

    _polygon(points, params) {
        // TODO: take border radius into account
        const { width = 0, height = 0 } = params;

        this._setTransformMatrix(params);
        this._ctx.beginPath();
        this._ctx.moveTo(points[0][0] * width, points[0][1] * height);

        for (let i = 1; i < points.length; ++i) {
            const [x, y] = points[i];

            this._ctx.lineTo(x * width, y * height);
        }

        const isLine = points.length < 3;

        if (isLine) {
            this._fillAndStroke(params, true);
        } else {
            this._ctx.closePath();
            this._fillAndStroke(params, false);
        }

        return this;
    }

    polygon(params) {
        const { shape } = params;

        return this._polygon(shape, params);
    }

    _setTextParams(params) {
        const {
            color = 'black',
            horizontalAlign = 'left',
            verticalAlign = 'top',
            font = 'Arial',
            size = 12,
            bold = false,
            italic = false
        } = params;

        this._ctx.font = `${italic ? 'italic ' : ''}${bold ? 'bold ' : ''}${Math.round(size)}px "${font}"`;
        this._ctx.textAlign = horizontalAlign;
        this._ctx.textBaseline = VERTICAL_ALIGN_TO_TEXT_BASELINE[verticalAlign];
        this._ctx.fillStyle = color;
    }

    text(params = {}) {
        const {
            text = '',
        } = params;

        const { x, y } = this._computeTextPosition(params);

        this._setTextParams(params);
        this._ctx.resetTransform();
        this._ctx.fillText(text, x, y + 1);

        return this;
    }

    _computeTextPosition(params) {
        const {
            x = 0,
            y = 0,
            width = 0,
            height = 0,
            margin = 0,
            borderRadius = 0,
            horizontalAlign = 'left',
            verticalAlign = 'top'
        } = params;

        const textMargin = Math.max(margin, borderRadius);
        const textX = Math.round(x + textMargin + (width - 2 * textMargin) * HORIZONTAL_ALIGN_TO_OFFSET_X[horizontalAlign]);
        const textY = Math.round(y + textMargin + (height - 2 * textMargin) * VERTICAL_ALIGN_TO_OFFSET_Y[verticalAlign]);

        return { x: textX, y: textY };
    }

    getTextWidth(params = {}) {
        const { text = '' } = params;

        this._setTextParams(params);
        
        return this._ctx.measureText(text).width;
    }

    getTextCharacterPosition(params, charIndex) {
        const {
            text = '',
            size = 12,
            horizontalAlign = 'left',
            verticalAlign = 'top'
        } = params;

        const { x, y } = this._computeTextPosition(params);
        const textWidth = this.getTextWidth(params);
        const subTextWidth = this.getTextWidth({ ...params, text: text.substring(0, charIndex) });
        const startX = x - HORIZONTAL_ALIGN_TO_OFFSET_X[horizontalAlign] * textWidth;
        const startY = y - VERTICAL_ALIGN_TO_OFFSET_Y[verticalAlign] * size;

        return {
            x: startX + subTextWidth,
            y: startY
        };
    }

    drawFormattedText(params) {
        const { nodes } = formatText(this, params);

        for (const node of nodes) {
            this.draw(node);
        }
    }

    getFormattedText(params) {
        return formatText(this, params);
    }

    draw(params) {
        const { type } = params;

        if (type === 'rectangle') {
            this.rectangle(params);
        } else if (type === 'square') {
            this.square(params);
        } else if (type === 'circle') {
            this.circle(params);
        } else if (type === 'ellipse') {
            this.ellipse(params);
        } else if (type === 'triangle') {
            this.triangle(params);
        } else if (type === 'hexagon') {
            this.hexagon(params);
        } else if (type === 'horizontal-hexagon') {
            this.horizontalHexagon(params);
        } else if (type === 'vertical-hexagon') {
            this.verticalHexagon(params);
        } else if (type === 'star') {
            this.star(params);
        } else if (type === 'text') {
            this.text(params);
        } else if (type === 'image') {
            this.image(params);
        }

        return this;
    }

    setCursor(name) {
        this._canvas.style.cursor = name;
    }

    clearCursor() {
        this.setCursor('default');
    }

    reset() {
        this.clear();
        this.clearCursor();
    }
}

const TRIANGLE_POINTS = [
    [-0.5,  0.5],
    [ 0.5,  0.5],
    [   0, -0.5]
];

const HEXAGON_WIDTH_TO_HEIGHT_RATIO = 0.8660254037844386;
const HORIZONTAL_HEXAGON_POINTS = computePointsOnCircle([0, 1/6, 2/6, 3/6, 4/6, 5/6]).map(([x, y]) => [x, y / HEXAGON_WIDTH_TO_HEIGHT_RATIO]);
const VERTICAL_HEXAGON_POINTS = computePointsOnCircle([0, 1/6, 2/6, 3/6, 4/6, 5/6], 0.25).map(([x, y]) => [x / HEXAGON_WIDTH_TO_HEIGHT_RATIO, y]);
const STAR_POINTS = computePointsOnCircle([0, 2/5, 4/5, 1/5, 3/5], 0.25);

function computePointsOnCircle(percents, start = 0) {
    return percents.map(a => {
        const angle = (a + start) * Math.PI * 2;

        return [
            Math.cos(angle) / 2,
            Math.sin(angle) / 2
        ];
    });
}
globalThis.ALL_FUNCTIONS.push(EnhancedCanvas);