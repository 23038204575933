import { Item } from './item';

export class Clock extends Item {
    constructor(parameters) {
        super(parameters);
    }

    static name = 'Clock'
    static title = 'Clock'
    static description = 'Advance to the next season.'
    static ability = {
        trigger({ game }) {
            game.advanceSeason();
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Clock);