export class Location {
    constructor({ x = 0, y = 0, index = 0, grid = null, entity = null, terrain = null } = {}) {
        this.x = x;
        this.y = y;
        this.index = index;
        this.grid = grid;
        this.entity = entity;
        this.terrain = terrain;
    }
    
    // conflict with another global object
    static name = 'Location'

    get game() {
        return this.grid.parent;
    }

    toInfo() {
        return {
            grid: this.grid.name,
            x: this.x,
            y: this.y
        };
    }

    toId() {
        return `${this.grid?.name}/${this.x}/${this.y}`;
    }
}
globalThis.ALL_FUNCTIONS.push(Location);