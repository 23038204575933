import { CONSTANTS } from '../constants';
import { Cursed } from '../terrains/cursed';
import { Unit } from '../unit';
import { getCellsWithAlliedUnits, isPlainLocation, getCellsWithinVisionRange, getAlliedUnitCells, getCellsWithinStraightMovementRange, getPath, isTerrain, getAdjacentChain } from '../utils';
import { Character } from './character';

Object.assign(CONSTANTS, {
    TAZAD_SUN_RANGE: 2,
    TAZAD_WIND_RANGE: 3
})

export class Tazad extends Character {
    constructor(options) {
        super(options);
    }

    static name = 'Tazad'
    static title = 'Tazad'
    static abilities = [
        {
            description: `Spawn a $CURSED_TERRAIN_STRENGTH-strength unit on a plain within $TAZAD_SUN_RANGE range of an allied unit. The plain becomes cursed (any unit that moves on it has its strength set to $CURSED_TERRAIN_STRENGTH).`,
            target1({ source }, C) {
                return getCellsWithinVisionRange(getAlliedUnitCells(source), C.TAZAD_SUN_RANGE).filter(location => isPlainLocation(location));
            },
            trigger({ game, source, target1 }, C) {
                game.setTerrain({ source, terrain: new Cursed(), location: target1 });
                game.spawnUnit({ source, unit: new Unit({ strength: C.CURSED_TERRAIN_STRENGTH }), location: target1, owner: source.owner });
            }
        }, {
            description: `Move an allied unit in a straight line by 1-$TAZAD_WIND_RANGE cells. It curses any plain it moves through (except the destination).`,
            target1({ source }) {
                return getCellsWithAlliedUnits(source);
            },
            target2({ target1 }, C) {
                return getCellsWithinStraightMovementRange(target1, C.TAZAD_WIND_RANGE);
            },
            target2Highlight({ target1, target2 }) {
                return getPath(target1, target2).slice(0, -1).filter(location => isPlainLocation(location));
            },
            trigger({ game, source, target1, target2 }) {
                const crossedLocations = this.target2Highlight(...arguments);
                
                game.moveUnit({ source, unit: target1.entity, location: target2 });

                for (const location of crossedLocations) {
                    game.setTerrain({ source, location, terrain: new Cursed() });
                }
            }
        }, {
            description: `Select a chain of cursed terrains adjacent to an allied unit. Spawn a $CURSED_TERRAIN_STRENGTH-strength unit on each cell of this chain.`,
            target1({ source }) {
                return getAdjacentChain(getAlliedUnitCells(source), cell => isTerrain(cell, Cursed));
            },
            target1Highlight({ target1 }) {
                return getAdjacentChain(target1, cell => isTerrain(cell, Cursed));
            },
            target1Hover() {
                return null;
            },
            trigger({ game, source }, C) {
                for (const location of this.target1Highlight(...arguments)) {
                    game.spawnUnit({ source, location, unit: new Unit(C.CURSED_TERRAIN_STRENGTH), owner: source.owner });
                }
            }
        }
    ]
}
globalThis.ALL_FUNCTIONS.push(Tazad);