import { isInstanceOf } from '../../../engine/utils/objects';
import { Terrain } from './terrain';

export class Shrine extends Terrain {
    constructor(parameters) {
        super(parameters);
    }

    static name = 'Shrine'
    static description = `You win if you place a unit on both shrines.`;

    isObjective() {
        return true;
    }

    static postEventCallbacks = {
        moveUnit({ game, self }, { location }) {
            if (location.terrain === self && location.entity && location.entity.owner) {
                const allShrines = game.battlefield.filter(location => isInstanceOf(location.terrain, Shrine));

                if (allShrines.every(location => location.entity?.owner === self.location.entity.owner)) {
                    game.setWinner({ player: self.location.entity.owner });
                }
            }
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Shrine);