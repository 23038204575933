import { FlexBuffer } from '../flex-buffer';
import { Counter } from '../counter';
import { WrappedId } from '../wrapped-id';

export class Serializer {
    constructor() {
        this._v = 0;

        this._valueMap = new Map();
        this._valueIdCounter = new Counter();
        this._buffer = new FlexBuffer();
        this._idToValue = new Map();
    }

    reset() {
        this._valueMap.clear();
        this._valueIdCounter.reset();
        this._idToValue.clear();
    }

    getObjectById(id) {
        return this._idToValue.get(id);
    }

    getObjectId(object) {
        const data = this._valueMap.get(object);

        return data && data.id;
    }

    _initSerialization() {
        this._v += 1;
        this._buffer.reset();
    }

    _endSerialization() {
        const buffer = this._buffer.sliceBuffer();
        this._cleanMap();

        return buffer;
    }

    serialize(object) {
        this._initSerialization();
        this._pushValue(object);

        return this._endSerialization();
    }

    _cleanMap() {
        for (const [key, data] of this._valueMap.entries()) {
            if (data.v !== this._v) {
                this._valueMap.delete(key);
                this._idToValue.delete(data.id);
            }
        }
    }

    _getValueMapData(value) {
        let data = this._valueMap.get(value);

        if (!data) {
            // TODO: there may be problems if too much unique objects are serialized, because the counter would increase beyond max int
            data = { id: this._valueIdCounter.next(), v: 0 };
            this._valueMap.set(value, data);
            this._idToValue.set(data.id, value);
        }

        return data;
    }

    _pushString(string) {
        const data = this._getValueMapData(string);

        this._buffer.pushUint32(data.id);

        if (data.v !== this._v) {
            data.v = this._v;
            this._buffer.pushString(string);
        }
    }

    _pushArray(array) {
        const data = this._getValueMapData(array);

        this._buffer.pushUint32(data.id);

        if (data.v !== this._v) {
            data.v = this._v;

            this._buffer.pushUint32(array.length);

            for (const item of array) {
                this._pushValue(item);
            }
        }
    }

    _pushObject(object) {
        const data = this._getValueMapData(object);

        this._buffer.pushUint32(data.id);

        if (data.v !== this._v) {
            data.v = this._v;

            const className = (object.constructor && object.constructor.name) || '';
            const entries = Object.entries(object);
            const entryCount = entries.length;

            this._pushString(className);
            this._buffer.pushUint16(entryCount);

            for (const [key, value] of entries) {
                this._pushString(key);
                this._pushValue(value);
            }
        }
    }

    _pushBuffer(buffer) {
        const data = this._getValueMapData(buffer);

        this._buffer.pushUint32(data.id);

        if (data.v !== this._v) {
            data.v = this._v;

            this._buffer.pushUint32(buffer.byteLength);
            this._buffer.pushBuffer(buffer);
        }
    }

    _pushValue(value) {
        if (value === null) {
            this._buffer.pushUint8(1);
        } else if (value === true) {
            this._buffer.pushUint8(10);
        } else if (value === false) {
            this._buffer.pushUint8(11);
        } else if (typeof value === 'number') {
            this._buffer.pushUint8(2);
            this._buffer.pushFloat32(value);
        } else if (typeof value === 'string') {
            this._buffer.pushUint8(3);
            this._pushString(value);
        } else if (Array.isArray(value)) {
            this._buffer.pushUint8(4);
            this._pushArray(value);
        } else if (value instanceof ArrayBuffer || ArrayBuffer.isView(value)) {
            this._buffer.pushUint8(7);
            this._pushBuffer(value);
        } else if (value instanceof WrappedId) {
            this._buffer.pushUint8(8);
            this._buffer.pushUint32(value.id);
        } else if (isObject(value)) {
            this._buffer.pushUint8(5);
            this._pushObject(value);
        } else if (typeof value === 'function') {
            this._buffer.pushUint8(6);
            this._pushString(value.name);
        } else {
            this._buffer.pushUint8(0);
        }
    }

    replaceIdsWithObjects(obj) {
        if (!obj || typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'boolean') {
            return obj;
        } else if (Array.isArray(obj)) {
            for (let i = 0; i < obj.length; ++i) {
                obj[i] = this.replaceIdsWithObjects(obj[i]);
            }

            return obj;
        } else if (obj instanceof WrappedId) {
            return this._idToValue.get(obj.id);
        } else {
            for (const key of Object.keys(obj)) {
                obj[key] = this.replaceIdsWithObjects(obj[key]);
            }

            return obj;
        }
    }
}

function isObject(obj) {
    return obj && typeof obj === 'object';
}
globalThis.ALL_FUNCTIONS.push(Serializer);