import { hasCommandLineOption } from '../../engine/utils/command-line';
import DEFAULT_MAP from './maps/default-map';
import TEST_MAP from './maps/test-map';

export const CONSTANTS = {
    MAP: DEFAULT_MAP,
    CHARACTER_COUNT_PER_PLAYER: 4,
    MAX_ITEM_COUNT_PER_PLAYER: 4,
    ACTION_COUNT_PER_PLAYER_PER_TURN: 1,
    ACTION_COUNT_PER_CHARACTER_PER_TURN: 1,
    CHARACTER_STARTING_FOCUS: 1,
    BASIC_ABILITY_FOCUS_COST: 0,
    ENHANCED_ABILITY_FOCUS_COST: 5,
    CHARACTER_MAX_FOCUS: 5,
    FOCUS_GAIN_PER_TURN: 1,
    START_UNIT_STRENGTH: 5,
    HQ_UNIT_STRENGTH: 1,
    MAX_TURN_DURATION: Infinity,
    END_TURN_GRACE_DURATION: 0.5,
    CHARACTER_ASPECT_RATIO: 1,
    SEASON_COUNT: 3,
    SEASON_CHANGE_COUNT_TO_ADVANCE: 2,
    FIRST_SEASON_INDEX: 0,
    AUTO_END_TURN: true,
    PASSIVE_PLAYER_2: false,
    EXIT_ON_GAME_END: true,
    SHOW_START_TURN_POPUP: true,
    ME_ALWAYS_FIRST: false,
    STARTING_ITEMS: [
        // ['Clock', 2]
    ]
};

// TODO: manage this another way
if (hasCommandLineOption('--dev')) {
    Object.assign(CONSTANTS, {
        MAP: TEST_MAP,
        ACTION_COUNT_PER_PLAYER_PER_TURN: Infinity,
        ACTION_COUNT_PER_CHARACTER_PER_TURN: Infinity,
        // CHARACTER_COUNT_PER_PLAYER: 8,
        FIRST_SEASON_INDEX: 0,
        AUTO_MATCHMAKING: true,
        ME_ALWAYS_FIRST: true,
        EXIT_ON_GAME_END: false,
        SHOW_START_TURN_POPUP: false,
        PASSIVE_PLAYER_2: true
    });

    // CONSTANTS.MAP.shape = 'horizontal-hexagon';
    // CONSTANTS.MAP.shape = 'square';

    CONSTANTS.STARTING_ITEMS.push(
        ['RallyingFlag', 1]
    );
    // CONSTANTS.MAP.units.push(
    //     [9, 6, 1, 1],
    //     [10, 6, 2, 1],
    //     [10, 5, 1, 1],
    //     [10, 7, 1, 1],
    //     [11, 7, 1, 1],
    //     [8, 7, 1, 1],
    //     [6, 6, 1, 1],
    //     // [6, 3, 1, 1],
    //     // [15, 6, 1, 1],
    // );
}