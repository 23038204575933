export default {
    "width": 19,
    "height": 15,
    "shape": "vertical-hexagon",
    "terrains": [
        [1, 1, "Shrine", 0],
        [4, 1, "Water", 0],
        [17, 1, "Headquarters", 2],
        [4, 2, "Water", 0],
        [9, 3, "Cursed", 0],
        [13, 3, "Cursed", 0],
        [3, 4, "Water", 0],
        [9, 4, "Cursed", 0],
        [13, 4, "Cursed", 0],
        [0, 5, "Water", 0],
        [1, 5, "Water", 0],
        [8, 5, "Water", 0],
        [9, 5, "Water", 0],
        [10, 5, "Cursed", 0],
        [12, 5, "Cursed", 0],
        [0, 6, "Water", 0],
        [1, 6, "Water", 0],
        [7, 6, "Water", 0],
        [9, 6, "Cursed", 0],
        [13, 6, "Cursed", 0],
        [4, 7, "Cursed", 0],
        [9, 7, "Laboratory", 0],
        [14, 7, "Cursed", 0],
        [4, 8, "Cursed", 0],
        [8, 8, "Cursed", 0],
        [10, 8, "Water", 0],
        [16, 8, "Water", 0],
        [17, 8, "Water", 0],
        [6, 9, "Cursed", 0],
        [8, 9, "Cursed", 0],
        [9, 9, "Water", 0],
        [10, 9, "Water", 0],
        [17, 9, "Water", 0],
        [18, 9, "Water", 0],
        [4, 10, "Cursed", 0],
        [8, 10, "Cursed", 0],
        [14, 10, "Water", 0],
        [5, 11, "Cursed", 0],
        [9, 11, "Cursed", 0],
        [13, 12, "Water", 0],
        [1, 13, "Headquarters", 1],
        [14, 13, "Water", 0],
        [17, 13, "Shrine", 0]
    ],
    "units": [
        [11, 1, 1, 2],
        [17, 1, 3, 2],
        [4, 3, 1, 1],
        [4, 5, 1, 1],
        [10, 5, 1, 2],
        [13, 5, 1, 2],
        [8, 6, 1, 2],
        [9, 6, 2, 2],
        [10, 6, 2, 2],
        [1, 7, 1, 1],
        [7, 7, 1, 1],
        [8, 7, 2, 1],
        [10, 7, 2, 2],
        [11, 7, 1, 2],
        [17, 7, 1, 2],
        [7, 8, 2, 1],
        [8, 8, 2, 1],
        [9, 8, 1, 1],
        [5, 9, 1, 1],
        [8, 9, 1, 1],
        [14, 9, 1, 2],
        [14, 11, 1, 2],
        [1, 13, 3, 1],
        [7, 13, 1, 1]
    ]
};