export function wrapObject(obj, wrapper) {
    return new Proxy(obj, {
        get(obj, prop) {
            if (prop in obj) {
                return obj[prop];
            } else {
                return wrapper[prop];
            }
        }
    });
}

export function formatAttributes(values, defaultValues) {
    if (!values) {
        return Object.assign({}, defaultValues);
    }

    const result = {};

    for (const [key, defaultValue] of Object.entries(defaultValues)) {
        const value = values[key];

        if (value !== undefined) {
            result[key] = value;
        } else {
            result[key] = defaultValue;
        }
    }

    return result;
}

export function updateAttributes(baseValues, newValues) {
    for (const key of Object.keys(baseValues)) {
        const newValue = newValues[key];

        if (newValue !== undefined) {
            baseValues[key] = newValue;
        }
    }
}

export function changeObjectValues(object, callback) {
    for (const [key, value] of Object.entries(object)) {
        object[key] = callback(value, key);
    }
}

export function mapObject(object, callback) {
    const result = {};

    for (const [key, value] of Object.entries(object)) {
        result[key] = callback(value, key);
    }

    return result;
}

export function addObjectValues(object, values) {
    for (const key of Object.keys(values)) {
        object[key] = object[key] + values[key];
    }
}

export function ensureObjectValuesArePositive(object) {
    for (const [key, value] of Object.entries(object)) {
        object[key] = Math.max(value, 0);
    }
}

export function makeObjectFromArray(array, keyFunc = (value => value.id)) {
    const map = {};

    for (const item of array) {
        map[keyFunc(item)] = item;
    }

    return map;
}

export function cloneObject(object) {
    let result = object;

    if (Array.isArray(object)) {
        result = new Array(object.length);

        for (let i = 0; i < object.length; ++i) {
            result[i] = cloneObject(object[i]);
        }
    } else if (object && object.constructor === Object) {
        result = {};

        for (const [key, value] of Object.entries(object)) {
            result[key] = cloneObject(value);
        }
    }

    return result;
}

export function visitObject(obj, callback) {
    for (const [key, value] of Object.entries(obj)) {
        if (isVanillaObject(value)) {
            visitObject(value, callback);
        } else {
            callback(value, key);
        }
    }
}

export function mergeObjects(target, properties, excludedProperties = []) {
    if (!properties) {
        return;
    }

    for (const [key, value] of Object.entries(properties)) {
        if (excludedProperties.includes(key)) {
            continue;
        }

        if (Array.isArray(value)) {
            // target[key] = target[key].concat(value);
            target[key] = target[key].slice();
        } else if (isVanillaObject(value)) {
            if (!isVanillaObject(target[key])) {
                target[key] = {};
            }
            mergeObjects(target[key], value);
        } else if (value === undefined) {
            delete target[key];
        // } else if (typeof value === 'function') {
            // target[key] = value(target[key]);
        } else {
            target[key] = value;
        }
    }
}

export function isVanillaObject(value) {
    return value && value.constructor === Object;
}

export function pickProperties(object, properties) {
    const result = {};

    for (const property of properties) {
        result[property] = object[property];
    }

    return result;
}

export function isInstanceOf(value, classObject) {
    return value && value instanceof classObject;
}

export function isSubClassOf(value, classObject) {
    return value && value.prototype instanceof classObject;
}

export function swapPropertyValues(object, property1, property2) {
    const tmp = object[property1];
    
    object[property1] = object[property2];
    object[property2] = tmp;
}