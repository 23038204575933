import { PotionOfStrength } from '../items/potion-of-strength';
import { Terrain } from './terrain';

export class Laboratory extends Terrain {
    constructor(parameters) {
        super(parameters);
    }

    isObjective() {
        return true;
    }

    static name = 'Laboratory'
    static title = 'Laboratory'
    static description = `If you control this location at the beginning of your turn, receive a potion of strength (+$POTION_OF_STRENGTH_BONUS strength to an allied unit).`
    static postEventCallbacks = {
        endTurn({ game, self }) {
            if (self.location.entity && self.location.entity.owner === game.activePlayer) {
                game.addItem({ source: self, item: new PotionOfStrength(), owner: self.location.entity.owner });
            }
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Laboratory);