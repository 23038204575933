import { FlexBuffer } from '../flex-buffer';
import { WrappedId } from '../wrapped-id';

export class Deserializer {
    constructor(functions = {}) {
        this._functions = functions;
        this._buffer = null;
        this._idToValue = new Map();
        this._valueToId = new Map();
    }

    reset() {
        this._buffer = null;
        this._idToValue.clear();
        this._valueToId.clear();
    }

    getObjectId(object) {
        return this._valueToId.get(object);
    }

    deserialize(buffer) {
        this._idToValue.clear();
        this._valueToId.clear();
        if (buffer instanceof FlexBuffer) {
            this._buffer = buffer;
        } else {
            this._buffer = new FlexBuffer(buffer);
        }
        const value = this._readValue();

        return value;
    }

    replaceObjectsWithIds(value) {
        if (!value || typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
            return value;
        } else {
            const id = this._valueToId.get(value);

            if (id) {
                return new WrappedId(id);
            } else if (Array.isArray(value)) {
                return value.map(value => this.replaceObjectsWithIds(value));
            } else {
                const result = {};

                for (const key of Object.keys(value)) {
                    result[key] = this.replaceObjectsWithIds(value[key]);
                }

                return result;
            }
        } 
    }

    _readString() {
        const id = this._buffer.readUint32();
        let value = this._idToValue.get(id);

        if (value === undefined) {
            value = this._buffer.readString();
            this._idToValue.set(id, value);
            this._valueToId.set(value, id);
        }

        return value;
    }

    _readArray() {
        const id = this._buffer.readUint32();
        let value = this._idToValue.get(id);

        if (value === undefined) {
            const length = this._buffer.readUint32();
            value = new Array(length);
            this._idToValue.set(id, value);
            this._valueToId.set(value, id);

            for (let i = 0; i < length; ++i) {
                const item = this._readValue();
                value[i] = item;
            }
        }

        return value;
    }

    _readObject() {
        const id = this._buffer.readUint32();
        let value = this._idToValue.get(id);

        if (value === undefined) {
            const className = this._readString();
            const classObject = this._functions[className];
            const size = this._buffer.readUint16();
            value = classObject ? Object.create(classObject.prototype) : {};
            this._idToValue.set(id, value);
            this._valueToId.set(value, id);

            for (let i = 0; i < size; ++i) {
                const key = this._readString();
                const item = this._readValue();

                value[key] = item;
            }
        }

        return value;
    }

    _readFunction() {
        const name = this._readString();

        return this._functions[name];
    }

    _readBuffer() {
        const id = this._buffer.readUint32();
        let value = this._idToValue.get(id);

        if (value === undefined) {
            const byteSize = this._buffer.readUint32();
            value = new Uint8Array(byteSize);
            this._idToValue.set(id, value);
            this._valueToId.set(value, id);

            for (let i = 0; i < byteSize; ++i) {
                value[i] = this._buffer.readUint8();
            }
        }

        return value;
    }

    _readWrappedId() {
        const id = this._buffer.readUint32();

        return new WrappedId(id);
    }

    _readValue() {
        const typeId = this._buffer.readUint8();

        if (typeId === 1) {
            return null;
        } else if (typeId === 10) {
            return true;
        } else if (typeId === 11) {
            return false;
        } else if (typeId === 2) {
            return this._buffer.readFloat32();
        } else if (typeId === 3) {
            return this._readString();
        } else if (typeId === 4) {
            return this._readArray();
        } else if (typeId === 5) {
            return this._readObject();
        } else if (typeId === 6) {
            return this._readFunction();
        } else if (typeId === 7) {
            return this._readBuffer();
        } else if (typeId === 8) {
            return this._readWrappedId();
        } else {
            return undefined;
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Deserializer);