const ARGV = [];

function argv() {
    return ARGV;
}

function getCommandLineOptionIndex(arg) {
    if (!Array.isArray(arg)) {
        arg = [arg];
    }

    return argv().findIndex(opt => arg.includes(opt));
}

export function hasCommandLineOption(arg) {
    return getCommandLineOptionIndex(arg) !== -1;
}

export function getCommandLineOptionValue(arg) {
    const index = getCommandLineOptionIndex(arg);

    return index === -1 ? undefined : argv()[index + 1] || null;
}