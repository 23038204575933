import { Entity } from './entity';

export class Player extends Entity {
    constructor({ id, username, tools, index }) {
        super();

        this.owner = this;
        this.id = id;
        this.username = username;
        this.tools = tools;
        this.characters = [];
        this.items = [];
        this.index = index;
        this.opponent = null;
        this.isFirst = this.index === 0;
    }

    static name = 'Player'

    getCharacterGridName() {
        return `characters${this.index + 1}`;
    }

    getItemGridName() {
        return `items${this.index + 1}`;
    }
}
globalThis.ALL_FUNCTIONS.push(Player);