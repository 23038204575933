import { Terrain } from './terrain';

export class Headquarters extends Terrain {
    constructor(parameters) {
        super(parameters);
    }

    static name = 'Headquarters'
    static description = `A player wins if they place a unit on their opponent's headquarters.`

    isObjective() {
        return true;
    }

    static postEventCallbacks = {
        moveUnit({ game, self }, { location }) {
            if (location.terrain === self && location.entity && location.entity.owner && location.entity.owner !== self.owner) {
                game.setWinner({ player: location.entity.owner });
            }
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Headquarters);