import { UserInterface } from '../../engine/client/user-interface';
import { ConnectionToServer } from '../../engine/client/connection-to-server';
import { GameScreen } from './screens/game-screen';
import { LobbyScreen } from './screens/lobby-screen';
import { WelcomeScreen } from './screens/welcome-screen';
import { ErrorScreen } from './screens/error-screen';
import { GRAPHICS } from './graphics';
import { CONSTANTS } from '../data/constants';

const UI_ASPECT_RATIO = 16 / 9;

export class Client {
    constructor(options = {}) {
        const {
            webSocketServerUrl,
            window,
            mirror = false,
            log = true,
            useLocalStorage = false,
            graphics = GRAPHICS,
            constants = CONSTANTS
        } = options;

        this._server = new ConnectionToServer({
            webSocketServerUrl,
            onDisconnect: () => this._onDisconnect()
        });
        this._ui = new UserInterface({
            window,
            aspectRatio: UI_ASPECT_RATIO,
            backgroundColor: 'black'
        });
        this._state = {};
        this._activeScreen = null;
        this._mirror = mirror;
        this._shouldLog = log;
        this._useLocalStorage = useLocalStorage;
        this._constants = constants;
        this._graphics = graphics;

        this._init();
        this.showWelcomScreen();
    }

    get ui() {
        return this._ui;
    }

    get server() {
        return this._server;
    }

    get state() {
        return this._state;
    }

    get constants() {
        return this._constants;
    }

    get graphics() {
        return this._graphics;
    }

    async start() {

    }

    setRenderEnabled(value) {
        this._ui.setRenderEnabled(value);
    }

    log(message) {
        if (this._shouldLog) {
            console.log(message);
        }
    }

    loadFromLocalStorage(key) {
        if (!this._useLocalStorage) {
            return '';
        } else {
            return localStorage.getItem(key) || '';
        }
    }

    saveToLocalStorage(key, value) {
        if (!value) {
            value = '';
        }

        if (this._useLocalStorage) {
            localStorage.setItem(key, value);
        }
    }

    _onDisconnect() {
        this.log('DISCONNECTED FROM SERVER');
        // this.showErrorScreen('Disconnected (the server has likely crashed)');
    }

    _showScreen(ScreenClass, arg) {
        if (this._activeScreen && this._activeScreen instanceof ScreenClass) {
            return;
        }

        if (this._activeScreen) {
            this._activeScreen.destroy();
        }

        this._activeScreen = new ScreenClass(this, arg);
        this._activeScreen.show(arg);

        return this;
    }

    authenticate({ username }) {
        return this._server.query('authenticate', { username });
    }

    logout() {
        return this._server.query('logout', null);
    }

    getGameState(gameId) {
        return this._server.query('getGameState', { gameId });
    }

    sendGameAction(action, data) {
        return this._server.query('gameAction', { action, data });
    }

    showErrorScreen(message) {
        return this._showScreen(ErrorScreen, message);
    }

    showWelcomScreen() {
        return this._showScreen(WelcomeScreen);
    }

    showLobbyScreen() {
        return this._showScreen(LobbyScreen);
    }

    showGameScreen() {
        return this._showScreen(GameScreen, { mirror: this._mirror });
    }

    refresh() {
        this._ui.refresh();
    }

    _init() {
        this._ui.on({
            keyDown(evt) {
                if (evt.code === 'Tab') {
                    evt.domEvent.preventDefault();
                    if (evt.shiftKey) {
                        this._ui.focusPrevious();
                    } else {
                        this._ui.focusNext();
                    }
                }
            }
        }, this);

        this._server.on({
            message(data) {
                console.log(data);
            },
            notifyStateChange(data) {
                this._onNewState(data);
            }
        }, this);
    }

    _onNewState(state) {
        const { id, username, activity, gameId } = state;
        const currentActivity = this._state.activity;
        Object.assign(this._state, state);
        this.log(`NEW STATE: ${JSON.stringify(state)}`);

        this.saveToLocalStorage('username', username);

        if (!id) {
            this.showWelcomScreen();
        } else if (activity === 'idle' && currentActivity !== 'play') {
            this.showLobbyScreen();
        } else if (activity === 'play') {
            this.showGameScreen();
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Client);