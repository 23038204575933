import { checkValueAgainstSchema, makeEnumSchema } from '../../engine/utils/schema';
import { CONSTANTS } from './constants';
import { Player } from './player';
import { Unit } from './unit';
import { getNextValue } from '../../engine/utils/array';
import { isInstanceOf } from '../../engine/utils/objects';
import { Character } from './characters/character';
import { Item } from './items/item';
import { CHARACTERS } from './characters';
import { ITEMS } from './items';
import { SpatialIndex } from './spatial-index';
import { TERRAINS } from './terrains';
import { Entity } from './entity';
import { Plain } from './terrains/plain';
import { AbilityPayload } from './ability-payload';

const CHARACTER_ID_ENUM = makeEnumSchema(CHARACTERS.names());
const LOCATION_SCHEMA = { grid: 'string', x: 'index', y: 'index' };

export class Game extends Entity {
    constructor({ players, constants = CONSTANTS }) {
        super();

        const { FIRST_SEASON_INDEX, SEASON_CHANGE_COUNT_TO_ADVANCE } = constants;

        this.constants = constants;
        this.spatialIndex = new SpatialIndex(this, Object.keys(EVENTS));
        this.players = [];
        this.turnDuration = 0;
        this.currentSeasonIndex = FIRST_SEASON_INDEX - 1;
        this.seasonChangeCount = SEASON_CHANGE_COUNT_TO_ADVANCE;
        this.activePlayer = null;
        this.winner = null;
        this.remainingActionCount = 0;
        this.turnNumber = 0;
        this.changed = false;
        this.battlefield = null;
        this.history = [];

        this._init(players);
    }

    static name = 'Game'

    _init(playersInfo) {
        const {
            MAP, CHARACTER_COUNT_PER_PLAYER, MAX_ITEM_COUNT_PER_PLAYER, STARTING_ITEMS
        } = this.constants;

        this.spatialIndex.addGrids({
            battlefield: { width: MAP.width, height: MAP.height, shape: MAP.shape },
            characters1: { width: CHARACTER_COUNT_PER_PLAYER },
            characters2: { width: CHARACTER_COUNT_PER_PLAYER },
            items1: { width: MAX_ITEM_COUNT_PER_PLAYER },
            items2: { width: MAX_ITEM_COUNT_PER_PLAYER }
        });

        this.battlefield = this.spatialIndex.getGrid('battlefield');

        for (const location of this.battlefield.cells) {
            this.setTerrain({ location, terrain: new Plain() });
        }

        for (let i = 0; i < playersInfo.length; ++i) {
            const { id, username, tools } = playersInfo[i];
            const player = new Player({ id, username, tools, index: i });

            this.addPlayer({ player });

            for (const characterId of tools.characterIds) {
                this.addCharacter({
                    character: CHARACTERS.instanciate(characterId),
                    owner: player
                });
            }
        }

        for (const [itemName, playerNumber] of STARTING_ITEMS) {
            this.addItem({
                item: ITEMS.instanciate(itemName),
                owner: this.players[playerNumber - 1]
            });
        }

        for (const [x, y, terrainName, playerNumber] of MAP.terrains) {
            this.setTerrain({
                location: { grid: 'battlefield', x, y },
                terrain: TERRAINS.instanciate(terrainName),
                owner: this.players[playerNumber - 1] || null
            });
        }

        for (const [x, y, unitStrength, playerNumber] of MAP.units) {
            this.spawnUnit({
                location: { grid: 'battlefield', x, y },
                unit: new Unit({ strength: unitStrength }),
                owner: this.players[playerNumber - 1]
            });
        }

        this.players[0].opponent = this.players[1];
        this.players[1].opponent = this.players[0];
    }

    static checkPlayerTools(tools, constants = CONSTANTS) {
        const { CHARACTER_COUNT_PER_PLAYER } = constants;

        return checkValueAgainstSchema(tools, {
            characterIds: ['set', CHARACTER_ID_ENUM, CHARACTER_COUNT_PER_PLAYER]
        });
    }

    static checkParameters({ playerIds, playerParameters, gameParameters }) {
        return playerIds.length === 2;
    }

    getCharacterGrid(player) {
        return this.spatialIndex.getGrid(player.getCharacterGridName());
    }

    getItemGrid(player) {
        return this.spatialIndex.getGrid(player.getItemGridName());
    }

    getPlayer(id) {
        return this.players.find(player => player.id === id);
    }

    start({ currentTime }) {
        this.endTurn();

        return this._getUpdateState();
    }

    update({ currentTime, elapsedTime }) {
        const { MAX_TURN_DURATION, END_TURN_GRACE_DURATION } = this.constants;

        this.turnDuration += elapsedTime;

        if (this.turnDuration > MAX_TURN_DURATION + END_TURN_GRACE_DURATION) {
            this.endTurn();
        }

        return this._getUpdateState();
    }

    _getUpdateState() {
        if (this.constants.PASSIVE_PLAYER_2 && !this.activePlayer.isFirst) {
            this.endTurn();
        }

        const result = { changed: this.changed, winner: this.winner?.id };
        this.changed = false;

        return result;
    }

    // the return value indicates if the state has changed
    playerInput({ playerId, action, data }) {
        const player = this.players.find(p => p.id === playerId);
        let message = null;

        if (!player) {
            return { error: 'not player of the game' };
        }

        if (action === 'surrender') {
            this.setWinner({ player: player.opponent });
        }

        if (player !== this.activePlayer) {
            return { error: 'not active player' };
        }

        if (action === 'end-turn') {
            this.endTurn();
        } else if (action === 'ability') {
            const { error } = checkValueAgainstSchema(data, {
                source: LOCATION_SCHEMA,
                targets: ['list', LOCATION_SCHEMA]
            });

            if (error) {
                return { error };
            }

            const constants = this.constants;
            const source = this.spatialIndex.getLocation(data.source)?.entity;
            const targets = data.targets.map(info => this.spatialIndex.getLocation(info));

            // TODO: subclass that can use abilities?
            if ((!isInstanceOf(source, Character) && !isInstanceOf(source, Item)) || source.owner !== player) {
                return { error: `invalid source ${JSON.stringify(data.entity)}`};
            }

            for (let i = 0; i < targets.length; ++i) {
                if (!targets[i]) {
                    return { error: `invalid target at index ${i} (${JSON.stringify(data.targets[i])})`};
                }
            }

            if (source.extraActionCount === 0 && this.remainingActionCount === 0) {
                return { error: 'no action remaining for player' };
            }

            if (source.extraActionCount === 0 && source.remainingActionCount === 0) {
                return { error: 'character cannot use any more ability this turn' };
            }

            const ability = source.getAbility(this.currentSeasonIndex);
            const payload = new AbilityPayload(this, ability, source, targets);

            if (ability.condition && !ability.condition(payload, constants)) {
                return { error: 'character cannot use this ability' };
            }

            if (ability.target1) {
                const validTargets = ability.target1(payload, constants);

                if (!validTargets.includes(payload.target1)) {
                    return { error: 'invalid target 1' };
                }
            }

            if (ability.target2) {
                const validTargets2 = ability.target2(payload, constants);

                if (!validTargets2.includes(payload.target2)) {
                    return { error: 'invalid target 2' };
                }
            }

            message = source.makeMessageFromAbilityUse(this, ability, payload.target1, payload.target2);

            this.triggerAbility(payload);
            this._checkWinner();

            if (this.constants.AUTO_END_TURN && !this.winner && this.remainingActionCount === 0) {
                this.endTurn();
            }
        } else {
            return { error: `invalid action "${action}"` };
        }

        this.history.push({ playerId, action, data, message });

        return this._getUpdateState();
    }

    _playerOwnsLocation(player, cell) {
        return cell?.entity?.owner === player;
    }

    _checkWinner() {
        // TODO: handle this elsewhere? (in the headquarters?)

        const player1 = this.players[0];
        const player2 = this.players[1];
        let player1UnitCount = 0;
        let player2UnitCount = 0;

        for (const location of this.battlefield.cells) {
            if (location.entity?.owner === player1) {
                player1UnitCount += 1;
            } else if (location.entity?.owner === player2) {
                player2UnitCount += 1;
            }
        }

        if (player1UnitCount === 0) {
            this.setWinner({ player: player2 });
        } else if (player2UnitCount === 0) {
            this.setWinner({ player: player1 });
        } 
    }

    setEntityLocation(entity, location) {
        this.spatialIndex.setEntityLocation(entity, location);
    }

    setTerrainLocation(terrain, location) {
        this.spatialIndex.setTerrainLocation(terrain, location);
    }
}

const EVENTS = {
    addPlayer({ player }) {
        this.players.push(player);
    },

    addCharacter({ source, character, owner }) {
        character.owner = owner;
        owner.characters.push(character);
        this.setEntityLocation(character, owner.getCharacterGridName());
    },

    addItem({ source, item, owner }) {
        item.owner = owner;
        owner.items.push(item);
        this.setEntityLocation(item, owner.getItemGridName());
    },

    removeItem({ source, item }) {
        const itemIndex = item?.owner.items.indexOf(item);

        if (itemIndex !== null && itemIndex !== -1) {
            item.owner.items.splice(itemIndex, 1);
        }

        this.setEntityLocation(item, null);
    },

    setTerrain({ source, location, terrain, owner }) {
        if (owner !== undefined) {
            terrain.owner = owner;
        }
        this.setTerrainLocation(terrain, location);
    },

    spawnUnit({ source, unit, owner, location }) {
        if (owner !== undefined) {
            unit.owner = owner;
        }
        this.moveUnit({ source, unit, location});
    },

    killUnit({ source, unit }) {
        this.setEntityLocation(unit, null);
    },

    moveUnit({ source, unit, location }) {
        if (location.entity === unit) {
            return;
        } else if (!location.entity) {
            this.setEntityLocation(unit, location);
        } else if (location.entity.owner === unit.owner) {
            this.mergeUnits({ source, unit1: location.entity, unit2: unit });
        } else {
            this.makeUnitsFight({ source, unit1: location.entity, unit2: unit })
        }
    },

    mergeUnits({ unit1, unit2 }) {
        unit1.strength += unit2.strength;
        unit1.remainingTurnCount = Math.max(unit1.remainingTurnCount, unit2.remainingTurnCount);
        unit1.tags.push(...unit2.tags);

        this.setEntityLocation(unit2, null);
    },

    makeUnitsFight({ source, unit1, unit2 }) {
        const location = unit1.location;
        const strength1 = unit1.strength;
        const strength2 = unit2.strength;

        unit1.strength -= strength2;
        unit2.strength -= strength1;

        if (unit1.strength > unit2.strength) {
            this.killUnit({ source, unit: unit2 });
        } else if (unit2.strength > unit1.strength) {
            this.killUnit({ source, unit: unit1 });
            this.setEntityLocation(unit2, location);
        } else {
            this.killUnit({ source, unit: unit1 });
            this.killUnit({ source, unit: unit2 });
        }
    },

    setUnitStrength({ source, unit, modifier, value }) {
        if (value !== undefined) {
            unit.strength = value;
        } else if (modifier !== undefined) {
            unit.strength += modifier;
        }

        if (unit.strength <= 0) {
            this.killUnit({ source, unit });
        }
    },

    spawnOrMergeUnit({ source, unit, owner, location }) {
        if (location.entity) {
            this.mergeUnits({ source, unit1: location.entity, unit2: unit });
        } else {
            this.spawnUnit({ source, unit, owner, location })
        }
    },

    addTag({ source, entity, tag }) {
        entity.tags.push({ value: tag, source });
    },

    giveExtraAction({ source, character }) {
        character.extraActionCount += 1;
    },

    endTurn() {
        const { ACTION_COUNT_PER_PLAYER_PER_TURN, ACTION_COUNT_PER_CHARACTER_PER_TURN, SEASON_CHANGE_COUNT_TO_ADVANCE } = this.constants;

        this.activePlayer = getNextValue(this.players, this.activePlayer);
        this.turnDuration = 0;
        this.remainingActionCount = ACTION_COUNT_PER_PLAYER_PER_TURN;
        this.turnNumber += 1;
        this.seasonChangeCount += 1;

        for (const character of this.activePlayer.characters) {
            character.remainingActionCount = ACTION_COUNT_PER_CHARACTER_PER_TURN;
            character.extraActionCount = 0;
        }

        if (this.seasonChangeCount >= SEASON_CHANGE_COUNT_TO_ADVANCE) {
            this.advanceSeason();
        }

        this.changed = true;
    },

    advanceSeason() {
        const { SEASON_COUNT } = this.constants;

        this.seasonChangeCount = 0;
        this.currentSeasonIndex = (this.currentSeasonIndex + 1) % SEASON_COUNT;
    },

    triggerAbility({ ability, source, target1, target2 }) {
        // TODO: maybe change the way characters are handled here
        if (isInstanceOf(source, Character)) {
            if (source.extraActionCount > 0) {
                source.extraActionCount -= 1;
            } else {
                this.remainingActionCount -= 1;
                source.remainingActionCount -= 1;
            }
        } else if (isInstanceOf(source, Item)) {
            this.removeItem({ item: source });
        }

        this.changed = true;
        ability.trigger({ game: this, source, target1, target2 }, this.constants);
    },

    setWinner({ player }) {
        if (!this.winner) {
            this.winner = player;
            this.changed = true;
        }
    }
};

for (const [key, effect] of Object.entries(EVENTS)) {
    Game.prototype[key] = function(rawData) {
        // TODO: maybe properly format the data depending on the event
        const formattedData = rawData || {};

        const eventHookEntities = this.spatialIndex.eventHooks[key];
        const preEventCallbackEntities = this.spatialIndex.preEventCallbacks[key];
        const postEventCallbackEntities = this.spatialIndex.postEventCallbacks[key];

        for (const entity of eventHookEntities) {
            entity.eventHooks[key]({ self: entity, game: this }, formattedData);
        }

        for (const entity of preEventCallbackEntities) {
            entity.preEventCallbacks[key]({ self: entity, game: this }, formattedData);
        }
        
        effect.call(this, formattedData);

        for (const entity of postEventCallbackEntities) {
            entity.postEventCallbacks[key]({ self: entity, game: this }, formattedData);
        }

        return this;
    };
}
globalThis.ALL_FUNCTIONS.push(Game);