import { CONSTANTS } from '../../data/constants';
import { Screen } from './screen';

export class ErrorScreen extends Screen {
    constructor(client) {
        super(client);
    }

    show(message) {
        this._ui.setRootWidget([
            `#message ${CONSTANTS.BACKGROUND_COLOR} %5 ]10`
        ]);

        this._ui.setWidgetAttributes({
            message: {
                style: { textSize: 0.2, textColor: 'red' },
                text: message,
            }
        });
    }
}
globalThis.ALL_FUNCTIONS.push(ErrorScreen);