import { dedupe } from '../../../engine/utils/array';
import { isInstanceOf } from '../../../engine/utils/objects';
import { CONSTANTS } from '../constants';
import { Plain } from '../terrains/plain';
import { Water } from '../terrains/water';
import { Unit } from '../unit';
import { getAdjacentCells, getAdjacentChain, getAllCells, getAlliedUnitCells, getCellsWithinVisionRange, hasAdjacentCell, isAlliedUnitLocation, isEmptyLocation, isObjectiveLocation, isPlainLocation, isTerrain, spread } from '../utils';
import { Character } from './character';

Object.assign(CONSTANTS, {
    ODREC_SUN_POWER: 2,
    ODREC_WIND_RANGE: 2
});

export class Odrec extends Character {
    constructor(options) {
        super(options);
    }

    static name = 'Odrec'
    static title = 'Odrec'
    static abilities = [
        {
            description: `Spawn a $ODREC_SUN_POWER-strength unit on a water cell adjacent to an allied unit. This cell becomes plain, and all adjacent empty plains become water.`,
            target1({ source }) {
                return spread({
                    start: getAlliedUnitCells(source),
                    spread: cell => isInstanceOf(cell.terrain, Water),
                    distance: 1
                });
            },
            target1Highlight({ target1 }) {
                return getAdjacentCells(target1).filter(cell => isEmptyLocation(cell) && isPlainLocation(cell));
            },
            trigger({ game, source, target1 }, C) {
                game.setTerrain({ source, location: target1, terrain: new Plain() });
                game.spawnUnit({ source, location: target1, unit: new Unit(C.ODREC_SUN_POWER), owner: source.owner });

                for (const location of this.target1Highlight(...arguments)) {
                    game.setTerrain({ source, location, terrain: new Water() });
                }
            }
        }, {
            description: `Transform a non-objective cell within $ODREC_WIND_RANGE range of an allied unit into water. Any unit on this cell dies.`,
            target1({ source }, C) {
                return getCellsWithinVisionRange(getAlliedUnitCells(source), { min: 0, max: C.ODREC_WIND_RANGE }).filter(location => !isObjectiveLocation(location) && !isWater(location));
            },
            trigger({ game, source, target1 }) {
                if (target1.entity) {
                    game.killUnit({ source, unit: target1.entity });
                }

                game.setTerrain({ source, location: target1, terrain: new Water() });
            }
        }, {
            description: `Select a cell adjacent to a chain of water. Move all allied units adjacent to this chain on the selected cell.`,
            target1({ source }) {
                return getAllCells(source)
                    .filter(location => !isWater(location) && hasAdjacentCell(location, adj => isTerrain(adj, Water)))
                    .filter(location => this.target1Highlight({ source, target1: location }).length > 0);
            },
            target1Highlight({ source, target1 }) {
                const waterChains = getAdjacentChain(target1, isWater);
                const adjacentLocations = dedupe(waterChains.map(location => getAdjacentCells(location).filter(location => !isWater(location))).flat());

                return adjacentLocations.filter(location => isAlliedUnitLocation(location, source));
            },
            trigger({ game, source, target1 }) {
                for (const location of this.target1Highlight(...arguments)) {
                    game.moveUnit({ source, unit: location.entity, location: target1 });
                }
            }
        }
    ]
}

function isWater(location) {
    return isTerrain(location, Water);
}
globalThis.ALL_FUNCTIONS.push(Odrec);