export class Counter {
    constructor() {
        this._next = 1;
    }

    next() {
        return this._next++;
    }

    reset() {
        this._next = 1;
    }
}
globalThis.ALL_FUNCTIONS.push(Counter);