import { EventTarget } from './event-target';
import { Scheduler } from './scheduler';
import { getCurrentTime } from './time';

export class Timer extends EventTarget {
    constructor(options = {}) {
        super();

        const {
            refreshRate = 100
        } = options;

        this.registerEvents(['timePass']);

        this._refreshRate = refreshRate;
        this._remaining = 0;
        this._startTime = 0;
        this._scheduler = new Scheduler();
        this._destroyed = false;
    }

    reset(duration) {
        if (this._destroyed) {
            return;
        }

        this._remaining = duration;
        this._startTime = getCurrentTime();
        this._scheduler.reset();

        this._scheduler.schedule({
            callback: () => this._onTimePass(),
            delay: this._refreshRate,
            repeat: true
        });

        this.triggerEvent('timePass', { elapsed: 0 });
    }

    _onTimePass() {
        const now = getCurrentTime();
        const elapsed = now - this._startTime;

        this.triggerEvent('timePass', { elapsed });
    }

    destroy() {
        this._scheduler.destroy();
        this.resetEvents();
        this._destroyed = true;
    }
}
globalThis.ALL_FUNCTIONS.push(Timer);