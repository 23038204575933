// const IMAGE_SIZE_RATIO = 3 / 4;
const IMAGE_SIZE_RATIO = 1;

export function formatText(helper, parameters = {}) {
    const {
        rules = {},
        text = '',
        x = 0,
        y = 0,
        fixedWidth = null,
        fixedHeight = null,
        maxWidth = Infinity,
        backgroundColor = null,
        backgroundRadius = 0,
        backgroundMargin = 0,
        backgroundBorderWidth = 1,
        backgroundBorderColor = null,
        horizontalAlign = 'left',
        verticalAlign = 'top',
        textFont = 'Arial',
        textSize = 12,
        textColor = 'black',
        mirrorHorizontally = false,
        mirrorVertically = false
    } = parameters;

    const tokens = tokenize(text + '\n', rules);
    const lines = [];

    const baseTextParams = {
        font: textFont,
        color: textColor,
        size: textSize,
        bold: false,
        italic: false,
        center: false,
        verticalCenter: false,
    };
    const textParams = Object.assign({}, baseTextParams);

    const forceCenter = horizontalAlign === 'center';
    const margin = Math.max(backgroundMargin, backgroundRadius);
    const maxLineWidth = (fixedWidth || maxWidth) - margin * 2;
    const startTextX = margin;
    const startTextY = margin;
    let textY = startTextY;
    let textX = startTextX;
    let currentLine = [];
    let currentLineWidth = 0;
    let longestLineWidth = 0;
    let currentLineHeight = textSize;
    let centerLine = false;
    let verticalCenter = false;
    let prevLineHeight = 0;
    let last = null;

    for (const token of tokens) {
        const { type, content } = token;

        let width = 0;
        let height = 0;
        let element = null;

        if (type === 'process') {
            content(textParams, baseTextParams);
        } else if (type === 'image') {
            height = textParams.size * IMAGE_SIZE_RATIO;
            width = content.width / content.height * height;
            element = { type: 'image', image: content, width, height, horizontalAlign: 'left', verticalAlign: 'bottom' };
        } else if (type === 'word') {
            width = helper.getTextWidth({ text: content, ...textParams });
            height = textParams.size * (content === '.' ? 0.2 : 1);
            element = Object.assign({ type: 'text', text: content, horizontalAlign: 'left', verticalAlign: 'bottom' }, textParams);
        } else if (type === 'space') {
            width = helper.getTextWidth({ text: ' ', ...textParams });
            height = textParams.size;
        }

        currentLineHeight = Math.max(currentLineHeight, height);

        if (textParams.center) {
            centerLine = true;
        }

        if (textParams.verticalCenter) {
            verticalCenter = true;
        }

        if (type === 'newline' || currentLineWidth + width > maxLineWidth) {
            if (currentLineHeight < 14) {
                // TODO: improve that
                currentLineHeight += 1;
            }

            textY += currentLineHeight + 1;
            for (const elt of currentLine) {
                elt.y = textY;
            }

            lines.push({
                elements: currentLine,
                width: currentLineWidth,
                height: currentLineHeight,
                center: centerLine || forceCenter
            });

            currentLineWidth = type === 'space' ? 0 : width;
            textX = startTextX;
            prevLineHeight = currentLineHeight;
            currentLineHeight = height;
            centerLine = textParams.center;
            currentLine = [];
        } else {
            currentLineWidth += width;
        }

        if (type === 'newline') {
            Object.assign(textParams, baseTextParams);
            currentLineHeight = baseTextParams.size;
            // currentLineHeight = Math.round(currentLineHeight * 1.7);
        }

        longestLineWidth = Math.max(longestLineWidth, currentLineWidth);

        if (element) {
            element.x = textX;
            currentLine.push(element);
        }

        if (type !== 'space' || textX !== startTextX || last === 'newline') {
            textX += width;   
        }

        last = type;
    }

    for (const line of lines) {
        if (line.center) {
            const offsetX = ((fixedWidth ? maxLineWidth : longestLineWidth) - line.width) / 2;

            for (const element of line.elements) {
                element.x += offsetX;
            }
        }
    }

    const nodes = lines.map(line => line.elements).flat();

    const textHeight = textY - startTextY + margin * 2;
    const textWidth = longestLineWidth + margin * 2;
    const height = fixedHeight ? fixedHeight : textHeight;
    const width = fixedWidth ? fixedWidth : textWidth;

    if (fixedHeight) {
        let additionalY = 0;

        if (verticalCenter || verticalAlign === 'middle') {
            additionalY = (fixedHeight - textHeight) / 2 + textSize * 0.05;
        } else if (verticalAlign === 'bottom') {
            additionalY = fixedHeight - textHeight;
        }

        for (const node of nodes) {
            node.y += additionalY;
        }
    }

    if (backgroundColor || backgroundBorderColor) {
        nodes.unshift({
            type: 'rectangle',
            horizontalAlign: 'left',
            x: 0,
            y: 0,
            width: Math.ceil(width),
            height: Math.ceil(height) + margin,
            fillColor: backgroundColor,
            borderRadius: backgroundRadius,
            borderColor: backgroundBorderColor,
            borderWidth: backgroundBorderWidth
        });
    }

    for (const node of nodes) {
        if (node.type === 'image') {
            const originalHeight = node.height / IMAGE_SIZE_RATIO;
            const dif = originalHeight - node.height;

            node.y -= dif * IMAGE_SIZE_RATIO;

            node.y += 2;
        }

        node.x += x;
        node.y += y;

        if (mirrorHorizontally) {
            node.x -= width;
        }

        if (mirrorVertically) {
            node.y -= height;
        }
    }

    return { width, height, nodes };
}

function isWordCharacter(c) {
    return c && /[a-zA-Z\d.,/()!?:'-]/.test(c);
}

function isColorCharacter(c) {
    return c && /[a-zA-Z#0-9]/.test(c);
}

const BASE_RULES = {
    '*': (options) => options.bold = !options.bold,
    '_': (options) => options.italic = !options.italic,
    '|': (options) => options.center = !options.center,
    '&': (options) => options.verticalCenter = !options.verticalCenter,
    '##': (options, baseOptions) => toggle(options, 'size', baseOptions.size, baseOptions.size * 0.8),
    '#': (options, baseOptions) => toggle(options, 'size', baseOptions.size, baseOptions.size * 1.6)
};

function tokenize(text, rules) {
    const allRules = Object.assign({}, rules, BASE_RULES);
    const tokens = [];

    for (let i = 0; i < text.length; ) {
        let token = null;

        if (text[i] === '@') {
            // TODO: generalize this branch, probably with regexp
            i += 1;
            let color = '';
            while (isColorCharacter(text[i])) {
                color += text[i];
                i += 1;
            }
            if (text[i] === ' ' && color) {
                i += 1;
            }
            const content = color
                ? (options) => options.color = color
                : (options, base) => options.color = base.color;
            token = { type: 'process', content };
        } else {
            for (const [str, content] of Object.entries(allRules)) {
                if (text.startsWith(str, i)) {
                    if (typeof content === 'function') {
                        token = { type: 'process', content };
                    } else if (typeof content === 'string') {
                        token = { type: 'word', content }
                    } else {
                        token = { type: 'image', content };
                    }

                    i += str.length;
                    break;
                }
            }
        }

        if (!token) {
            const c = text[i];

            if (c === ' ') {
                token = { type: 'space' };
                i += 1;
            } else if (c === '\n') {
                token = { type: 'newline' };
                i += 1;
            } else {
                let len = 1;

                while (isWordCharacter(text[i + len])) {
                    len += 1;
                }

                token = { type: 'word', content: text.substring(i, i + len) };
                i += len;
            }
        }

        tokens.push(token);
    }

    return tokens;
}

function toggle(object, property, value1, value2) {
    const value = object[property];

    if (value !== value1) {
        object[property] = value1;
    } else {
        object[property] = value2;
    }
}