import { isInstanceOf } from '../../engine/utils/objects';

export function isPlainLocation(location) {
    return isTerrain(location, 'Plain');
}

export function isObjectiveLocation(location) {
    return location.terrain.isObjective();
}

export function isTerrain(location, terrainClass) {
    if (typeof terrainClass === 'string') {
        return location.terrain.name === terrainClass;
    } else {
        return isInstanceOf(location.terrain, terrainClass);
    }
}

export function isEmptyPlain(location) {
    return isPlainLocation(location) && isEmptyLocation(location);
}

export function getLocationsByTerrain(game, criteria) {
    if (typeof criteria === 'function') {
        return game.battlefield.cells.filter(location => criteria(location.data.terrain));
    } else {
        return game.battlefield.cells.filter(location => location.data.terrain.name === criteria);
    }
}

export function getAllCells(entity) {
    return entity.game.battlefield.cells.slice();
}

export function getAllValidMoveTargetCells(entity) {
    return getAllCells(entity).filter(location => isValidMoveDestination(location));
}

export function isAlliedUnitLocation(location, entity) {
    return location?.entity?.owner === entity.owner;
}

export function isOpponentUnitCell(cell, entity) {
    return cell && cell.entity && cell.entity.owner !== entity.owner;
}

export function isUnitCell(cell) {
    return cell && cell.entity;
}

export function isEmptyLocation(location) {
    return location && !location.entity && location.terrain.allowsUnitMovement();
}

export function givesLineOfSight(cell, entity) {
    return isEmptyLocation(cell) || isAlliedUnitLocation(cell, entity);
}

export function isValidMoveDestination(location) {
    return location && location.terrain.allowsUnitMovement();
}

export function getCellsWithAlliedUnits(character) {
    return character.game.battlefield.filter(cell => isAlliedUnitLocation(cell, character));
}

export function getCellsWithOpponentUnits(character) {
    return character.game.battlefield.filter(cell => isOpponentUnitCell(cell, character));
}

export function getAdjacentCells(cell) {
    return cell.grid.getNeighbors(cell);
}

export function getAdjacentUnitCells(cell) {
    return cell.grid.getNeighbors(cell).filter(neighbor => !!neighbor.entity);
}

export function getAdjacentEmptyCells(cell, diagonals) {
    return cell.grid.getNeighbors(cell, diagonals).filter(neighbor => isEmptyLocation(neighbor));
}

export function getAdjacentAlliedUnitCells(cell, diagonals) {
    return cell.grid.getNeighbors(cell, diagonals).filter(neighbor => isAlliedUnitLocation(neighbor, cell.entity));
}

export function getAdjacentOpponentUnitCells(cell, diagonals) {
    return cell.grid.getNeighbors(cell, diagonals).filter(neighbor => isOpponentUnitCell(neighbor, cell.entity));
}

export function hasAdjacentEmptyCell(cell, diagonals) {
    return getAdjacentEmptyCells(cell, diagonals).length > 0;
}

export function hasAdjacentAlliedUnit(cell, diagonals) {
    return getAdjacentAlliedUnitCells(cell, diagonals).length > 0;
}

export function hasAdjacentOpponentUnit(cell, diagonals) {
    return getAdjacentOpponentUnitCells(cell, diagonals).length > 0;
}

export function getAlliedChains(character, diagonals) {
    return character.game.battlefield.filter(cell => isAlliedUnitLocation(cell, character) && hasAdjacentAlliedUnit(cell, diagonals));
}

export function getAlliedUnitCells(character) {
    return character.game.battlefield.filter(cell => isAlliedUnitLocation(cell, character));
}

export function getConnectedAlliedUnitLocations(location) {
    return location.grid.spread({
        start: location,
        spread: c => isAlliedUnitLocation(c, location.entity)
    });
}

export function getNeutralCellsWithinAlliedRange(character, range) {
    return character.game.battlefield.spread({
        start: cell => isAlliedUnitLocation(cell, character),
        spread: cell => givesLineOfSight(cell, character),
        add: cell => isPlainLocation(cell),
        distance: range,
        includeStart: true
    });
}

export function spread({ start, spread, add, distance, includeStart }) {
    const first = Array.isArray(start) ? start[0] : start;

    return first.grid.spread({ start, spread, add, distance, includeStart });
}

export function getAlliedUnitsWithinRange(start, range) {
    return spread({
        start,
        spread: (cell, { start }) => givesLineOfSight(cell, start.entity),
        add: (cell, { start }) => isAlliedUnitLocation(cell, start.entity),
        distance: range
    });
}

export function getCellsWithinVisionRange(start, range, source) {
    return spread({
        start,
        spread: (cell, { start }) => givesLineOfSight(cell, source || start.entity),
        add: () => true,
        distance: range
    });
}

export function getCellsWithinStraightVisionRange(start, range) {
    return spread({
        start,
        spread: (cell, { start }) => areAligned(start, cell) && givesLineOfSight(cell, start.entity),
        add: cell => areAligned(start, cell),
        distance: range
    });
}

export function getCellsWithinMovementRange(start, range) {
    return spread({
        start,
        spread: (cell, { start }) => isValidMoveDestination(cell) && !isOpponentUnitCell(cell, start.entity),
        add: cell => isValidMoveDestination(cell),
        distance: range
    });
}

export function getCellsWithinStraightMovementRange(start, range) {
    return spread({
        start,
        spread: (cell, { start }) => areAligned(start, cell) && isValidMoveDestination(cell) && !isOpponentUnitCell(cell, start.entity),
        add: (cell, { start }) => areAligned(start, cell) && isValidMoveDestination(cell),
        distance: range
    });
}

export function getAllCellsInRadius(start, radius) {
    return spread({
        start,
        spread: () => true,
        distance: radius,
        includeStart: true
    });
}

export function areAligned(cell1, cell2) {
    return cell1.grid.areAligned(cell1, cell2);
}

export function getDirection(cell1, cell2) {
    if (!cell1 || !cell2) {
        return null;
    }

    return cell1.grid.getDirection(cell1, cell2);
}

export function getAdjacentCellsStartingFromDirection(cell, direction, indexes) {
    return cell.grid.getAdjacentCellsStartingFromDirection(cell, direction, indexes);
}

export function areDiagonals(cell1, cell2) {
    const dx = Math.abs(cell1.x - cell2.x);
    const dy = Math.abs(cell1.y - cell2.y);

    return dx === dy;
}

export function consumeTags(entity, predicate) {
    const remainingTags = typeof predicate === 'function'
        ? entity.tags.filter(tag => !predicate(tag))
        : entity.tags.filter(tag => tag.value !== predicate);

    const matchingTagCount = entity.tags.length - remainingTags.length;

    entity.tags = remainingTags;

    return matchingTagCount;
}

export function getAllUnitsWithTags(game, tag, source) {
    const units = [];

    for (const cell of game.battlefield.cells) {
        if (cell.entity && cell.entity.tags.some(t => t.value === tag && t.source === source)) {
            cell.entity.tags = cell.entity.tags.filter(t => t.value !== tag || t.source !== source);
            units.push(cell.entity);
        }
    }

    return units;
}

export function getAlliedCharacterCells(entity, predicate = () => true) {
    return entity.owner.characterGrid.filter(predicate);
}

export function getOpponentCharacterCells(entity, predicate = () => true) {
    return entity.owner.opponent.characterGrid.filter(predicate);
}

export function getAlliedItemCells(entity) {
    return entity.owner.itemGrid.filter(cell => cell.entity);
}

export function checkEnhanced(character, valueIfEnhanced, value) {
    return character.enhanced ? valueIfEnhanced : value;
}

export function getSymmetricalCell(cell, center) {
    return cell.grid.getSymmetrical(cell, center);
}

export function getPath(cell1, cell2, source = cell1.entity) {
    return cell1.grid.getPath({
        start: cell1,
        target: cell2,
        cost: location => {
            if (!location.terrain.allowsUnitMovement()) {
                return Infinity;
            } else if (!isOpponentUnitCell(location, source)) {
                return 1;
            } else {
                return location === cell2 ? 1 : Infinity;
            }
        }
    })
}

export function getDistance(cell1, cell2) {
    return cell1.grid.getDistance(cell1, cell2);
}

export function getCellInDirection(cell, direction) {
    return cell.grid.getCellInDirection(cell, direction);
}

export function getAllPlainsWithinAlliedRange(source, range) {
    const alliedUnitLocations = getAlliedUnitCells(source);
    const locationsInRange = getCellsWithinVisionRange(alliedUnitLocations, range);
    const plainsInRange = locationsInRange.filter(location => isPlainLocation(location));

    return plainsInRange;
}

export function hasAdjacentCell(location, predicate) {
    return getAdjacentCells(location).some(predicate);
}

export function getAdjacentChain(start, predicate) {
    return spread({
        start,
        spread: predicate,
        includeStart: true
    });
}