export class Entity {
    constructor({ owner = null, location = null, tags = [] } = {}) {
        this.owner = owner;
        this.location = location;
        this.tags = tags.slice();
    }

    get game() {
        return this.location?.game;
    }

    get name() {
        return this.constructor.name;
    }

    get title() {
        return this.constructor.title;
    }

    get description() {
        return this.constructor.description;
    }

    get image() {
        return this.constructor.image;
    }

    get abilities() {
        return this.constructor.abilities;
    }

    get ability() {
        return this.constructor.ability;
    }

    get eventHooks() {
        return this.constructor.eventHooks;
    }

    get preEventCallbacks() {
        return this.constructor.preEventCallbacks;
    }

    get postEventCallbacks() {
        return this.constructor.postEventCallbacks;
    }

    getAbility(index) {
        return this.abilities[index] || this.ability || DEFAULT_ABILITY;
    }

    makeMessageFromAbilityUse() {
        return null;
    }

    static title = ''
    static description = ''
    static abilities = []
    static ability = null
    static eventHooks = {}
    static preEventCallbacks = {}
    static postEventCallbacks = {}
}

const DEFAULT_ABILITY = {
    trigger() {}
};
globalThis.ALL_FUNCTIONS.push(Entity);