import { CONSTANTS } from '../constants';
import { Unit } from '../unit';
import { getAdjacentCellsStartingFromDirection, getAdjacentCells, getAdjacentEmptyCells, getAlliedChains, getCellsWithAlliedUnits, getConnectedAlliedUnitLocations, hasAdjacentEmptyCell, isEmptyLocation, isValidMoveDestination, getDirection, getAlliedUnitCells, getAllValidMoveTargetCells, getCellsWithinVisionRange, isAlliedUnitLocation, getCellsWithinStraightVisionRange, isOpponentUnitCell } from '../utils';
import { Character } from './character';

Object.assign(CONSTANTS, {
    BLOBBY_WIND_RANGE: 1,
    BLOBBY_WIND_UNIT_COUNT: 3,
    BLOBBY_WIND_UNIT_STRENGTH: 1,
});

export class Blobby extends Character {
    constructor(options) {
        super(options);
    }

    static name = 'Blobby'
    static title = 'Blobby'
    static abilities = [
        {
            description: `Clone an allied unit on 2 adjacent cells (the original disappears).`,
            target1({ source }) {
                return getCellsWithAlliedUnits(source);
            },
            target2({ target1 }) {
                return getAdjacentCells(target1).filter(isValidMoveDestination);
            },
            target2Highlight({ target1, target2 }) {
                const direction = getDirection(target1, target2);

                return getAdjacentCellsStartingFromDirection(target1, direction, [0, 1]).filter(location => isValidMoveDestination(location));
            },
            target2Hover() {
                return null;
            },
            trigger({ game, source, target1 }) {
                const locations = this.target2Highlight(...arguments);

                for (const location of locations) {
                    const unit  = new Unit({ strength: target1.entity.strength, tags: target1.entity.tags });
                    game.spawnUnit({ source, location, unit, owner: source.owner });
                }

                game.killUnit({ source, unit: target1.entity });
            }
        }, {
            description: `Spawn $BLOBBY_WIND_UNIT_COUNT 1-strength units in a straight line within 1 range of an allied unit.`,
            target1({ source }) {
                return getAlliedUnitCells(source);
            },
            target2({ target1 }, C) {
                return getCellsWithinStraightVisionRange(target1, C.BLOBBY_WIND_RANGE).filter(cell => isValidMoveDestination(cell));
            },
            target2Highlight({ source, target1, target2 }, C) {
                const direction = getDirection(target1, target2);

                return target1.grid.spread({
                    start: target1,
                    spread: (cell, { from }) => getDirection(from, cell) === direction && isValidMoveDestination(cell) && !isOpponentUnitCell(cell, source),
                    add: (cell, { from }) => getDirection(from, cell) === direction && isValidMoveDestination(cell),
                    distance: C.BLOBBY_WIND_UNIT_COUNT
                });
            },
            trigger({ game, source }, C) {
                const spawnLocations = this.target2Highlight(...arguments);

                for (const location of spawnLocations) {
                    game.spawnUnit({ source, unit: new Unit({ strength: C.BLOBBY_WIND_UNIT_STRENGTH }), owner: source.owner, location });
                }
            }
        }, {
            description: `Merge a chain of allied units. It gets an additional +1 strength for each unit merged.`,
            target1({ source }) {
                return getAlliedChains(source);
            },
            target1Highlight({ target1 }) {
                return [target1].concat(getConnectedAlliedUnitLocations(target1));
            },
            trigger({ game, source, target1 }) {
                const locations = this.target1Highlight(...arguments).slice(1);

                for (const location of locations) {
                    game.moveUnit({ source, unit: location.entity, location: target1 });
                }

                game.setUnitStrength({ source, unit: target1.entity, modifier: locations.length + 1 });
            }
        }
    ]
}
globalThis.ALL_FUNCTIONS.push(Blobby);