import { Grid } from '../../engine/utils/grid';
import { Location } from '../../engine/utils/location';
import { isInstanceOf } from '../../engine/utils/objects';

export class SpatialIndex {
    constructor(game, eventNames) {
        this.game = game;
        this.grids = {};
        this.eventHooks = initEventCallbackSets(eventNames);
        this.preEventCallbacks = initEventCallbackSets(eventNames);
        this.postEventCallbacks = initEventCallbackSets(eventNames);
    }

    static name = 'SpatialIndex'

    addGrids(grids) {
        for (const [name, { width = 1, height = 1, shape = 'square' }] of Object.entries(grids)) {
            this.grids[name] = new Grid({ parent: this.game, name, width, height, shape });
        }
    }

    getGrid(name) {
        return this.grids[name];
    }

    getLocation(info) {
        if (!info) {
            return null;
        } else if (isInstanceOf(info, Location)) {
            return info;
        } else if (typeof info === 'string') {
            return this.grids[info]?.find(location => !location.entity);
        } else {
            let { grid: name, x, y } = info;
            const grid = this.grids[name];

            if (!grid) {
                return null;
            }

            if (x < 0) {
                x = grid.width + x;
            }

            if (y < 0) {
                y = grid.height + y;
            }

            return grid.get({ x, y });
        }
    }

    _modifyLocation(locationInfo, entity, key) {
        const location = this.getLocation(locationInfo);
        const alreadyExists = entity.location && entity.location[key] === entity;
        const remove = !location;

        if (alreadyExists) {
            entity.location[key] = null;
        }

        if (location) {
            location[key] = entity;
        }

        entity.location = location;

        if (!alreadyExists && !remove) {
            this._onEntityAdd(entity);
        } else if (alreadyExists && remove) {
            this._onEntityRemove(entity);
        }
    }

    setEntityLocation(entity, location) {
        this._modifyLocation(location, entity, 'entity');
    }

    setTerrainLocation(terrain, location) {
        this._modifyLocation(location, terrain, 'terrain')
    }

    _onEntityAdd(entity) {
        this._addEntityEventCallbacks(entity, 'eventHooks');
        this._addEntityEventCallbacks(entity, 'preEventCallbacks');
        this._addEntityEventCallbacks(entity, 'postEventCallbacks');
    }

    _onEntityRemove(entity) {
        this._removeEventCallbacks(entity, 'eventHooks');
        this._removeEventCallbacks(entity, 'preEventCallbacks');
        this._removeEventCallbacks(entity, 'postEventCallbacks');
    }

    _addEntityEventCallbacks(entity, key) {
        for (const name of Object.keys(entity[key])) {
            this[key][name].add(entity);
        }
    }

    _removeEventCallbacks(entity, key) {
        for (const name of Object.keys(entity[key])) {
            this[key][name].delete(entity);
        }
    }
}

function initEventCallbackSets(eventNames) {
    const obj = {};

    for (const name of eventNames) {
        obj[name] = new Set();
    }

    return obj;
}
globalThis.ALL_FUNCTIONS.push(SpatialIndex);