import { Terrain } from './terrain';

export class Water extends Terrain {
    constructor(parameters) {
        super(parameters);
    }

    static name = 'Water'
    static description = 'Blocks unit movement but not line of sight.'

    allowsUnitMovement() {
        return false;
    }
}
globalThis.ALL_FUNCTIONS.push(Water);